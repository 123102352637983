import { useCancelCartMutation } from 'orders/reduxQueries'
import { getActiveAccount } from 'preferences/selectors'
import React, { FC, useCallback } from 'react'
import { useAppSelector } from 'store/hooks'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'

type Props = {
    cartId: string | null
    onClose: () => void
    onConfirm: () => void
}

const ConfirmCancelCartDialog: FC<Props> = ({ cartId, onClose, onConfirm }) => {
    const accountSlug = useAppSelector(getActiveAccount)
    const [cancelCart, { isLoading }] = useCancelCartMutation()

    const handleCancelReservation = useCallback(async () => {
        if (!accountSlug || !cartId) throw new Error('Account slug or reservation id is missing')

        await cancelCart({ cartIds: [cartId], accountSlug })
        onConfirm()
    }, [accountSlug, cartId, cancelCart, onConfirm])

    if (!accountSlug || !cartId) return null

    return (
        <ConfirmationDialog
            title="Cancel reservation?"
            text="Are you sure you want to permanently cancel this reservation?"
            buttonText="Yes, cancel"
            cancelButtonText="No, return"
            loading={isLoading}
            onConfirm={handleCancelReservation}
            onCancel={onClose}
        />
    )
}

export default ConfirmCancelCartDialog
