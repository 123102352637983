import * as React from 'react'
import styled, { css } from 'styled-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'uiComponents/flex'
import { SecondaryText } from 'uiComponents/typography'
import { transparency } from 'utils/css'
import Infotip from 'uiComponents/infotip'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'
import { getCssSizeFromString } from './reactTable/utils'
import { StatusText } from 'uiComponents/statusText'

export interface Pagination {
    page: number
    pageSize: number
}

interface DataRowProps {
    interactive?: boolean
    hoverBorder?: boolean
    highlighted?: boolean
    narrow?: boolean
    bold?: boolean
}

interface HeaderRowProps {
    transparent?: boolean
}

export const HeaderRow = styled.div<HeaderRowProps>`
    background: ${(props) => (props.transparent ? '' : props.theme.colors.aluminiumShades[5])};
    display: flex;
    border-radius: 0.7em 0.7em 0 0;
`

export interface CellProps {
    align?: 'left' | 'right' | 'center'
    noPadding?: boolean
}

export const Cell = styled.div<CellProps>`
    display: flex;
    justify-content: ${(p) => {
        if (p.align === 'center') {
            return 'center'
        } else if (p.align === 'right') {
            return 'flex-end'
        } else {
            return 'flex-start'
        }
    }};
    align-items: center;
    flex-shrink: 0;
    padding: ${(props) => (props.noPadding ? '0' : '0 1.25rem')};
    font-weight: 300;
    transition: all 0.1s ease-in;

    > label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: unset;
    }
`

const dataRowInteractiveCss = css`
    cursor: pointer;
    &:hover ${Cell} {
        font-weight: normal;
    }
`

export const DataRow = styled.div<DataRowProps>`
    display: flex;
    height: ${(props) => (props.narrow ? '3.5rem' : '5.35em')};
    ${(props) => props.narrow && 'cursor: auto;'};
    ${(props) => (props.interactive ? dataRowInteractiveCss : '')}
    transition: all .2s ease-in;

    && > ${Cell} {
        white-space: unset;
        word-wrap: break-word;
        &.withName > span {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
        }
        &.rtl {
            direction: rtl;
        }
    }

    ${(props) =>
        props.hoverBorder
            ? ` border-left: 3px solid transparent;
      &:hover {
        border-left: 3px solid ${props.theme.colors.boyBlue};
      }`
            : ''}

    & > * {
        ${(props) => (props.highlighted ? 'opacity: 1' : '')};
        ${(props) => (props.highlighted ? 'font-weight: normal' : '')};
    }

    ${(props) =>
        props.bold &&
        `&& > ${Cell} {
      font-weight: 500;
    }`}
`

interface DynamicRowProps {
    narrow?: boolean
}

export const DynamicRow = styled(DataRow)<DynamicRowProps>`
    && > ${Cell} {
        &.wrapContent {
            padding: 0 1em;
            white-space: unset;
            word-wrap: break-word;
        }
    }
    .visibleOnHover {
        visibility: hidden;
    }
    &:hover .visibleOnHover {
        visibility: visible;
    }
    ${(props) => (props.narrow ? 'height: 3em;' : '')}
    &.hide-bottom-border {
        border-bottom: 1px solid ${(p) => p.theme.colors.white};
    }
`

export type SortDirection = 'asc' | 'desc'

export interface Sorting {
    prop: string
    direction: SortDirection
}

export interface TableHeaderProps {
    children?: any
    sortKey?: string
    sorting?: Sorting
    changeSort?: (newSorting: Sorting) => void
    nonInteractive?: boolean
    noPadding?: boolean
    align?: 'left' | 'right' | 'center'
    infotipText?: string
    infotipPointer?: 'left' | 'right'
    infotipWidth?: string
    width?: string | number
    headerProps?: React.HTMLAttributes<HTMLTableHeaderCellElement>
}

const activeCss = css`
    color: ${(props) => props.theme.colors.textDark};
    font-weight: bold;
`

const inactiveCss = css`
    &:hover {
        color: ${(props) => props.theme.colors.textDark};
    }
`

const SortUpIcon = styled(FontAwesomeIcon)`
    margin-left: 0.3125em;
    transform: translateY(17%);
    color: ${(props) => props.theme.colors.boyBlue};
    align-self: center;
`

const SortDownIcon = styled(SortUpIcon)`
    transform: translateY(-20%);
`
const SortIcon = styled(SortUpIcon)`
    transform: translateY(10%);
    color: ${(props) => props.theme.colors.textDark};
    opacity: 0.3;
    transition: opacity 0.1s ease-in;
`

const StyledHeader = styled.div<TableHeaderProps>`
    > span {
        overflow: visible;
    }
    display: flex;
    align-items: center;
    padding: ${(props) => (props.noPadding ? '0' : '0 1.25rem 0 1.25rem')};

    justify-content: ${(props) => {
        if (props.align === 'center') {
            return 'center'
        } else if (props.align === 'right') {
            return 'flex-end'
        } else {
            return 'flex-start'
        }
    }};
    min-height: 2.5em;

    color: ${(props) => props.theme.colors.textLight};
    ${(props) => (props.sorting ? (props.sortKey === props.sorting.prop ? activeCss : inactiveCss) : '')}
    ${(props) => (props.sortKey ? 'cursor: pointer;' : '')}

  white-space: break-spaces;
    user-select: none;
    border-bottom: 3px solid transparent;
    transition: color 0.1s ease-in, border-bottom 0.2s ease-in;

    ${(props) =>
        !props.nonInteractive
            ? css`
                  padding-top: 3px;
                  &:hover {
                      border-bottom: 3px solid ${props.theme.colors.boyBlue};
                  }

                  &:hover ${SortIcon} {
                      opacity: 0.8;
                  }
              `
            : ''}

    ${(props) => {
        const size = getCssSizeFromString(props.width)

        return size
            ? css`
                  width: ${size};
                  max-width: ${size};
                  min-width: ${size};
                  /* Edge */
                  @supports (-ms-ime-align: auto) {
                      min-width: ${size};
                  }
              `
            : ''
    }}
`

const StyledHeaderText = styled.span`
    display: flex;
    justify-content: flex-start;
`

function newSorting(sortKey: string, sorting: Sorting): Sorting {
    const direction = sorting.prop === sortKey ? (sorting.direction === 'asc' ? 'desc' : 'asc') : 'asc'
    return { prop: sortKey, direction }
}

export function TableHeader({
    children,
    sortKey,
    sorting,
    changeSort,
    align,
    infotipText,
    infotipPointer,
    infotipWidth,
    headerProps,
    ...rest
}: TableHeaderProps & React.HTMLAttributes<HTMLTableHeaderCellElement>) {
    const sortDirection = sorting && sorting.prop === sortKey ? sorting.direction : null
    return (
        <StyledHeader
            sortKey={sortKey}
            sorting={sorting}
            align={align}
            onClick={() => changeSort && sortKey && sorting && changeSort(newSorting(sortKey, sorting))}
            {...rest}
            {...headerProps}
        >
            <StyledHeaderText>
                {children}
                {infotipText && (
                    <Infotip pointer={infotipPointer} maxWidth={infotipWidth}>
                        {infotipText}
                    </Infotip>
                )}
                {sortDirection === 'asc' && <SortUpIcon icon={faSortUp} />}
                {sortDirection === 'desc' && <SortDownIcon icon={faSortDown} />}
                {children && !sortDirection && sortKey && <SortIcon icon={faSort} />}
            </StyledHeaderText>
        </StyledHeader>
    )
}

interface DataTableProps {
    id?: string
    style?: React.CSSProperties
    className?: string
    columnWidths?: (string | null)[]
    columnData?: { w: string | null; fixed: boolean }[]
    children?: React.ReactNode
    bordered?: boolean
    customSidePadding?: string
    allowOverflow?: boolean
    rightInsetShadow?: boolean
    backgroundColor?: string
}

export const DataTable = styled.div<DataTableProps>`
    background-color: #ffffff;
    font-size: 0.875rem;
    width: ${(props) => (props.allowOverflow ? 'fit-content' : '100%')};
    box-shadow: ${(props) => (props.allowOverflow ? '' : CONTAINER_BOX_SHADOW)};
    border-radius: 0.7em;
    & > ${DataRow}:last-child {
        border-radius: 0 0 0.7em 0.7em;
    }
    & ${HeaderRow} > ${StyledHeader} {
        -ms-flex-negative: 1;
        flex: 1 1 0;
        ${(props) =>
            props.customSidePadding &&
            `padding-right: ${props.customSidePadding};
       padding-left: ${props.customSidePadding};`}
    }

    & ${DataRow} > ${Cell} {
        -ms-flex-negative: 1;
        flex: 1 1 0;
        ${(props) => (props.customSidePadding ? `padding: ${props.customSidePadding} ${props.customSidePadding}` : '')}
    }

    & ${DataRow} > ${Cell}:not(.wrapContent) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & ${DataRow} > ${Cell}.overflow-allowed {
        overflow: visible;
    }

    & > ${DataRow}:nth-child(2n) {
        background: ${(props) => props.theme.colors.white};
    }

    & > ${DataRow}:nth-child(2n + 1) {
        background: ${(props) => transparency(props.theme.colors.tableRow, 0.5)};
    }

    ${(props) =>
        (props.columnWidths || []).map((w, i) =>
            w
                ? css`
                      & ${HeaderRow} > ${StyledHeader}:nth-child(${i + 1}),
                      & ${DataRow} > ${Cell}:nth-child(${i + 1}) {
                          width: ${w};
                          max-width: ${w};
                          min-width: ${w};
                          /* Edge */
                          @supports (-ms-ime-align: auto) {
                              min-width: ${w};
                          }
                      }
                  `
                : '',
        )}

    ${(props) =>
        (props.columnData || []).map((c, i) =>
            c
                ? css`
                      & ${HeaderRow} > ${StyledHeader}:nth-child(${i + 1}),
                      & ${DataRow} > ${Cell}:nth-child(${i + 1}) {
                          width: ${c.w};
                          max-width: ${c.fixed ? c.w : 'unset'};
                          min-width: ${c.w};
                          /* Edge */
                          @supports (-ms-ime-align: auto) {
                              min-width: ${c.w};
                          }
                      }
                  `
                : '',
        )}

  ${(props) =>
        props.bordered &&
        `& > ${DataRow}, & > div > ${DataRow} {
      border-bottom: 1px solid ${transparency(props.theme.colors.border, 0.5)};
    }
    & > ${DataRow}:nth-child(2n + 1), & > div > ${DataRow}:nth-child(2n + 1){
      background: ${props.theme.colors.white};
    }
    `}

  &.sticky {
        overflow-x: scroll;
        overflow-y: auto;
        .header,
        .footer {
            position: sticky;
            z-index: 1;
            width: fit-content;
        }

        .header {
            top: 0;

            .th {
                z-index: 1;
                background-color: inherit;
            }
        }

        .footer {
            bottom: 0;
        }

        .body {
            z-index: 0;

            [data-sticky-first-right-td] {
                background: #fff;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 8px);
            }
        }

        [data-sticky-td] {
            position: sticky;
            z-index: 1;
        }

        [data-sticky-last-left-td] {
            box-shadow: 2px 0px 7px #ccc;
        }
    }
`

export const InlineDataTable = styled(DataTable)`
    border-radius: 0.7em 0;
    box-shadow: none;

    & ${HeaderRow} {
        border-radius: 0;
    }

    & ${DataRow} {
        box-shadow: none;
    }
`

const ExpandLink = styled.a`
    font-size: 1.25em;

    color: ${(props) => props.theme.colors.textLight};
    transition: color 0.1s ease-in;

    ${DataRow}:hover &,
  ${DataRow}.expanded & {
        color: ${(props) => props.theme.colors.textDark};
    }
`
interface ExpandCellProps {
    expanded: boolean
    className?: string
    noPadding?: boolean
    align?: 'left' | 'right' | 'center'
}
export function ExpandCell({ expanded, className, noPadding, align }: ExpandCellProps) {
    return (
        <Cell align={align || 'center'} className={className} noPadding={noPadding}>
            <ExpandLink href="" onClick={(e) => e.preventDefault()} className="expander">
                <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
            </ExpandLink>
        </Cell>
    )
}

export const NameAndStatus = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > span {
        margin-bottom: 0.2em;
    }
`

export const Name = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`

interface StatusProps {
    active: boolean
    withText?: boolean
}

interface RowProps {
    withText?: boolean
}

const StatusRow = styled(Row)<RowProps>`
    width: ${(p) => (p.withText ? '9em' : '2em')};
    ${(p) => (p.withText ? '' : 'margin-right: .5em')};
    ${(p) => (p.withText ? 'margin-top: .2em' : '')};
    font-style: italic;
    align-items: center;
`

export const StatusIndicator = styled.div<StatusProps>`
    background-color: ${(p) => (p.active ? p.theme.colors.status.success : p.theme.colors.status.error)};
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
`

export function Status({ active, withText }: StatusProps) {
    return (
        <StatusRow gutter={12} withText={withText}>
            <Col span={2}>
                <StatusIndicator active={active} />
            </Col>
            {withText && (
                <Col span={10}>
                    <SecondaryText>{active ? 'Active' : 'Inactive'}</SecondaryText>
                </Col>
            )}
        </StatusRow>
    )
}

export type StatusName = 'success' | 'error' | 'disabled' | 'warn' | 'blue'
interface MultipleStatusProps {
    status: StatusName
    successText?: string
    errorText?: string
    warnText?: string
    disabledText?: string
    blueText?: string
    withText?: boolean
}

export const MultipleStatusIndicator = styled.div<MultipleStatusProps>`
    ${(p) => {
        if (p.status === 'success') {
            return `background-color: ${p.theme.colors.status.success}`
        }
        if (p.status === 'error') {
            return `background-color: ${p.theme.colors.status.error}`
        }
        if (p.status === 'warn') {
            return `background-color: ${p.theme.colors.status.warn}`
        }
        if (p.status === 'disabled') {
            return `background-color: ${p.theme.colors.border}`
        }
        if (p.status === 'blue') {
            return `background-color: ${p.theme.colors.boyBlue}`
        }
        return ''
    }};
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
`

/**
 * @deprecated This component is deprecated, please use the much simpler {@link StatusText} component instead.
 */
export function MultipleStatus(props: MultipleStatusProps) {
    const { status } = props
    return (
        <div>
            {status === 'success' && (
                <StatusRow gutter={12} withText={props.withText}>
                    <MultipleStatusIndicator status={status} />
                    {props.withText && (
                        <Col span={10}>
                            <SecondaryText>{props.successText ? props.successText : 'Active'}</SecondaryText>
                        </Col>
                    )}
                </StatusRow>
            )}
            {status === 'warn' && (
                <StatusRow gutter={12} withText={props.withText}>
                    <MultipleStatusIndicator status={status} />
                    {props.withText && (
                        <Col span={10}>
                            <SecondaryText>{props.warnText ? props.warnText : 'Upcoming'}</SecondaryText>
                        </Col>
                    )}
                </StatusRow>
            )}
            {status === 'error' && (
                <StatusRow gutter={12} withText={props.withText}>
                    <MultipleStatusIndicator status={status} />
                    {props.withText && (
                        <Col span={10}>
                            <SecondaryText>{props.errorText ? props.errorText : 'Expired'}</SecondaryText>
                        </Col>
                    )}
                </StatusRow>
            )}
            {status === 'disabled' && (
                <StatusRow gutter={12} withText={props.withText}>
                    <MultipleStatusIndicator status={status} />
                    {props.withText && (
                        <Col span={10}>
                            <SecondaryText>{props.disabledText ? props.disabledText : 'Inactive'}</SecondaryText>
                        </Col>
                    )}
                </StatusRow>
            )}
            {status === 'blue' && (
                <StatusRow gutter={12} withText={props.withText}>
                    <MultipleStatusIndicator status={status} />
                    {props.withText && (
                        <Col span={10}>
                            <SecondaryText>{props.blueText ? props.blueText : 'Special'}</SecondaryText>
                        </Col>
                    )}
                </StatusRow>
            )}
        </div>
    )
}

export const ReactTableDataTableComponent = styled.div<DataTableProps>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.white};

    font-size: 0.875rem;
    width: ${(props) => (props.allowOverflow ? 'fit-content' : '100%')};
    box-shadow: ${(props) => (props.allowOverflow ? '' : CONTAINER_BOX_SHADOW)};
    border-radius: 0.7em;
    & > ${DataRow}:last-child {
        border-radius: 0 0 0.7em 0.7em;
    }
    & ${HeaderRow} > ${StyledHeader} {
        -ms-flex-negative: 1;
        flex: 1 1 0;
        ${(props) =>
            props.customSidePadding &&
            `padding-right: ${props.customSidePadding};
       padding-left: ${props.customSidePadding};`}
    }

    & ${DataRow} > ${Cell} {
        -ms-flex-negative: 1;
        flex: 1 1 0;
        ${(props) => (props.customSidePadding ? `padding: ${props.customSidePadding} ${props.customSidePadding}` : '')}
    }

    & ${DataRow} > ${Cell}:not(.wrapContent) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & ${DataRow} > ${Cell}.overflow-allowed {
        overflow: visible;
    }

    & .body > ${DataRow}:nth-child(2n) {
        background: ${(props) => props.theme.colors.white};
    }

    & .body > ${DataRow}:nth-child(2n + 1) {
        background: ${(props) => transparency(props.theme.colors.tableRow, 0.5)};
    }

    ${(props) =>
        props.bordered &&
        `
      & > .body > ${DataRow}, & > div > ${DataRow} {
        border-bottom: 1px solid ${transparency(props.theme.colors.border, 0.5)};
      }
      & > .body > ${DataRow}:nth-child(2n + 1), & > div > ${DataRow}:nth-child(2n + 1){
        background: ${props.theme.colors.white};
      }
    `}

    ${(props) =>
        props.bordered &&
        `
        & .bordered {
            width: fit-content;
            border-bottom: 1px solid ${transparency(props.theme.colors.border, 0.5)};
        }
    `}

    &.sticky {
        overflow-x: scroll;
        overflow-y: auto;
        .header,
        .footer {
            position: sticky;
            z-index: 1;
            width: fit-content;
        }

        .header {
            top: 0;
            width: 100%;
            .th {
                z-index: 1;
                background-color: inherit;
            }
        }

        .footer {
            bottom: 0;
        }

        .body {
            z-index: 0;

            [data-sticky-first-right-td] {
                background: #fff;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 8px);
            }
        }

        [data-sticky-td] {
            position: sticky;
            z-index: 1;
        }

        [data-sticky-last-left-td] {
            box-shadow: 2px 0px 7px #ccc;
        }
    }

    .expanded {
        background-color: ${(props) => props.theme.colors.tableRow};
        [data-sticky-first-right-td] {
            background-color: ${(props) => props.theme.colors.tableRow} !important;
        }
    }

    .selected {
        background-color: ${(props) => props.theme.colors.boyBlueShades[5]};
        [data-sticky-first-right-td] {
            background-color: ${(props) => props.theme.colors.boyBlueShades[5]} !important;
        }
    }
`
