import * as React from 'react'
import { PriceSetting, DynamicInterval, OverrideType, PricingSettingToEnforce } from 'products/pricing/pricingService'
import { PricingType } from 'products/articleConfigurationService'
import { Currency } from 'uiComponents/money/moneyHoc'
import { SetAdjustmentFunc } from 'products/pricing/exceptions/exceptionForm'
import { RulesForNyop } from './rulesForNyop'
import { RulesForRtp } from './rulesForRtp'
import { RulesForUpsell } from './rulesForUpsell'
import { RulesForStatic } from './rulesForStatic'
import { Adjustment } from './shared'

export const priceSettingNames = {
    BOX: 'Max price',
    AVG: 'Regular price',
    MIN: 'Min price',
}

export const priceSettings: PriceSetting[] = ['BOX', 'AVG', 'MIN']

interface RulesSectionProps {
    exception: boolean
    pricingType: PricingType | null
    adjustments: Adjustment[]
    intervals: DynamicInterval[] | null
    currency: Currency
    activatePriceSettingRule: (priceType: PriceSetting, activate: boolean) => void
    setAdjustment: SetAdjustmentFunc
    setEnforceRule: (priceSetting: PricingSettingToEnforce | null) => void
    setIntervals: (intervals: DynamicInterval[] | null) => void
    priceSettingToEnforce: PricingSettingToEnforce | null
    validate: boolean
    areIntervalsValid: boolean
    doIntervalsOverlap: boolean
    overrideType: OverrideType | null
    onOverrideTypeChange: (type: OverrideType) => void
}

export function RulesSection(props: RulesSectionProps) {
    const { pricingType, currency, adjustments, validate, exception, intervals } = props
    const boxAdjustment = adjustments.find((a) => a.name === 'BOX')
    return (
        <div style={{ marginBottom: '2em' }}>
            {(pricingType === 'nyop' || (exception && !pricingType)) && (
                <RulesForNyop
                    activatePriceSettingRule={props.activatePriceSettingRule}
                    currency={currency}
                    adjustments={adjustments}
                    setAdjustment={props.setAdjustment}
                    validate={validate}
                />
            )}
            {pricingType === 'rtp' && (
                <RulesForRtp
                    activatePriceSettingRule={props.activatePriceSettingRule}
                    currency={currency}
                    adjustments={adjustments}
                    setAdjustment={props.setAdjustment}
                    setEnforceRule={props.setEnforceRule}
                    priceSettingToEnforce={props.priceSettingToEnforce}
                    validate={validate}
                    onOverrideTypeChange={props.onOverrideTypeChange}
                />
            )}
            {pricingType === 'upsell' && (
                <RulesForUpsell
                    activatePriceSettingRule={props.activatePriceSettingRule}
                    currency={currency}
                    adjustment={
                        boxAdjustment
                            ? boxAdjustment
                            : {
                                  name: 'BOX',
                                  type: 'ABSOLUTE',
                                  value: 0,
                                  on: false,
                              }
                    }
                    setAdjustment={props.setAdjustment}
                    validate={validate}
                />
            )}
            {pricingType === 'static' && (
                <RulesForStatic
                    activatePriceSettingRule={props.activatePriceSettingRule}
                    currency={currency}
                    adjustment={
                        boxAdjustment
                            ? boxAdjustment
                            : {
                                  name: 'BOX',
                                  type: 'ABSOLUTE',
                                  value: 0,
                                  on: false,
                              }
                    }
                    intervals={intervals}
                    setIntervals={props.setIntervals}
                    setAdjustment={props.setAdjustment}
                    validate={validate}
                    areIntervalsValid={props.areIntervalsValid}
                    doIntervalsOverlap={props.doIntervalsOverlap}
                    overrideType={props.overrideType}
                    onOverrideTypeChange={props.onOverrideTypeChange}
                />
            )}
        </div>
    )
}
