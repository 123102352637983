import React, { useMemo, useState } from 'react'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { SingleSelect, RichTextInput, TextInput, NumberInput } from 'uiComponents/input'
import { ImageUploadResponse } from 'http/imagesService'
import cloneDeep from 'lodash/cloneDeep'
import { SecondaryText, Body } from 'uiComponents/typography'
import { ProductListData, Locale, CMS_KEY_PLACEHOLDER, CheckoutFlow, ConfirmationFlow } from 'admin/articleService'
import { UploadInput } from 'uiComponents/input/upload'
import { MessageKind } from 'uiComponents/messages'
import Infotip, { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { Tabs } from 'uiComponents/navigation/tabs'
import { Col, Row } from 'uiComponents/flex'
import { DeleteNote, UploadExplanation, ImageUrlCol, ImgLink } from '../common'
import { FormTogglerCol, FormToggler } from 'uiComponents/input/toggle'
import { useGetAllArticles, useGetAllCategoriesForSelect } from 'products/redux'
import { flatten } from 'lodash'
import { extractContentFromHTML } from '../utils'

interface GeneralInfoSectionProps {
    accountSlug: string
    locales: Locale[]
    activeLocaleIndex: number
    setActiveLocaleIndex: (index: number) => void
    localesWithMissingName: string[]
    localesWithMissingDescription: string[]
    localesWithMissingTimeSlotInfo: string[]
    showBookingLimitsError: boolean
    updateData: (data: ProductListData) => void
    categoryData: ProductListData
    onWideImageUpload: (file: File) => Promise<any>
    onSquareImageUpload: (file: File) => Promise<any>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    onWideImageUploadSuccess: (response: ImageUploadResponse) => void
    onSquareImageUploadSuccess: (response: ImageUploadResponse) => void
    hasFeature: (feature: string, slug: string) => boolean
    viewMode: 'flat' | 'nested' | ''
}

const categoryFlowOptions: { name: string; value: CheckoutFlow }[] = [
    { name: 'Dated', value: 'dated' },
    { name: 'Undated', value: 'undated' },
    { name: 'Calendar view', value: 'rtp' },
    { name: 'Date change', value: 'date_change' },
]

const confirmationFlowOptions: { name: string; value: ConfirmationFlow }[] = [
    { name: 'Payment required', value: 'payment_required' },
    { name: 'Reservation', value: 'reservation' },
    { name: 'Validity extension', value: 'validity_extension' },
    { name: 'Ruhr Topcard check', value: 'ruhr_topcard_check' },
]

const noReservationCheckoutFlows: CheckoutFlow[] = ['undated', 'date_change']

const reservationOption = {
    name: 'Date Reservation',
    value: 'date_reservation',
}

let isForGroupBookingsWasTurnedOffIndirectly = false

export function GeneralInfoSection(props: GeneralInfoSectionProps) {
    const {
        activeLocaleIndex,
        setActiveLocaleIndex,
        localesWithMissingName,
        localesWithMissingDescription,
        localesWithMissingTimeSlotInfo,
        showBookingLimitsError,
        locales,
        categoryData,
        updateData,
    } = props
    const categories = useGetAllCategoriesForSelect()
    const articles = useGetAllArticles()
    const [descriptionInvalidAmountOfCharsError, setDescriptionInvalidAmountOfCharsError] = useState<boolean>(false)
    const formattedCategories = useMemo(() => {
        const parentCategoryIds = flatten(articles.map((a) => a.categories?.map((c) => c.id) || []))
        return [
            { value: '', name: 'None' },
            ...categories.filter((c) => !parentCategoryIds.includes(c.value) && c.value !== categoryData.id),
        ]
    }, [categories, articles, categoryData.id])
    const newData = useMemo(() => cloneDeep(categoryData), [categoryData])

    function onTitleChange(event: React.ChangeEvent<HTMLInputElement>, locale: Locale) {
        newData.title.text[locale.code] = event.target.value
        updateData(newData)
    }

    function onDescriptionChange(value: string, locale: Locale) {
        setDescriptionInvalidAmountOfCharsError(false)
        if (newData.imageType === 'square_image') {
            setDescriptionInvalidAmountOfCharsError(extractContentFromHTML(value).length >= 120)
        }

        if (newData.description) {
            newData.description.text[locale.code] = value
        } else {
            newData.description = {
                key: CMS_KEY_PLACEHOLDER,
                text: {
                    [locale.code]: value,
                },
            }
        }
    }

    function onTimeSlotInfoChange(value: string, locale: Locale) {
        if (newData.timeSlotInfo) {
            newData.timeSlotInfo.text[locale.code] = value
        } else {
            newData.timeSlotInfo = {
                key: CMS_KEY_PLACEHOLDER,
                text: {
                    [locale.code]: value,
                },
            }
        }
    }

    function onParentIdChange(value: string) {
        newData.parentId = value.trim() === '' ? null : value
        updateData(newData)
    }

    function onPriorityChange(event: React.ChangeEvent<HTMLInputElement>) {
        newData.priority = event.target.value === '' ? null : +event.target.value
        updateData(newData)
    }

    function onPriorityBlur() {
        if (categoryData.priority !== null) {
            return
        }
        newData.priority = 1
        updateData(newData)
    }

    function onFlowChange(flow: CheckoutFlow) {
        newData.flow = flow
        if (!['dated', 'rtp'].includes(flow)) {
            if (newData.isForGroupBookings) {
                newData.isForGroupBookings = false
                // ensure we switch isForGroupBookings setting back on if it was indirectly turned off by this toggle
                isForGroupBookingsWasTurnedOffIndirectly = true
            }
        } else if (isForGroupBookingsWasTurnedOffIndirectly) {
            newData.isForGroupBookings = true
            isForGroupBookingsWasTurnedOffIndirectly = false
        }
        if (noReservationCheckoutFlows.includes(flow) && newData.confirmationFlow === 'reservation') {
            newData.confirmationFlow = 'payment_required'
        }
        updateData(newData)
    }

    function onConfirmationFlowChange(flow: ConfirmationFlow) {
        newData.confirmationFlow = flow
        updateData(newData)
    }

    function onCardOpenChange(value: boolean) {
        newData.cardOpenByDefault = value
        updateData(newData)
    }

    function onBookingLimitsChange(
        event: React.ChangeEvent<HTMLInputElement>,
        field: 'bookingHorizon' | 'releaseTime',
    ) {
        newData[field] = event.target.value === '' ? null : +event.target.value
        updateData(newData)
    }

    function removeWideCategoryImage() {
        newData.image = null
        updateData(newData)
    }

    function removeSquareCategoryImage() {
        newData.squareImage = null
        updateData(newData)
    }

    function getCheckoutFlowOptions() {
        if (newData.flow === 'date_reservation') {
            return [reservationOption]
        }
        return props.hasFeature('DateChangeProductCRUDUpdate', props.accountSlug)
            ? categoryFlowOptions
            : categoryFlowOptions.filter((o) => o.value !== 'date_change')
    }

    function getConfirmationFlowOptions() {
        const edgeCaseConfFlows: ConfirmationFlow[] = ['validity_extension', 'ruhr_topcard_check']
        let options = props.hasFeature('ShowEdgeCaseConfirmationFlows', props.accountSlug)
            ? confirmationFlowOptions
            : confirmationFlowOptions.filter((o) => !edgeCaseConfFlows.includes(o.value))
        if (newData.flow && noReservationCheckoutFlows.includes(newData.flow)) {
            options = options.filter((o) => o.value !== 'reservation')
        }
        return options
    }

    const reservationFlow = newData.flow === 'date_reservation'
    return (
        <div>
            <FormItem htmlFor="checkout-flow" style={{ position: 'relative', margin: '1em 0' }}>
                <FormItemName>Checkout flow type</FormItemName>
                <DisabledItemHoverInfotip
                    active={reservationFlow}
                    infotipText="This category belongs to Date Reservation checkout flow. In order to change the flow, please contact Convious support."
                    width="30em"
                >
                    <SingleSelect
                        id="checkout-flow"
                        options={getCheckoutFlowOptions()}
                        selected={newData.flow || ''}
                        height="2.8em"
                        maxHeight="11em"
                        noSelectOption="None"
                        onSelect={onFlowChange}
                        disabled={reservationFlow}
                    />
                </DisabledItemHoverInfotip>
            </FormItem>
            <FormItem htmlFor="confirmation-flow" style={{ margin: '1.5em 0' }}>
                <FormItemName>Confirmation flow type</FormItemName>
                <SingleSelect
                    id="confirmation-flow"
                    options={getConfirmationFlowOptions()}
                    selected={newData.confirmationFlow || ''}
                    height="2.8em"
                    maxHeight="11em"
                    noSelectOption="None"
                    onSelect={onConfirmationFlowChange}
                />
            </FormItem>
            <Tabs
                titles={locales.map((locale) => locale.name)}
                setActive={setActiveLocaleIndex}
                activeTabIndex={activeLocaleIndex}
            >
                {locales.map((locale, index) => {
                    return (
                        <div key={index}>
                            <FormItem htmlFor="category-name" style={{ position: 'relative', marginBottom: 24 }}>
                                <FormItemName>
                                    Category name
                                    <Infotip pointer="left">
                                        This is the name of the category that will appear in the checkout.
                                    </Infotip>
                                </FormItemName>
                                <TextInput
                                    id={`category-name-${[locale.code]}`}
                                    name="category-name"
                                    type="text"
                                    maxLength={50}
                                    value={newData.title?.text[locale.code] || ''}
                                    status={localesWithMissingName.includes(locale.code) ? 'error' : 'normal'}
                                    placeholder="Add category name..."
                                    onChange={(event) => onTitleChange(event, locale)}
                                    locale={locale.code}
                                    block
                                />
                            </FormItem>
                            {localesWithMissingName.includes(locale.code) && (
                                <ValidationMessage className="validation-message-visible" style={{ top: '-1.8em' }}>
                                    This field is required.
                                </ValidationMessage>
                            )}
                            <FormItem htmlFor="category-description" style={{ position: 'relative' }}>
                                <div style={{ display: 'flex' }}>
                                    <FormItemName>Category description (optional)</FormItemName>
                                </div>
                                <RichTextInput
                                    id={`category-description-${[locale.code]}`}
                                    onChange={(value) => onDescriptionChange(value, locale)}
                                    onBlur={() => updateData(newData)}
                                    value={newData.description?.text[locale.code] || ''}
                                    maxLength={300}
                                    showCharsLeft
                                    status={
                                        localesWithMissingDescription.includes(locale.code)
                                            ? 'error'
                                            : descriptionInvalidAmountOfCharsError
                                            ? 'warning'
                                            : 'normal'
                                    }
                                />
                            </FormItem>
                            {localesWithMissingDescription.includes(locale.code) && (
                                <ValidationMessage className="validation-message-visible" style={{ top: '-1.8em' }}>
                                    All translations of the description are required.
                                </ValidationMessage>
                            )}
                            {descriptionInvalidAmountOfCharsError && (
                                <ValidationMessage
                                    status="processing"
                                    className="validation-message-visible"
                                    style={{ top: '-1.8em' }}
                                >
                                    Max 120 characters for square images exceeded. Text beyond this limit may be hidden
                                    at checkout.
                                </ValidationMessage>
                            )}
                            {newData.isLeaf && newData.timeSlotsEnabled && (
                                <>
                                    <FormItem htmlFor="category-time-slot-info" style={{ position: 'relative' }}>
                                        <div style={{ display: 'flex' }}>
                                            <FormItemName>Category time slot info (optional)</FormItemName>
                                        </div>
                                        <RichTextInput
                                            id={`category-time-slot-${[locale.code]}`}
                                            onChange={(value) => onTimeSlotInfoChange(value, locale)}
                                            onBlur={() => updateData(newData)}
                                            value={newData.timeSlotInfo?.text[locale.code] || ''}
                                            maxLength={500}
                                            showCharsLeft
                                            status={
                                                localesWithMissingTimeSlotInfo.includes(locale.code)
                                                    ? 'error'
                                                    : 'normal'
                                            }
                                        />
                                    </FormItem>
                                    {localesWithMissingTimeSlotInfo.includes(locale.code) && (
                                        <ValidationMessage
                                            className="validation-message-visible"
                                            style={{ top: '-1.8em' }}
                                        >
                                            All translations of the time slot info are required.
                                        </ValidationMessage>
                                    )}
                                </>
                            )}
                        </div>
                    )
                })}
            </Tabs>

            {categories.length > 0 && (
                <FormItem htmlFor="category-parent" style={{ position: 'relative' }}>
                    <FormItemName>Parent category (optional)</FormItemName>
                    <SingleSelect
                        nested
                        id="category-parent"
                        options={formattedCategories}
                        selected={newData.parentId || ''}
                        height="2.8em"
                        maxHeight="20rem"
                        noSelectOption="None"
                        onSelect={onParentIdChange}
                    />
                </FormItem>
            )}
            <FormItem htmlFor="category-priority" style={{ position: 'relative', marginTop: '1em' }}>
                <FormItemName>Category priority</FormItemName>
                <NumberInput
                    integerOnly
                    value={newData.priority === null ? '' : newData.priority}
                    min={1}
                    max={1000}
                    placeholder="1"
                    onChange={onPriorityChange}
                    onBlur={onPriorityBlur}
                />
            </FormItem>
            <Row style={{ marginTop: '1em' }}>
                <Col span={6}>
                    <FormItem htmlFor="category-booking-horizon">
                        <FormItemName>
                            Booking horizon
                            <Infotip pointer="left" maxWidth="28em">
                                Number of days from today the product will be available to buy in the checkout. Use this
                                to prevent customers booking too far in advance.
                                <div>Leave blank to disable.</div>
                            </Infotip>
                        </FormItemName>
                        <NumberInput
                            id="category-booking-horizon"
                            integerOnly
                            value={newData.bookingHorizon === null ? '' : newData.bookingHorizon}
                            min={1}
                            max={1000}
                            onChange={(event) => onBookingLimitsChange(event, 'bookingHorizon')}
                            placeholder="-"
                            status={showBookingLimitsError ? 'error' : 'normal'}
                        />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem htmlFor="category-release-time">
                        <FormItemName>
                            Release time
                            <Infotip pointer="left" maxWidth="30em">
                                Number of days from today the product will not be available to buy in the checkout. Use
                                this to set up a product that will be available for purchase in the future.
                                <div>Leave blank to disable.</div>
                            </Infotip>
                        </FormItemName>
                        <NumberInput
                            id="category-release-time"
                            integerOnly
                            value={newData.releaseTime === null ? '' : newData.releaseTime}
                            min={1}
                            max={1000}
                            onChange={(event) => onBookingLimitsChange(event, 'releaseTime')}
                            placeholder="-"
                            status={showBookingLimitsError ? 'error' : 'normal'}
                        />
                    </FormItem>
                </Col>
                <ValidationMessage
                    className={showBookingLimitsError ? 'validation-message-visible' : ''}
                    style={{ top: '0.5em', marginBottom: '-2.5em' }}
                >
                    Booking horizon must be greater than release time, otherwise the category will never be available.
                </ValidationMessage>
            </Row>
            <FormItemName style={{ margin: '2em 0 1em 0' }}>
                Checkout is set to show
                <strong>
                    &nbsp;{newData.imageType === 'square_image' ? 'square' : 'wide'}
                    &nbsp;
                </strong>
                category images
                <Infotip pointer="left">If you wish to change that, please contact Convious support.</Infotip>
            </FormItemName>
            <FormItem htmlFor="category-image" style={{ position: 'relative' }}>
                <FormItemName>Wide category image</FormItemName>
                <UploadInput
                    type="img"
                    fileSizeLimit={1}
                    onUpload={props.onWideImageUpload}
                    handleUploadSuccessResponse={props.onWideImageUploadSuccess}
                    inputId="category-image"
                    replaceTopMessages={props.replaceMessages}
                    removeAllMessages={props.removeAllMessages}
                />
                <UploadExplanation>
                    <Body size={3}>Image size should be 450x140</Body>
                </UploadExplanation>
            </FormItem>
            {!!newData.image && (
                <>
                    <FormItemName>Current wide image url:</FormItemName>
                    <Row>
                        <ImageUrlCol span={9}>
                            <ImgLink href={newData.image} target="_blank">
                                {newData.image}
                            </ImgLink>
                        </ImageUrlCol>
                        <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <DeleteNote onClick={removeWideCategoryImage} style={{ margin: '0' }}>
                                <SecondaryText>x Remove</SecondaryText>
                            </DeleteNote>
                        </Col>
                    </Row>
                </>
            )}
            <FormItem htmlFor="category-square-image" style={{ position: 'relative', marginTop: '1em' }}>
                <FormItemName>Square category image</FormItemName>
                <UploadInput
                    type="img"
                    fileSizeLimit={1}
                    onUpload={props.onSquareImageUpload}
                    handleUploadSuccessResponse={props.onSquareImageUploadSuccess}
                    parseErrorMessage
                    inputId="category-square-image"
                    replaceTopMessages={props.replaceMessages}
                    removeAllMessages={props.removeAllMessages}
                />
                <UploadExplanation>
                    <Body size={3}>Image should be square, e.g. 300x300</Body>
                </UploadExplanation>
            </FormItem>
            {!!newData.squareImage && (
                <>
                    <FormItemName>Current square image url:</FormItemName>
                    <Row>
                        <ImageUrlCol span={9}>
                            <ImgLink href={newData.squareImage} target="_blank">
                                {newData.squareImage}
                            </ImgLink>
                        </ImageUrlCol>
                        <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <DeleteNote onClick={removeSquareCategoryImage} style={{ margin: '0' }}>
                                <SecondaryText>x Remove</SecondaryText>
                            </DeleteNote>
                        </Col>
                    </Row>
                </>
            )}
            <FormTogglerCol span={12} style={{ marginTop: '2.5em' }}>
                <FormItemName>
                    Expand by default
                    <Infotip pointer="left" maxWidth="28em">
                        Expanding a category card will show the image.
                    </Infotip>
                </FormItemName>
                <FormToggler
                    id="card-open-by-default"
                    isOn={!!newData.image && newData.cardOpenByDefault}
                    onClick={onCardOpenChange}
                    disabled={!newData.image}
                    title={newData.image ? 'Expand by default' : 'Only has an effect when a wide image is used'}
                />
            </FormTogglerCol>
        </div>
    )
}
