import { MarketType } from 'settings/accountSettings/accountInfo'
import { OnboardingTemplate } from './onboarding'

export function defaultOnboardingTemplateFor(
    accountSlug: string,
    accountName: string,
    websiteUrl: string,
    adyenAccountName: string,
    adyenCountryCode: string,
    supportAgentEmail: string,
    marketType: MarketType,
    validFrom: Date,
    validTo: Date,
): OnboardingTemplate {
    return {
        accountSlug,
        accountData: {
            name: accountName,
            websiteUrl,
            adyenSettings: {
                accountName: adyenAccountName,
                countryCode: adyenCountryCode,
            },
            emailSettings: {
                supportAgentName: 'Jane',
                supportEmail: supportAgentEmail,
            },
            marketType,
        },
        productsLists: [
            {
                productsList: {
                    parentId: null,
                    title: {
                        key: `default_lists.${accountSlug}_list_rtp`,
                        text: {
                            en: 'This is real time pricing',
                        },
                    },
                    flow: 'rtp',
                    priority: 1,
                    enabled: true,
                },
                articles: [
                    {
                        articleData: {
                            productsListIds: null,
                            name: {
                                key: `default_articles.${accountSlug}_rtp_adult`,
                                text: {
                                    en: 'Adult ticket',
                                },
                            },
                            description: {
                                key: `default_articles.${accountSlug}_rtp_adult_description`,
                                text: {
                                    en: 'Adult ticket description',
                                },
                            },
                            showFrom: validFrom,
                            showTo: validTo,
                            validFrom,
                            validTo,
                            pricingType: 'rtp',
                            priority: 1,
                            displayType: 'REGULAR_TICKET',
                            businessType: 'single_visit_ticket',
                        },
                        pricing: {
                            originalPrice: 30,
                            minAcceptedPrice: 10,
                            gatePrice: null,
                            avgTargetPrice: null,
                        },
                    },
                    {
                        articleData: {
                            productsListIds: null,
                            name: {
                                key: `default_articles.${accountSlug}_rtp_child`,
                                text: {
                                    en: 'Child ticket',
                                },
                            },
                            description: {
                                key: `default_articles.${accountSlug}_rtp_child_description`,
                                text: {
                                    en: 'Child ticket description',
                                },
                            },
                            showFrom: validFrom,
                            showTo: validTo,
                            validFrom,
                            validTo,
                            pricingType: 'rtp',
                            priority: 1,
                            displayType: 'REGULAR_TICKET',
                            businessType: 'single_visit_ticket',
                        },
                        pricing: {
                            originalPrice: 10,
                            minAcceptedPrice: 5,
                            gatePrice: null,
                            avgTargetPrice: null,
                        },
                    },
                    {
                        articleData: {
                            productsListIds: null,
                            name: {
                                key: `default_articles.${accountSlug}_rtp_parking`,
                                text: {
                                    en: 'Parking ticket',
                                },
                            },
                            description: {
                                key: `default_articles.${accountSlug}_rtp_parking_description`,
                                text: {
                                    en: 'Parking ticket description',
                                },
                            },
                            showFrom: validFrom,
                            showTo: validTo,
                            validFrom,
                            validTo,
                            pricingType: 'upsell',
                            priority: 1,
                            displayType: 'REGULAR_TICKET',
                            businessType: 'single_visit_ticket',
                        },
                        pricing: {
                            originalPrice: 5,
                            minAcceptedPrice: null,
                            gatePrice: null,
                            avgTargetPrice: null,
                        },
                    },
                ],
            },
            {
                productsList: {
                    parentId: null,
                    title: {
                        key: `default_lists.${accountSlug}_list_static`,
                        text: {
                            en: 'These are static prices',
                        },
                    },
                    flow: 'dated',
                    priority: 2,
                    enabled: true,
                },
                articles: [
                    {
                        articleData: {
                            productsListIds: null,
                            name: {
                                key: `default_articles.${accountSlug}_static_adult`,
                                text: {
                                    en: 'Adult ticket',
                                },
                            },
                            description: {
                                key: `default_articles.${accountSlug}_static_adult_description`,
                                text: {
                                    en: 'Adult ticket description',
                                },
                            },
                            showFrom: validFrom,
                            showTo: validTo,
                            validFrom,
                            validTo,
                            pricingType: 'static',
                            priority: 1,
                            displayType: 'REGULAR_TICKET',
                            businessType: 'single_visit_ticket',
                        },
                        pricing: {
                            originalPrice: 15,
                            minAcceptedPrice: null,
                            gatePrice: null,
                            avgTargetPrice: null,
                        },
                    },
                    {
                        articleData: {
                            productsListIds: null,
                            name: {
                                key: `default_articles.${accountSlug}_static_child`,
                                text: {
                                    en: 'Child ticket',
                                },
                            },
                            description: {
                                key: `default_articles.${accountSlug}_static_child_description`,
                                text: {
                                    en: 'Child ticket description',
                                },
                            },
                            showFrom: validFrom,
                            showTo: validTo,
                            validFrom,
                            validTo,
                            pricingType: 'static',
                            priority: 1,
                            displayType: 'REGULAR_TICKET',
                            businessType: 'single_visit_ticket',
                        },
                        pricing: {
                            originalPrice: 7.5,
                            minAcceptedPrice: null,
                            gatePrice: null,
                            avgTargetPrice: null,
                        },
                    },
                    {
                        articleData: {
                            productsListIds: null,
                            name: {
                                key: `default_articles.${accountSlug}_static_parking`,
                                text: {
                                    en: 'Parking',
                                },
                            },
                            description: {
                                key: `default_articles.${accountSlug}_static_parking_description`,
                                text: {
                                    en: 'PArking description',
                                },
                            },
                            showFrom: validFrom,
                            showTo: validTo,
                            validFrom,
                            validTo,
                            pricingType: 'upsell',
                            priority: 1,
                            displayType: 'REGULAR_TICKET',
                            businessType: 'single_visit_ticket',
                        },
                        pricing: {
                            originalPrice: 5,
                            minAcceptedPrice: null,
                            gatePrice: null,
                            avgTargetPrice: null,
                        },
                    },
                ],
            },
        ],
    }
}
