import React from 'react'
import './statusText.scss'

export type StatusStyle = 'success' | 'warn' | 'error' | 'disabled' | 'special'

interface StatusProps {
    statusStyle: StatusStyle
    children?: React.ReactNode
    style?: React.CSSProperties
}

export const StatusText = ({ statusStyle, children, style }: StatusProps) => (
    <div className={`status-text ${statusStyle}`} style={style}>
        {children}
    </div>
)

interface StatusColumnProps {
    children?: React.ReactNode
}

export const StatusColumn = ({ children }: StatusColumnProps) => <div className="status-column">{children}</div>
