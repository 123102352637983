import * as React from 'react'
import styled from 'styled-typed'
import { State } from 'store'
import { connect } from 'react-redux'
import { Account } from 'auth/state'
import { Sorting, Pagination } from 'uiComponents/table'
import { PageTitle, PageHeading, StyledATag } from 'uiComponents/typography'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import OrderSearchField from 'uiComponents/search/searchWithButton'
import ExportMenu from 'orders/export'
import { DateRange } from 'dateRanges'
import TransactionsTable from 'orders/transactions/transactionsTable'
import { OrdersService } from 'orders/ordersService'
import { cancelOutOffsetBeforeConvertionToUTC } from 'utils'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { OrderPage, defaultPageData, DateAndTime } from 'orders/schema'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { History } from 'history'
import Permission from 'admin/permissionRequired'
import { format } from 'date-fns'
import { UpdateDialog } from 'orders/transactions/updateDialogs'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { CompareRangeQuery } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { PaginationSection } from 'uiComponents/table/pagination'
import Feature from 'features/feature'
import { ActionButtonExternalLink, ActionButtonA, ActionButton } from 'uiComponents/buttons'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { withFeatures } from 'features'
import { withPermission } from 'admin/hocs'
import { User } from 'auth/state'
import OrdersFilter from '../ordersFilter'
import cloneDeep from 'lodash/cloneDeep'

const PageFeatures = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1em;
`
const RightSideFeatures = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`
const LeftSideFeatures = styled.div`
    display: flex;
    align-items: baseline;
`
const DatePickerWrapper = styled.div`
    position: relative;
    float: right;
    top: 4rem;
`
const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SearchResults = styled.div`
    font-style: italic;
    font-size: 0.875em;
    color: ${(props) => props.theme.colors.textLight};
    margin-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    font-weight: lighter;
    height: 1.35em;
`
const DisputesCenterLink = styled(ActionButtonA)`
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.border};
    position: relative;
    padding-right: 2.5em;
    color: ${(props) => props.theme.colors.textDark};
    background: ${(props) => props.theme.colors.white};

    &:hover:not(:disabled) {
        background: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.border};
    }

    &.no-disputes {
        padding-right: 1rem;
        background: ${(props) => props.theme.colors.background};
    }
`
const DisputesCount = styled.div`
    position: absolute;
    top: 0.2rem;
    right: 0.5rem;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.convious};
    border-radius: 50%;
    height: 24px;
    width: 24px;
    font-size: 0.875em;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface TransactionsPageProps {
    history: History
    search?: string
    dateRange: DateRange
    onDateRangeChanged: (dr: DateRange, compareRange: CompareRangeQuery) => void
    dateRangeType: DateRangeType
    onDateRangeTypeChange: (type: DateRangeType) => void
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    pagination: Pagination
    onPaginationChanged: (p: Pagination) => void
    accountSlug: string
    ordersService: OrdersService
    loginService: LoginService
    loggingService: LoggingService
    navigation: Navigation
    match: RouteMatch<{}>
    accounts: Account[]
    user: User | null
    backofficeEndpoint: string
    hasFeature: (feature: string, accountSlug: string) => boolean
    hasPermission: (permission: string, accountSlug: string) => boolean
}

interface TransactionsPageState {
    ordersPageData: OrderPage
    allOrdersSelected: boolean
    updateDialog: 'visitDate' | 'visitTime' | null
    updatedDateTime: DateAndTime
    updatedOrderId: string
    disputesCount: number
    loading: boolean
}

class TransactionsPage extends React.Component<TransactionsPageProps & MessageProps, TransactionsPageState> {
    lastCall: React.MutableRefObject<number>

    constructor(props: TransactionsPageProps & MessageProps) {
        super(props)
        this.state = {
            ordersPageData: defaultPageData,
            allOrdersSelected: false,
            updateDialog: null,
            updatedDateTime: { date: null, time: null },
            updatedOrderId: '',
            disputesCount: 0,
            loading: false,
        }

        this.lastCall = React.createRef() as React.MutableRefObject<number>
    }

    async componentDidMount() {
        await this.loadOrdersList()
    }

    async componentDidUpdate(prevProps: TransactionsPageProps) {
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug ||
            query.page !== prevQuery.page ||
            query.pageSize !== prevQuery.pageSize ||
            query.sortBy !== prevQuery.sortBy ||
            query.sortDirection !== prevQuery.sortDirection ||
            query.dateRangeType !== prevQuery.dateRangeType ||
            query.q !== prevQuery.q ||
            (!!query.q && query.searchType !== prevQuery.searchType) ||
            query.filter !== prevQuery.filter
        ) {
            await this.loadOrdersList()
        }
    }

    loadOrdersList = async () => {
        this.setState({ loading: true })
        await this.getOrdersList()
    }

    getOrdersList = async () => {
        const innerDate = new Date().valueOf()
        this.lastCall.current = innerDate

        try {
            const { sort, pagination, dateRange, dateRangeType, search, accountSlug } = this.props
            const query = this.props.navigation.query()
            const data = await this.props.ordersService.getTransactionsList(
                accountSlug,
                dateRange.from,
                dateRange.to,
                dateRangeType,
                search,
                sort.prop,
                sort.direction,
                pagination.page,
                pagination.pageSize,
                query.searchType === 'extended' ? 'extended' : 'simple',
                query.filter,
            )

            if (this.lastCall.current === innerDate) {
                this.setState({
                    ordersPageData: data.orders,
                    disputesCount: data.openDisputeCount,
                    loading: false,
                })
            }
        } catch {
            this.props.replaceMessages(
                'server_error',
                'error',
                'Oops! Transactions table could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    saveNewEmail = async (orderUuid: string, email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!re.test(email)) {
            this.props.replaceMessages('email_error', 'error', 'Invalid email. Please try again.')
        } else {
            this.setState({ loading: true })

            try {
                await this.props.ordersService.updateEmail(orderUuid, email)
                await this.getOrdersList()
            } catch {
                this.props.replaceMessages(
                    'email_error',
                    'error',
                    `Oops! There was a problem with saving email ${email}. Please try again.`,
                )
            }

            this.setState({ loading: false })
        }
    }

    saveNewVisitDate = (orderId: string, date: Date) => {
        this.setState({
            updateDialog: 'visitDate',
            updatedOrderId: orderId,
            updatedDateTime: {
                date: format(date, 'yyyy-MM-dd'),
                time: null,
            },
        })
    }

    saveNewVisitTime = async (orderId: string, time: string) =>
        new Promise((resolve) => {
            this.setState(
                {
                    updatedOrderId: orderId,
                    updatedDateTime: {
                        date: null,
                        time,
                    },
                },
                () => {
                    this.saveDateAndTime().then(resolve)
                },
            )
        })

    cancelSaveNewDateOrTime = () => {
        const ordersPageData = cloneDeep(this.state.ordersPageData)
        this.setState({ ordersPageData, updateDialog: null })
    }

    saveDateAndTime = async () => {
        const {
            updatedOrderId,
            updatedDateTime: { date, time },
        } = this.state
        try {
            this.setState({ updateDialog: null, loading: true })
            await this.props.ordersService.updateVisitDateAndTime(updatedOrderId, date, time)
            await this.getOrdersList()
        } catch (e) {
            if (e.message === 'no_availability') {
                this.props.replaceMessages(
                    'no_availability_error',
                    'error',
                    'No availability left for selected date or time.',
                )
            } else if (e.message === 'order_already_redeemed') {
                this.props.replaceMessages(
                    'already_redeemed_error',
                    'error',
                    'Cannot change date because order is already redeemed.',
                )
            } else if (e.message === 'date_change_not_possible') {
                this.props.replaceMessages('date_change_not_possible', 'error', 'Cannot change date for this order.')
            } else {
                this.props.replaceMessages(
                    'visit_date_error',
                    'error',
                    'Oops! There was a problem with saving new visit details. Please try again.',
                )
            }
        }
        this.setState({
            loading: false,
            updatedOrderId: '',
            updatedDateTime: { date: null, time: null },
        })
    }

    getLinkToCheckout = (account: Account) => {
        if (!!account.url) {
            const checkoutUrlParts = account.url.split('/')
            if (checkoutUrlParts[0].indexOf('http') < 0) {
                return `http://${account.url}#convious-control-panel`
            }
        }
        return `${account.url}#convious-control-panel`
    }

    checkResellerHasRequiredPermissions = () => {
        const accountPermissions = this.props.user?.permissions.filter((a) => a.account === this.props.accountSlug)
        const permissionsList =
            accountPermissions && accountPermissions.length > 0 ? accountPermissions[0].permissions : []
        return permissionsList.indexOf('edit_orders') > -1 && permissionsList.indexOf('view_pricing_settings') > -1
    }

    render() {
        const {
            sort,
            onSortChanged,
            dateRange,
            search,
            accountSlug,
            ordersService,
            hideMessage,
            loginService,
            loggingService,
            replaceMessages,
            history,
            accounts,
            onPaginationChanged,
            pagination,
        } = this.props
        const { loading, ordersPageData, updateDialog, disputesCount } = this.state

        const dateFrom = cancelOutOffsetBeforeConvertionToUTC(dateRange.from).toISOString()
        const dateTo = cancelOutOffsetBeforeConvertionToUTC(dateRange.to).toISOString()
        const query = this.props.navigation.query()

        const exportQuery = {
            search,
            widget_slug: accountSlug,
            date_from: dateFrom,
            date_to: dateTo,
            date_range_type: this.props.dateRangeType,
            sort_by: sort.prop,
            sort_direction: sort.direction,
            filters: query.filter,
        }

        const orderEntries = ordersPageData.entries
        const activeAccount = accounts.find((x) => x.slug === accountSlug) || accounts[0]
        const withTimeSlots = activeAccount ? activeAccount.timeSlotsEnabled : false
        const checkoutLink = this.getLinkToCheckout(activeAccount)
        const searchPlaceholder =
            query.searchType === 'extended'
                ? 'Search by ID, email, location or discount code'
                : 'Search by order ID or email'

        const userIsReseller = !!this.props.user?.resellerId
        const showInlineCOToResellers =
            userIsReseller &&
            this.props.hasFeature('ResellersFeature', accountSlug) &&
            activeAccount.activeResellerContract &&
            this.checkResellerHasRequiredPermissions()
        const showInlineCOToRegularUsers =
            this.props.hasFeature('CPInlineCheckout', accountSlug) &&
            this.props.hasPermission('edit_orders', accountSlug) &&
            this.props.hasPermission('view_pricing_settings', accountSlug)
        const inlineCheckout = showInlineCOToResellers || showInlineCOToRegularUsers
        const hideNewOrderButton = userIsReseller && !showInlineCOToResellers
        const resellerWithMultipleAccounts = !!this.props.user && this.props.user.accounts.length > 1
        const inliceCheckoutLink =
            userIsReseller && resellerWithMultipleAccounts
                ? `/resellers/account/${this.props.accountSlug}/new_order/`
                : `/account/${this.props.accountSlug}/orders/reseller/new_order`

        return (
            <>
                <Messages messages={this.props.messages} hideMessage={this.props.hideMessage} />
                {updateDialog && (
                    <UpdateDialog
                        dialogType={updateDialog}
                        order={orderEntries.find((o) => o.id === this.state.updatedOrderId)}
                        updatedDateTime={this.state.updatedDateTime}
                        onCancel={this.cancelSaveNewDateOrTime}
                        onConfirm={this.saveDateAndTime}
                    />
                )}
                <DatePickerWrapper>
                    <DateRangePicker
                        range={dateRange}
                        onChange={this.props.onDateRangeChanged}
                        dateRangeTypeDisabled={this.state.loading}
                        dateRangeType={this.props.dateRangeType}
                        onDateRangeTypeChange={this.props.onDateRangeTypeChange}
                        allowFutureDateSelection
                    />
                </DatePickerWrapper>
                <PageTitle data-userpilot="orders-page-header">Orders</PageTitle>
                <PageHeading>
                    View, edit, and refund orders, resend emails, and download e-tickets. You can view a short
                    introduction to order management{' '}
                    <StyledATag target="_blank" href="https://support.convious.com/help/how-to-manage-orders">
                        here
                    </StyledATag>
                    .
                </PageHeading>
                <PageFeatures>
                    <LeftSideFeatures>
                        <SearchContainer>
                            <OrderSearchField
                                placeholder={searchPlaceholder}
                                style={{ width: '22em' }}
                                disabled={loading}
                            />
                            <SearchResults>{!loading ? `${ordersPageData.totalCount} results` : ''}</SearchResults>
                        </SearchContainer>
                    </LeftSideFeatures>
                    <RightSideFeatures>
                        <OrdersFilter
                            page="orders"
                            accountSlug={accountSlug}
                            ordersService={this.props.ordersService}
                            replaceMessages={this.props.replaceMessages}
                            hideMessage={this.props.hideMessage}
                        />
                        <Feature name="PaypalResolutionCenter" accountSlug={accountSlug}>
                            <Permission name="partner_admin" accountSlug={accountSlug}>
                                <DisputesCenterLink
                                    kind="action"
                                    secondary
                                    href={`/account/${this.props.accountSlug}/orders/paypal/disputes/active/${location.search}&activeDisputesDateRange=last30days`}
                                    style={{ marginLeft: '1em' }}
                                    className={disputesCount === 0 ? 'no-disputes' : ''}
                                >
                                    Order disputes
                                    {!!disputesCount && (
                                        <DisputesCount>
                                            <span>{disputesCount}</span>
                                        </DisputesCount>
                                    )}
                                </DisputesCenterLink>
                            </Permission>
                        </Feature>
                        {inlineCheckout && !hideNewOrderButton && (
                            <ActionButton kind="action" secondary style={{ marginLeft: '1em', zIndex: 1 }}>
                                <UnstyledLink to={inliceCheckoutLink}>New order</UnstyledLink>
                            </ActionButton>
                        )}
                        {!inlineCheckout && !hideNewOrderButton && (
                            <ActionButtonExternalLink
                                href={checkoutLink}
                                target="_blank"
                                kind="action"
                                secondary
                                style={{ marginLeft: '1em', zIndex: 1 }}
                            >
                                New order
                            </ActionButtonExternalLink>
                        )}
                        <Permission name="export_orders" accountSlug={accountSlug}>
                            <ExportMenu
                                ordersService={ordersService}
                                ordersNumber={ordersPageData.totalCount}
                                ordersQuery={exportQuery}
                                loginService={loginService}
                                loggingService={loggingService}
                                backofficeEndpoint={this.props.backofficeEndpoint}
                                activeSlug={accountSlug}
                                hideMessage={hideMessage}
                                replaceMessages={replaceMessages}
                                dateRange={dateRange}
                                page="transactions"
                            />
                        </Permission>
                    </RightSideFeatures>
                </PageFeatures>
                <TransactionsTable
                    history={history}
                    orders={orderEntries}
                    loading={loading}
                    sort={sort}
                    onSortChanged={onSortChanged}
                    ordersService={ordersService}
                    loggingService={loggingService}
                    accountSlug={accountSlug}
                    saveNewEmail={this.saveNewEmail}
                    saveNewVisitDate={this.saveNewVisitDate}
                    saveNewVisitTime={this.saveNewVisitTime}
                    reloadOrderList={this.getOrdersList}
                    replaceMessages={this.props.replaceMessages}
                    hideMessage={this.props.hideMessage}
                    withTimeSlots={withTimeSlots}
                />
                <PaginationSection
                    pagination={pagination}
                    onPaginationChanged={onPaginationChanged}
                    totalItemsCount={ordersPageData.totalCount}
                />
            </>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        user: state.auth.user,
    }
}

export default withPermission(withFeatures(withNavigation(withMessages(connect(mapStateToProps)(TransactionsPage)))))
