import * as React from 'react'
import ActionButton from 'uiComponents/buttons'
import './css/addTaxConfigurationElement.scss'

interface AddTaxConfigurationElementProps {
    fullWidth: boolean
    onClick: () => void
}

export const AddTaxConfigurationElement = (props: AddTaxConfigurationElementProps) => {
    const { fullWidth, onClick } = props

    return (
        <>
            <div className={`addTaxConfigurationElement ${fullWidth ? 'full' : 'half'}`}>
                <div className="title">Add Tax Configuration</div>
                <div className="subtitle">
                    Create a new Tax Configuration, that can later be applied to all or specific products.
                </div>
                <ActionButton onClick={onClick}>+ Create Tax Configuration</ActionButton>
            </div>
        </>
    )
}
