import * as React from 'react'
import styled from 'styled-typed'
import { Upsell } from 'admin/articleService'
import { Col } from 'uiComponents/flex'
import { PlainLoader } from 'uiComponents/loaders'
import { ImageUploadResponse } from 'http/imagesService'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { UploadInput } from 'uiComponents/input/upload'
import { MessageKind } from 'uiComponents/messages'
import { ActionButton } from 'uiComponents/buttons'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { ToggleComponent, PickerComponent } from 'uiComponents/studio/form/fieldComponents'
import { Checkbox } from 'uiComponents/input'
import { SectionWrapper, FormSectionWrapper, SectionTitleWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormItemType, TemplateSettings, templatesConfig, TemplateType } from 'emailTemplates/schema'
import { SelectComponent } from 'uiComponents/studio/form/fieldComponents'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'
import { UpsellsSection } from './upsellsSection'
import Infotip from 'uiComponents/infotip'
import { TaxConfiguration } from 'settings/accountSettings/taxConfigurations/taxConfigurationsService'
import { getIsUSPartner } from 'auth/selectors'
import { useAppSelector } from 'store/hooks'

export const AlignedCol = styled(Col)`
    display: flex;
    align-items: center;

    &.align-right {
        justify-content: flex-end;
    }
`
const TestEmailSection = styled(FormSectionWrapper)`
    position: relative;
    padding-top: 1.5em;
`
const TestEmailSectionTitle = styled(SectionTitleWrapper)`
    font-size: 1.23em;
    flex-direction: column;
    span {
        color: ${(props) => props.theme.colors.textLight};
        font-size: 0.8em;
        font-weight: lighter;
        padding-top: 0.2em;
    }
`

interface GlobalSectionProps {
    accountSlug: string
    templateType: TemplateType
    templateSettings: TemplateSettings
    sendingEmail: boolean
    discountReservoirOptions: SingleSelectOption[]
    allUpsells: Upsell[]
    enabledUpsells: Upsell[]
    markAllErrors: boolean
    handleChange: (value: string | boolean | Upsell[], item: FormItemType) => void
    onSend: () => void
    onUpload: (file: File) => Promise<any>
    onUploadSuccess: (response: ImageUploadResponse, type: 'logo' | 'agentPhoto') => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    defaultTaxConfiguration?: TaxConfiguration
}

function GlobalSection(props: GlobalSectionProps) {
    type PickerType = 'picker'
    type ToggleType = 'toggle'
    type UploadType = 'upload'
    type SelectType = 'select'
    type CheckboxType = 'checkbox'
    const themeColourField = {
        name: 'theme-colour',
        type: 'picker' as PickerType,
        label: 'Theme colour',
        path: '',
        defaultValue: '#5298F4',
    }
    const backgroundColorField = {
        name: 'background-colour',
        type: 'picker' as PickerType,
        label: 'Background colour',
        path: '',
        defaultValue: '#F5F7FA',
    }
    const signatureField = {
        name: 'signature-enabled',
        type: 'toggle' as ToggleType,
        label: 'Signature enabled',
        path: '',
        defaultValue: true,
    }
    const socialIconsField = {
        name: 'social-iconst-enabled',
        type: 'toggle' as UploadType,
        label: 'Social icons enabled',
        path: '',
        defaultValue: true,
    }
    const taxField = {
        name: 'tax-enabled',
        type: 'toggle' as UploadType,
        label: 'Tax enabled',
        path: '',
        defaultValue: false,
    }
    const optInField = {
        name: 'opt-in-required',
        type: 'checkbox' as CheckboxType,
        label: 'Send only to emails with opt-in',
        path: '',
        defaultValue: false,
    }
    const logoUploadField = {
        name: 'logo-upload',
        type: 'toggle' as UploadType,
        label: 'Upload a logo',
        path: '',
        defaultValue: true,
    }
    const agentPhotoUploadField = {
        name: 'agent-photo-upload',
        type: 'toggle' as UploadType,
        label: 'Upload agent photo (square 1:1)',
        path: '',
        defaultValue: true,
    }
    const discountReservoirField = {
        type: 'select' as SelectType,
        name: 'coupon-pool',
        label: 'Coupon pool',
        path: '',
        defaultValue: null,
        availableOptions: props.discountReservoirOptions,
        infotip:
            'Please ensure the pool has coupons uploaded. If an attached coupon pool is empty, the email will not be sent.',
    }

    const isUSPartner = useAppSelector(getIsUSPartner)

    return (
        <>
            <SectionWrapper title="Global settings" unlimitedHeight>
                <FormItem>
                    <AlignedCol span={6}>
                        <label htmlFor={themeColourField.name}>{themeColourField.label}</label>
                    </AlignedCol>
                    <AlignedCol span={6} className="align-right">
                        <PickerComponent
                            field={themeColourField}
                            handleChange={(v: string) => props.handleChange(v, 'themeColor')}
                            value={props.templateSettings.themeColor}
                        />
                    </AlignedCol>
                </FormItem>
                <FormItem>
                    <AlignedCol span={6}>
                        <label htmlFor={backgroundColorField.name}>{backgroundColorField.label}</label>
                    </AlignedCol>
                    <AlignedCol span={6} className="align-right">
                        <PickerComponent
                            field={backgroundColorField}
                            handleChange={(v: string) => props.handleChange(v, 'backgroundColor')}
                            value={props.templateSettings.backgroundColor}
                        />
                    </AlignedCol>
                </FormItem>
                {templatesConfig[props.templateType].customFields.indexOf('requiresOptIn') > -1 && (
                    <FormItem>
                        <Col span={9}>
                            <label htmlFor={optInField.name}>{optInField.label}</label>
                        </Col>
                        <AlignedCol span={3} className="align-right">
                            <div style={{ fontSize: '0.875rem' }}>
                                <Checkbox
                                    name={optInField.name}
                                    onChange={(v) => props.handleChange(v.target.checked, 'requiresOptIn')}
                                    checked={!!props.templateSettings.requiresOptIn}
                                />
                            </div>
                        </AlignedCol>
                    </FormItem>
                )}
                <FormItem>
                    <Col span={9}>
                        <label htmlFor={signatureField.name}>{signatureField.label}</label>
                    </Col>
                    <AlignedCol span={3} className="align-right">
                        <ToggleComponent
                            field={signatureField}
                            handleChange={(v: string) => props.handleChange(v, 'signatureEnabled')}
                            value={props.templateSettings.signatureEnabled}
                        />
                    </AlignedCol>
                </FormItem>
                <FormItem>
                    <Col span={9}>
                        <label htmlFor={socialIconsField.name}>{socialIconsField.label}</label>
                    </Col>
                    <AlignedCol span={3} className="align-right">
                        <ToggleComponent
                            field={socialIconsField}
                            handleChange={(v: string) => props.handleChange(v, 'socialIconsEnabled')}
                            value={props.templateSettings.socialIconsEnabled}
                        />
                    </AlignedCol>
                </FormItem>
                {templatesConfig[props.templateType].customFields.indexOf('vatEnabled') > -1 && !isUSPartner && (
                    <FormItem>
                        <Col span={9}>
                            <label htmlFor={taxField.name}>{taxField.label}</label>
                        </Col>
                        <AlignedCol span={3} className="align-right">
                            <DisabledItemHoverInfotip
                                active={!props.defaultTaxConfiguration}
                                infotipText="Account Tax is not set"
                                width="13em"
                                offsetLeft="-9em"
                                offsetTop={35}
                            >
                                <ToggleComponent
                                    field={taxField}
                                    handleChange={(v: string) => props.handleChange(v, 'vatEnabled')}
                                    value={props.templateSettings.vatEnabled}
                                    disabled={!props.defaultTaxConfiguration}
                                />
                            </DisabledItemHoverInfotip>
                        </AlignedCol>
                    </FormItem>
                )}
                {templatesConfig[props.templateType].customFields.indexOf('discountReservoir') > -1 && (
                    <FormItem>
                        <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor={discountReservoirField.name}>
                                {discountReservoirField.label}
                                <Infotip pointer="left" maxWidth="17em" smallBoxText>
                                    {discountReservoirField.infotip}
                                </Infotip>
                            </label>
                        </Col>
                        <AlignedCol span={8} className="align-right">
                            <SelectComponent
                                id="discountReservoir"
                                field={discountReservoirField}
                                noSelectOption="No coupons used"
                                handleChange={(v) => props.handleChange(v as string, 'discountReservoir')}
                                value={props.templateSettings.discountReservoir || ''}
                            />
                        </AlignedCol>
                    </FormItem>
                )}
                {templatesConfig[props.templateType].customFields.indexOf('upsellsEnabled') > -1 && (
                    <UpsellsSection
                        templateSettings={props.templateSettings}
                        allUpsells={props.allUpsells}
                        enabledUpsells={props.enabledUpsells}
                        markAllErrors={props.markAllErrors}
                        handleChange={props.handleChange}
                        onUpload={props.onUpload}
                        onUploadSuccess={props.onUploadSuccess}
                        replaceTopMessages={props.replaceTopMessages}
                        removeAllMessages={props.removeAllMessages}
                    />
                )}
            </SectionWrapper>
            <SectionWrapper title="Uploads">
                <FormItem>
                    <Col span={12}>
                        <label htmlFor={logoUploadField.name}>{logoUploadField.label}</label>
                    </Col>
                    <Col span={12}>
                        <UploadInput
                            type="img"
                            fileSizeLimit={0.25}
                            onUpload={props.onUpload}
                            handleUploadSuccessResponse={(response: ImageUploadResponse) =>
                                props.onUploadSuccess(response, 'logo')
                            }
                            inputId={logoUploadField.name}
                            replaceTopMessages={props.replaceTopMessages}
                            removeAllMessages={props.removeAllMessages}
                        />
                    </Col>
                </FormItem>
                <FormItem>
                    <Col span={12}>
                        <label htmlFor={agentPhotoUploadField.name}>{agentPhotoUploadField.label}</label>
                    </Col>
                    <Col span={12}>
                        <UploadInput
                            type="img"
                            fileSizeLimit={0.25}
                            onUpload={props.onUpload}
                            handleUploadSuccessResponse={(response: ImageUploadResponse) =>
                                props.onUploadSuccess(response, 'agentPhoto')
                            }
                            inputId={agentPhotoUploadField.name}
                            replaceTopMessages={props.replaceTopMessages}
                            removeAllMessages={props.removeAllMessages}
                        />
                    </Col>
                </FormItem>
            </SectionWrapper>
            <TestEmailSection>
                {props.sendingEmail && (
                    <PlainLoader
                        style={{
                            position: 'absolute',
                            right: '1em',
                            top: '.5em',
                            width: '4em',
                        }}
                    />
                )}
                <FormItem>
                    <AlignedCol span={8}>
                        <TestEmailSectionTitle>
                            Receive test email
                            <span>Test email in HTML preview.</span>
                        </TestEmailSectionTitle>
                    </AlignedCol>
                    <AlignedCol span={4} className="align-right">
                        <ActionButton disabled={props.sendingEmail} kind="action" onClick={props.onSend}>
                            Send
                        </ActionButton>
                    </AlignedCol>
                </FormItem>
            </TestEmailSection>
        </>
    )
}

export default GlobalSection
