import React from 'react'
import ReactDOMServer from 'react-dom/server'
import PeopleDoorDuotone from '@convious/icons/src/icons/duotone/peopleDoorDuotone.svg'
import PeopleRoofDuotone from '@convious/icons/src/icons/duotone/peopleRoofDuotone.svg'
import TimesIconSolid from '@convious/icons/src/icons/solid/timesSolid.svg'
import { TimelineItem, TimelineGroup } from '../types'

const svgToString = (SvgComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>): string => {
    return ReactDOMServer.renderToStaticMarkup(<SvgComponent />)
}

const doorIcon = svgToString(PeopleDoorDuotone)
const roofIcon = svgToString(PeopleRoofDuotone)
const timesIcon = svgToString(TimesIconSolid)

const getGroupTemplate = (group: TimelineGroup): string => {
    if (!group) return ''
    return `
        <div>
            ${group.reservationPeriodType === 'Gate' ? doorIcon : roofIcon}
            ${group.content}
        </div>
    `
}

const getPeriodItemTemplate = (item: TimelineItem): string => {
    return `
        <div>
            <strong>${item.reserved} reserved</strong>
            <span>${item.available} remaining</span>
        </div>
    `
}

const getInstantItemTemplate = (item: TimelineItem): string => {
    return `
        <div>
            <strong>${item.available}</strong>
        </div>
    `
}

const getIconItemTemplate = (icon: string, text: string): string => {
    return `
        <div>
            ${icon}
            <span>${text}</span>
        </div>
    `
}

const getItemTemplate = (item: TimelineItem): string => {
    if (item.reservationType === 'instant') {
        return getInstantItemTemplate(item)
    }
    if (item.capacity === 0) {
        return getIconItemTemplate(timesIcon, 'Closed')
    }
    if (item.capacity === null) {
        return getIconItemTemplate(timesIcon, 'Unlimited')
    }
    return getPeriodItemTemplate(item)
}

export { getItemTemplate, getGroupTemplate }
