import { ProductsMetaData, Article, FilterOption, FilterCategory } from 'uiComponents/filter/schema'

export const getProductOptions = (products: ProductsMetaData[]): FilterOption[] => {
    const onlyArticles = products.filter((p) => !p.children && !p.articles).length === products.length
    return onlyArticles ? getMappedArticles(products as any as Article[], []) : getMappedProductLists(products, [])
}

export const getProductTreeOptions = (products: Article[], categories: ProductsMetaData[]) => {
    const options = !!categories.length ? getMappedProductLists(categories, []) : getMappedArticles(products, [])
    return options
}

const getMappedProductLists = (productList: ProductsMetaData[], parents: string[]): FilterOption[] => {
    return productList.map((pl) => ({
        name: pl.name,
        slug: pl.uuid,
        category: 'products' as FilterCategory,
        parents: parents,
        children:
            pl.children && pl.children.length > 0
                ? getMappedProductLists(pl.children, [...parents, pl.uuid])
                : getMappedArticles(pl.articles, [...parents, pl.uuid]),
        leafNode: !pl.children && !pl.articles,
    }))
}

const getMappedArticles = (articles: Article[], parents: string[]): FilterOption[] => {
    return articles.map((a) => ({
        name: a.name,
        slug: a.id,
        category: 'products' as FilterCategory,
        parents,
        children: [],
        leafNode: true,
    }))
}

interface OptionConfigItem {
    name: string
    slug: string
}

export const formatFlatOptions = (category: FilterCategory, optionsConfig: OptionConfigItem[]) => {
    return optionsConfig.map((o) => ({
        category,
        name: o.name,
        slug: o.slug,
        parents: [],
        children: [],
        leafNode: true,
    }))
}

export const getFiltersFromUrl = (filterCategory: FilterCategory): string => {
    const filterParam = new URLSearchParams(location.search).get('filter')
    if (!filterParam) return ''

    const filter = filterParam
        .split(',')
        .map(decodeURIComponent)
        .find((uriComponent) => uriComponent.startsWith(`${filterCategory}:`))

    if (!filter) return ''

    const [, value] = filter.split(':')
    return value
}
