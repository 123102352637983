import React, { useState } from 'react'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { TaxConfiguration } from './types'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import './css/actionMenu.scss'

interface ActionMenuProps {
    taxConfiguration: TaxConfiguration
    editingTaxConfiguration: boolean
    isDefault?: boolean
    setDefaultTaxConfiguration: (taxConfigurationUuid: string) => void
    deleteTaxConfiguration: (taxConfigurationUuid: string) => void
    cancel: () => void
}

export const ActionMenu = ({
    taxConfiguration,
    editingTaxConfiguration,
    isDefault,
    setDefaultTaxConfiguration,
    deleteTaxConfiguration,
    cancel,
}: ActionMenuProps) => {
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false)
    const canSetDefault = !isDefault && !editingTaxConfiguration
    const setDefaultTitle = isDefault
        ? 'This configuration is already the default'
        : editingTaxConfiguration
        ? 'Save the tax configuration first'
        : 'Make this the default tax configuration'

    const canDelete = !isDefault
    const deleteTitle = isDefault ? 'You cannot delete the default configuration' : 'Delete this tax configuration'

    return (
        <>
            <OnClickMenu
                className="actions"
                title="Actions"
                kind="action"
                iconTrigger={faEllipsisV as IconProp}
                secondary
            >
                <OnClickMenuItem
                    className={`setDefault${!canSetDefault ? ' disabled' : ''}`}
                    title={setDefaultTitle}
                    onClick={() => {
                        if (canSetDefault) {
                            setDefaultTaxConfiguration(taxConfiguration.uuid)
                        }
                    }}
                >
                    Set as default
                </OnClickMenuItem>
                <OnClickMenuItem
                    className={`delete${!canDelete ? ' disabled' : ''}`}
                    title={deleteTitle}
                    onClick={() => {
                        if (canDelete) {
                            setShowDeleteConfirmationDialog(true)
                        }
                    }}
                >
                    Delete
                </OnClickMenuItem>
            </OnClickMenu>
            {showDeleteConfirmationDialog && (
                <ConfirmationDialog
                    title="Delete tax configuration?"
                    text="This action cannot be undone. Do you want to proceed?"
                    buttonText="Delete"
                    className="deleteTaxConfigurationConfirmationDialog"
                    onCancel={() => setShowDeleteConfirmationDialog(false)}
                    onConfirm={() => {
                        if (taxConfiguration.uuid) {
                            deleteTaxConfiguration(taxConfiguration.uuid)
                        } else {
                            cancel()
                        }
                        setShowDeleteConfirmationDialog(false)
                    }}
                />
            )}
        </>
    )
}
