import styled from 'styled-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface CategoryProps {
    height: number
}

export const FilterToggleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    max-width: 1200px;
    z-index: 100;
`
export const Category = styled.div<CategoryProps>`
    position: relative;
    display: flex;
    align-items: center;
    height: ${(props) => `${props.height}em`};
    padding: 0 2.6em 0 1.85em;
    cursor: pointer;

    &.active,
    &:hover {
        background: ${(props) => props.theme.colors.background};
    }
    &.applied {
        color: ${(props) => props.theme.colors.textLight};
    }
`
export const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0.6em;
    right: 1em;
    opacity: 0;
    transition: opacity 0.2s;

    &.visible {
        opacity: 1;
    }
`

export const FilterBaseElement = styled.div`
    font-size: 0.75rem;
    height: 2rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
`

interface ContainerProps {
    open?: boolean
}

export const CategoriesContainer = styled.div<ContainerProps>`
    height: ${(p) => (p.open ? '0' : '2rem')};
    position: relative;
    display: flex;
    &.loading {
        opacity: 0.5;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent;
            pointer: auto;
            z-index: 10;
        }
    }
`
export const ActionsSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.7em 1em;
    border-top: 1px solid ${(props) => props.theme.colors.background};
`
export const ActionText = styled.div`
    cursor: pointer;
    min-width: 4em;
`
