import React, { useRef } from 'react'
import { SingleSelectOption, TextInput } from 'uiComponents/input'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { SingleSelect } from 'uiComponents/input'
import { ActionButton } from 'uiComponents/buttons'
import { MessageKind } from 'uiComponents/messages'
import { delay, formatISOString, parseDate } from 'utils'
import classNames from 'classnames'
import { SearchSolid } from '@convious/icons'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import './searchBar.scss'

export interface SearchBarProps {
    searchByOptions: SingleSelectOption[]
    defaultSearchBy?: string
    navigation: Navigation
    match: RouteMatch<any>
    minSearchLength?: number
    maxSearchLength?: number
    onSearch?: () => void
    onChange?: (search: string) => void
    onSearchByChange?: (searchBy: string) => void
    replaceMessages?: (id: string, status: MessageKind, text: string) => void
    removeAllMessages?: () => void
    autoSearchByPatterns?: [RegExp, string][]
    showClearButton?: boolean
    placeholder?: string
}

export const getSearchByOptionByValue = (searchByOptions: SingleSelectOption[], value: string) =>
    searchByOptions.find((option) => option.value === value)

const SearchBar = ({
    searchByOptions,
    defaultSearchBy,
    navigation,
    minSearchLength,
    maxSearchLength,
    onSearch,
    onChange,
    onSearchByChange,
    replaceMessages,
    removeAllMessages,
    autoSearchByPatterns,
    showClearButton,
    placeholder,
}: SearchBarProps) => {
    const [search, setSearch] = React.useState<string>('')
    const [searchBy, setSearchBy] = React.useState<string>('')
    const [searchByType, setSearchByType] = React.useState<string>('text')
    const searchByOption = getSearchByOptionByValue(searchByOptions, searchBy)
    const searchPlaceholder =
        placeholder || (searchByOption?.name ? `Enter ${searchByOption.name}` : 'Enter search text')
    const searchCointainerRef = useRef<HTMLDivElement>(null)
    const searchByCointainerRef = useRef<HTMLDivElement>(null)

    const setSearchParameters = () => {
        const query = navigation.query()
        const searchText = query.search || ''
        const searchBy = query.searchBy || defaultSearchBy || ''
        setSearch(searchText)
        setSearchBy(searchBy)
        setSearchByType(getInputType(searchBy))
    }

    React.useEffect(setSearchParameters, [window.location.pathname])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
        if (onChange) {
            onChange(value)
        }

        if (autoSearchByPatterns) {
            for (const [regex, searchByValue] of autoSearchByPatterns) {
                if (regex.test(value)) {
                    setSearchBy(searchByValue)
                    break
                }
            }
        }
    }

    const getInputType = (searchBy: string) => {
        return searchByOptions.find((option) => option.value === searchBy)?.inputType || 'text'
    }

    const hasInputTypeChanged = (inputType: string) => {
        return getInputType(inputType) !== searchByType
    }

    const handleSearchByChange = (value: string) => {
        setSearchBy(value)
        if (onSearchByChange) {
            onSearchByChange(value)
        }

        const inputType = getInputType(value)
        setSearchByType(inputType)
        if (hasInputTypeChanged(inputType)) {
            setSearch('')
        }
    }

    const handleClearSearch = () => {
        setSearch('')
        if (onChange) {
            onChange('')
        }

        if (search) {
            navigation.addQueryWithReplace({ search: '' })
            if (onSearch) {
                onSearch()
            }
        }
    }

    const executeSearch = async () => {
        const trimmedSearch = search.trim()
        if (search !== trimmedSearch) {
            setSearch(trimmedSearch)
        }

        if (minSearchLength && trimmedSearch.length < minSearchLength) {
            await flashErrorMessage(`Minimum search length is ${minSearchLength} symbols`)
        } else if (maxSearchLength && trimmedSearch.length > maxSearchLength) {
            await flashErrorMessage(`Maximum search length is ${maxSearchLength} symbols`)
        } else {
            navigation.addQueryWithReplace({ search: trimmedSearch, searchBy })
            if (onSearch) {
                onSearch()
            }
        }
    }

    const flashErrorMessage = async (text: string) => {
        if (replaceMessages) {
            replaceMessages('error', 'error', text)
        }

        if (removeAllMessages) {
            await delay(4000)
            removeAllMessages()
        }
    }

    return (
        <div className="search-bar-container">
            <div className="search-bar">
                <div className="search-container" ref={searchCointainerRef}>
                    {searchByType === 'date' ? (
                        <DatePicker
                            className="search-date"
                            date={parseDate(search)}
                            onChange={(value) => setSearch(value ? formatISOString(value, 'yyyy-MM-dd') : '')}
                        />
                    ) : (
                        <TextInput
                            id="search-text"
                            className="search-text"
                            placeholder={searchPlaceholder}
                            value={search}
                            onChange={handleSearchChange}
                            onKeyUp={(event) => {
                                if (event.key === 'Enter') {
                                    executeSearch()
                                }
                            }}
                            block
                        />
                    )}
                </div>
                <div
                    className={classNames('search-by-container', { visible: searchByOptions.length > 1 })}
                    ref={searchByCointainerRef}
                >
                    <SingleSelect
                        className="search-by"
                        options={searchByOptions}
                        noSelectOption="Select search by"
                        selected={searchBy}
                        onSelect={handleSearchByChange}
                        whiteBackground
                        height="unset"
                    />
                </div>
            </div>
            <ActionButton className="search-button" kind="action" size="large" onClick={executeSearch}>
                <SearchSolid />
            </ActionButton>
            {showClearButton && (
                <ActionButton
                    className={classNames('clear-search', { visible: search !== '' })}
                    disabled={search === ''}
                    kind="action"
                    onClick={handleClearSearch}
                >
                    Clear
                </ActionButton>
            )}
        </div>
    )
}

export default withNavigation(SearchBar)
