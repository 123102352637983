import React, { useCallback, useMemo, useRef } from 'react'
import * as Yup from 'yup'
import { Box, Button, ToggleButton } from '@mui/material'
import { withFeatures } from 'features'
import { Field, FormikConfig, FormikHelpers, FormikProps, FormikValues } from 'formik'
import { ToggleButtonGroup } from 'formik-mui'
import { History } from 'history'
import { withNavigation, WithNavigationProps } from 'hocs'
import { InventoryServiceContext } from 'inventory/context'
import { InventoryItem, InventoryRule } from 'inventory/inventoryService'
import { Link } from 'react-router-dom'
import { FormWrapper } from 'uiComponents/form/form'
import { FormItemName } from 'uiComponents/form/formElements'
import FieldWrapper from 'uiComponents/input/fieldWrapper'
import RadioFieldFormik from 'uiComponents/input/radio/radioFieldFormik'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import { ChartDataLoader, TableLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { TextWithHorizontalLine } from 'uiComponents/pageElements'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { Caption } from 'uiComponents/typography'
import { addSeparators, delay } from 'utils'
import { RulesList } from './rulesList'
import { CrowdControlFormState } from './types'
import PeopleDoorSolid from '@convious/icons/src/icons/solid/peopleDoorSolid.svg'
import PeopleRoofSolid from '@convious/icons/src/icons/solid/peopleRoofSolid.svg'
import PeopleDoorDuotone from '@convious/icons/src/icons/duotone/peopleDoorDuotone.svg'
import PeopleRoofDuotone from '@convious/icons/src/icons/duotone/peopleRoofDuotone.svg'
import { Body } from 'uiComponents/typography'
import './crowdControl.scss'

const InventoryFormSchema = Yup.object().shape({
    name: Yup.string().required().max(500),
    limitType: Yup.string().required(),
    isLimited: Yup.boolean(),
    limit: Yup.mixed().when('isLimited', {
        is: true,
        then: Yup.number().typeError('Required').required().min(0).max(100000),
        otherwise: Yup.mixed().optional(),
    }),
})
const limitInfo = {
    capacity: {
        title: 'Capacity Limit',
        description:
            'Capacity Limit is useful when you have limited space or shared resources and you want to avoid overcrowding. You can set a limit on how many people can be in each activity at once, without worrying about conflicting time slots or different visitors arriving.',
        Icon: PeopleRoofDuotone,
    },
    admission: {
        title: 'Admission Limit',
        description:
            'Admission Limit helps control the number of people entering at once. You decide how many people you can handle for each activity. This ensures a smother experience and avoids overcrowding.',
        Icon: PeopleDoorDuotone,
    },
}

const Summary: React.FC<CrowdControlFormState> = ({ name, isLimited, limit, limitType }) => {
    const activityName = name || 'Name of Activity'
    const actionPhrase = limitType === 'capacity' ? 'staying in' : 'entry to'
    const limitDescription = isLimited ? (limit != null ? `${addSeparators(limit)}` : 'Input number') : 'Unlimited'

    return (
        <p>
            <strong>{limitDescription}</strong> people limit is set for {actionPhrase} the&nbsp;
            <strong>&quot;{activityName}&quot;</strong> activity at any given time.
        </p>
    )
}

interface CrowdControlFormParams {
    accountSlug: string
    id: string
}

interface CrowdControlFormProps extends WithNavigationProps<CrowdControlFormParams> {
    accountSlug: string
    history: History
    setActiveSection: (section: string, header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

const CrowdControlForm: React.FC<CrowdControlFormProps> = ({
    accountSlug,
    history,
    match,
    hasFeature,
    replaceMessages,
    setActiveSection,
    removeAllMessages,
}) => {
    const inventoryService = React.useContext(InventoryServiceContext)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [updating, setUpdating] = React.useState<boolean>(false)
    const [rulesList, setRulesList] = React.useState<InventoryRule[]>([])
    const formikRef = useRef<FormikProps<FormikValues>>(null)

    const initialValues: CrowdControlFormState = React.useMemo(
        () => ({ name: '', limitType: 'capacity', isLimited: true, limit: undefined }),
        [],
    )

    const fetchInventoryData = async (id: string) => {
        try {
            setLoading(true)
            const data = await inventoryService.getInventoryDetails(accountSlug, id)
            initialValues.name = data.name
            initialValues.limit = data.defaultAvailability
            initialValues.isLimited = data.defaultAvailability !== null
            initialValues.limitType = data.reservationPeriodType === 'Venue' ? 'capacity' : 'admission'
            setRulesList(data.availabilityRules)
        } catch {
            replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    const inventoryId = useMemo(() => match.params.id, [match.params.id])

    React.useEffect(() => {
        setActiveSection('inventory', 'Crowd Control')
        if (!!inventoryId && inventoryId !== 'new') {
            fetchInventoryData(inventoryId)
        }
    }, [inventoryId])

    const onInventorySave: FormikConfig<CrowdControlFormState>['onSubmit'] = async (values, { validateForm }) => {
        const { name, limit, isLimited, limitType } = values
        const formErrors = await validateForm()

        if (formErrors && Object.keys(formErrors).length > 0) {
            return
        }

        try {
            setUpdating(true)
            const payload: InventoryItem = {
                id: match.params.id,
                name,
                account: accountSlug,
                defaultAvailability: isLimited ? limit : null,
                reservationPeriodType: limitType === 'capacity' ? 'Venue' : 'Gate',
            }
            if (match.params.id !== 'new') {
                await inventoryService.updateInventoryItem(accountSlug, payload)
            } else {
                const savedInventory = await inventoryService.createInventoryItem(accountSlug, payload)
                const id = savedInventory.id
                history.push(`/account/${accountSlug}/products/inventory/item/${id}`)
            }
            setUpdating(false)
            replaceMessages('success', 'success', 'The inventory capacity has been saved successfully.')
            formikRef.current?.resetForm({ values: formikRef.current.values })

            await delay(3000)
            removeAllMessages()
        } catch {
            setUpdating(false)
            replaceMessages(
                'server_error',
                'error',
                'Oops! Could not save the inventory capacity, please try again later.',
            )
        }
    }

    const handleLimitTypeChange = (
        value: CrowdControlFormState['limitType'],
        setFieldValue: FormikHelpers<CrowdControlFormState>['setFieldValue'],
    ) => {
        if (value !== null) {
            setFieldValue('limitType', value)
        }
    }

    const isNew = useMemo(() => inventoryId === 'new', [inventoryId])

    const renderLimitType = useCallback(() => {
        if (isNew && formikRef.current) {
            const { setFieldValue } = formikRef.current

            return (
                <FieldWrapper label="Limit type" status="normal">
                    <Field
                        component={ToggleButtonGroup}
                        name="limitType"
                        className="iconToggles"
                        type="checkbox"
                        exclusive
                    >
                        <ToggleButton
                            value="capacity"
                            aria-label="capacity-limit"
                            size="medium"
                            disabled={!isNew}
                            onChange={() => handleLimitTypeChange('capacity', setFieldValue)}
                        >
                            <PeopleRoofSolid className="crowd-control-limit-type-icon" />
                            Capacity Limit
                        </ToggleButton>
                        <ToggleButton
                            value="admission"
                            aria-label="admission-limit"
                            size="medium"
                            disabled={!isNew}
                            onChange={() => handleLimitTypeChange('admission', setFieldValue)}
                        >
                            <PeopleDoorSolid className="crowd-control-limit-type-icon" />
                            Admission Limit
                        </ToggleButton>
                    </Field>
                </FieldWrapper>
            )
        }
        return (
            <FieldWrapper label="Limit type" status="normal">
                <Body color="light">
                    This cannot be changed after the Activity was created. Create a new activity if you require a
                    different Limit Type or <a href="mailto:support@convious.com">contact support</a> for further
                    assistance.
                </Body>
            </FieldWrapper>
        )
    }, [isNew, handleLimitTypeChange])

    return (
        <>
            {loading && <TableLoader style={{ minHeight: '41em' }} />}
            {updating && <ChartDataLoader />}
            {!loading && (
                <>
                    <FormWrapper
                        enableReinitialize
                        validateOnChange
                        initialValues={initialValues}
                        onSubmit={onInventorySave}
                        validationSchema={InventoryFormSchema}
                        className="crowd-control"
                        innerRef={formikRef}
                    >
                        {({ values, setFieldValue, setFieldError, dirty }) => {
                            const { title, description, Icon } = limitInfo[values.limitType]

                            return (
                                <>
                                    <section className="crowd-control-left">
                                        <TextInputFieldFormik
                                            id="name"
                                            name="name"
                                            label="Activity name"
                                            placeholder="Name your activity setup"
                                        />

                                        {renderLimitType()}

                                        <Box>
                                            <FormItemName weight="medium" size={1} className="label">
                                                Default limit
                                            </FormItemName>
                                            <div className="crowd-control-radio">
                                                <RadioFieldFormik
                                                    checkMarkSize="small"
                                                    name="isLimited"
                                                    label="Unlimited"
                                                    checked={!values.isLimited}
                                                    onChange={() => {
                                                        setFieldError('limit', undefined)
                                                        setFieldValue('isLimited', false)
                                                    }}
                                                />
                                                <RadioFieldFormik
                                                    checkMarkSize="small"
                                                    name="isLimited"
                                                    label="Limit of"
                                                    checked={values.isLimited}
                                                    onChange={() => {
                                                        setFieldValue('isLimited', true)
                                                    }}
                                                />
                                                <TextInputFieldFormik
                                                    id="limit"
                                                    name="limit"
                                                    type="number"
                                                    placeholder="People"
                                                    className="crowd-control-limit-input"
                                                    disabled={!values.isLimited}
                                                    validateOnChange
                                                />
                                            </div>
                                        </Box>
                                        <div className="crowd-control-left-buttons">
                                            <Button
                                                component={Link}
                                                to={`/account/${accountSlug}/products/inventory/home`}
                                                variant="outlined"
                                                size="large"
                                            >
                                                Cancel
                                            </Button>

                                            <Button type="submit" variant="contained" size="large" disabled={!dirty}>
                                                Save
                                            </Button>
                                        </div>
                                    </section>
                                    <section className="crowd-control-right">
                                        <Box display="flex" flexDirection="column" alignItems="center">
                                            <Icon className="crowd-control-right-icon" />
                                            <h5>{title}</h5>
                                        </Box>
                                        <p className="crowd-control-right-text">{description}</p>
                                        <div className="crowd-control-right-summary">
                                            <h6>How it will work:</h6>
                                            <Summary
                                                name={values.name}
                                                isLimited={values.isLimited}
                                                limit={values.limit}
                                                limitType={values.limitType}
                                            />
                                        </div>
                                        <Caption color="light" className="caption">
                                            Unless specified differently with exceptions.
                                        </Caption>
                                    </section>
                                </>
                            )
                        }}
                    </FormWrapper>
                    {match.params.id !== 'new' && (
                        <Box padding="2rem" paddingTop="0" display="flex" flexDirection="column" alignItems="center">
                            <TextWithHorizontalLine text="Limit exceptions" alignment="left" />
                            {rulesList.length > 0 && (
                                <Box marginBottom={2} width="100%">
                                    <RulesList
                                        accountSlug={accountSlug}
                                        rulesList={rulesList}
                                        inventoryId={match.params.id}
                                        hasFeature={hasFeature}
                                    />
                                </Box>
                            )}
                            {rulesList.length === 0 && <NoResultsRow text="No limit exceptions created" />}
                            <div>
                                <Button
                                    component={Link}
                                    to={`/account/${accountSlug}/products/inventory/rule/new/item/${match.params.id}`}
                                    variant="text"
                                    size="large"
                                    id="newRule"
                                >
                                    Add exception
                                </Button>
                            </div>
                        </Box>
                    )}
                </>
            )}
        </>
    )
}

export default withFeatures(withNavigation(CrowdControlForm))
