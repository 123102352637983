import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import React, { FC } from 'react'
import styled, { css } from 'styled-typed'
import { TextInput } from 'uiComponents/input/textInput'
import { delay } from 'utils'
import { INPUT_HEIGHT } from '../textInput'
import { SelectElementProps } from './interface'
import SingleSelectOptionsList from './singleSelectOptionsList'
import { SingleSelectOptionProps } from './singleSelectOption'
import classNames from 'classnames'

export type Status = 'error' | 'normal' | 'success' | 'highlight'

export function sortOptionsAlphabetically(a: SingleSelectOption, b: SingleSelectOption) {
    if (a.name < b.name) {
        return -1
    }
    if (a.name > b.name) {
        return 1
    }
    return 0
}

export const SelectElement = styled.div<SelectElementProps>`
    font-size: 0.875rem;
    position: relative;
    background: ${(props) => (props.whiteBackground ? props.theme.colors.white : props.theme.colors.background)};
    color: ${(props) => props.theme.colors.textDark};
    border-radius: 0.375em;
    width: ${(props) => (props.width ? props.width : 'auto')};
    ${(props) => (props.lineHeight ? `line-height: ${props.lineHeight}` : null)};
    &.open {
        ${(props) => (props.widthWhenOpen ? `width: ${props.widthWhenOpen}` : null)};
    }
`

const borderCss = css`
    border: 1px solid ${(props) => props.theme.colors.textLight};
`
const successCss = css`
    border: 1px solid ${(props) => props.theme.colors.status.success};
`

const errorCss = css`
    border: 1px solid ${(props) => props.theme.colors.status.error};
`

const highlightCss = css`
    border: 1px solid ${(props) => props.theme.colors.boyBlue};
`

export const IconContainer = styled.div`
    position: relative;
    height: 1em;
    margin-left: 0.2em;
`
export const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in;

    &.visible {
        opacity: 1;
        z-index: 1;
    }
`
export const ClearIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.boyBlue};
    width: 14px !important;
    height: 14px;

    &:hover {
        background-color: ${(props) => props.theme.colors.boyBlueShades[10]};
        border-radius: 100%;
    }
`

export const SelectedOptionDisplay = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1em;
`

interface MinimizedElementProps {
    width?: string
    height?: string
    maxHeight?: string
    disabled?: boolean
    status: Status
    border?: boolean
}

export const MinimizedView = styled.div<MinimizedElementProps>`
    font-weight: lighter;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.9em 0 1.1em;
    height: ${(props) => (props.height ? props.height : INPUT_HEIGHT)};
    background-color: ${(props) => (props.disabled ? props.theme.colors.aluminiumShades[10] : 'none')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${(props) => (props.border ? borderCss : '')}
    ${(props) => (props.status === 'success' ? successCss : '')}
    ${(props) => (props.status === 'error' ? errorCss : '')}
    ${(props) => (props.status === 'highlight' ? highlightCss : '')}

    border-radius: .375em;

    &:focus {
        ${(props) => (props.status === 'success' ? successCss : '')}
        ${(props) => (props.status === 'error' ? errorCss : '')}
    ${(props) => (props.status === 'highlight' ? highlightCss : '')}
    }
    outline: none;
`

export const PopupArea = styled.div<SelectElementProps>`
    position: absolute;
    top: 2.8em;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.colors.white};
    border-radius: 0.375em;
    ${(props) => (props.border ? borderCss : '')}
    border-top: none;
    overflow: hidden;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in, visibility 0s 0.2s;

    &.visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in, visibility 0s 0s;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
`

export interface SingleSelectOption<T = any> {
    id?: string
    value: T
    name: string
    inputType?: string
    disabled?: boolean
    parentId?: string
    slug?: string
}

export interface SingleSelectProps<T> {
    id?: string
    className?: string
    options: SingleSelectOption<T>[]
    noSelectOption?: string
    selected: string | null
    width?: string
    height?: string
    maxHeight?: string
    lineHeight?: string
    onBlur?: () => void
    onSelect: (value: string) => void
    onFocus?: () => void
    disabled?: boolean
    style?: {}
    status?: Status
    whiteBackground?: boolean
    border?: boolean
    widthWhenOpen?: string
    withClear?: boolean
    withSearch?: boolean
    nested?: boolean
    customIconUp?: IconDefinition
    customIconDown?: IconDefinition
    SingleSelectOption?: FC<SingleSelectOptionProps>
    name?: string
}

interface SingleSelectState {
    expanded: boolean
    selected: string | null
    focusedItem: number
    options: SingleSelectOption[]
    search: string
    searching: boolean
}

export class SingleSelect<T = any> extends React.Component<SingleSelectProps<T>, SingleSelectState> {
    popup: HTMLDivElement | null = null
    list: HTMLDivElement | null = null
    icon: HTMLDivElement | null = null
    listItemHeight = 28

    constructor(props: SingleSelectProps<T>) {
        super(props)
        this.state = {
            expanded: false,
            selected: this.props.selected,
            focusedItem: this.getSelectedItemIndex(this.props.options),
            options: this.props.options || [],
            search: '',
            searching: false,
        }
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
    }

    setListRef = (node: HTMLDivElement) => {
        this.list = node
    }

    setIconRef = (node: HTMLDivElement) => {
        this.icon = node
    }

    getSelectedItemIndex = (options = this.state.options) => {
        const selected = this.state ? this.state.selected : this.props.selected
        const selectedItem = options.filter((o) => o.value === selected)[0]
        return options.indexOf(selectedItem)
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
        document.addEventListener('keydown', this.onKeyDown, false)
    }

    componentDidUpdate(prevProps: SingleSelectProps<T>) {
        if (prevProps.selected !== this.props.selected || this.props.selected !== this.state.selected) {
            this.setState({ selected: this.props.selected }, () =>
                this.setState({ focusedItem: this.getSelectedItemIndex() }),
            )
        }
        if (!isEqual(prevProps.options, this.props.options)) {
            this.setState({ options: this.props.options })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
        document.removeEventListener('keydown', this.onKeyDown, false)
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.popup && this.popup.contains && !this.popup.contains(ev.target as Node) && this.state.expanded) {
            this.setState({
                expanded: false,
                search: '',
                options: this.props.options,
            })
        }
    }

    onKeyDown = (ev: KeyboardEvent) => {
        const { expanded, focusedItem } = this.state
        if (expanded) {
            if (ev.key === 'ArrowDown' && focusedItem < this.state.options.length - 1) {
                ev.preventDefault()
                this.setState({ focusedItem: focusedItem + 1 }, () => this.scrollFocusedItem())
            } else if (ev.key === 'ArrowUp' && focusedItem > 0) {
                ev.preventDefault()
                this.setState({ focusedItem: focusedItem - 1 }, () => this.scrollFocusedItem())
            } else if (ev.key === 'Enter') {
                this.onSelect(this.state.options[focusedItem].value)
            }
        }
    }

    scrollFocusedItem = () => {
        if (this.state.expanded && this.list) {
            const fromTop = this.state.focusedItem * this.listItemHeight
            const listHeight = this.list.clientHeight
            if (fromTop - this.list.scrollTop > listHeight - this.listItemHeight) {
                this.list.scrollTop += this.listItemHeight
            } else if (fromTop < this.list.scrollTop) {
                this.list.scrollTop -= this.listItemHeight
            }
        }
    }

    onFocus = (e: React.FocusEvent | React.MouseEvent) => {
        if (this.state.expanded && this.icon && this.icon.contains(e.target as Node)) {
            return
        }
        if (!this.state.expanded && !this.props.disabled) {
            this.setState({ expanded: true }, () => {
                if (this.list) {
                    this.list.scrollTop = this.listItemHeight * this.getSelectedItemIndex() - this.listItemHeight
                }
            })
        }
        this.props.onFocus && this.props.onFocus()
    }

    onBlur = async () => {
        await delay(300)
        if (this.state.expanded && !this.state.searching) {
            this.setState({
                expanded: false,
                focusedItem: this.getSelectedItemIndex(),
                search: '',
                options: this.props.options,
            })
        }
        this.props.onBlur && this.props.onBlur()
    }

    onSelect = async (value: string | null) => {
        this.setState({ expanded: false, selected: value }, () =>
            this.setState({ focusedItem: this.getSelectedItemIndex() }),
        )
        this.props.onSelect(value as string)
        if (this.props.withSearch) {
            await delay(300)
            this.setState({ search: '', options: this.props.options })
        }
    }

    onOptionClick = (e: React.MouseEvent, option: SingleSelectOption) => {
        e.stopPropagation()
        if (option.disabled) {
            return
        }
        this.onSelect(option.value)
    }

    onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: e.target.value })
        const searchText = e.target.value.trim().toLowerCase()
        if (searchText) {
            this.setState({
                options: this.props.options.filter((o) => o.name.toLowerCase().includes(searchText)),
            })
        } else {
            this.setState({ options: this.props.options })
        }
    }

    render() {
        const {
            id,
            className,
            noSelectOption,
            width,
            height,
            maxHeight,
            lineHeight,
            disabled,
            style,
            status,
            whiteBackground,
            widthWhenOpen,
            border,
            withSearch,
            withClear,
            nested,
            SingleSelectOption,
        } = this.props

        const { expanded, selected, focusedItem, options, search } = this.state
        const selectedOption = selected ? options.filter((opt) => opt.value === selected) : []
        const selectedName = selectedOption.length > 0 ? selectedOption[0].name : null

        return (
            <SelectElement
                id={id}
                className={classNames(className, { open: expanded })}
                ref={this.setPopupRef}
                width={width}
                widthWhenOpen={widthWhenOpen}
                style={style}
                onClick={this.onFocus}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                lineHeight={lineHeight}
                whiteBackground={whiteBackground}
                data-value={selected}
            >
                <select hidden defaultValue={selected as string} name={this.props.name} />
                <MinimizedView
                    height={height}
                    disabled={disabled}
                    tabIndex={0}
                    status={status ? status : 'normal'}
                    border={border}
                    className="single-select-label"
                >
                    {withSearch && expanded ? (
                        <>
                            <TextInput
                                placeholder="Search"
                                value={search}
                                onChange={this.onSearchTextChange}
                                onFocus={() => this.setState({ searching: true })}
                                block
                                style={{ marginBottom: '0', border: 'none' }}
                            />
                            <IconContainer>
                                <Icon icon={faSearch} className="visible" />
                            </IconContainer>
                        </>
                    ) : (
                        <>
                            <SelectedOptionDisplay className={classNames({ 'no-select-option': !selected })}>
                                {selectedName ? selectedName : noSelectOption}
                            </SelectedOptionDisplay>
                            <IconContainer className="icon-container">
                                {!!selected && !!withClear && (
                                    <ClearIcon
                                        style={{ right: 28 }}
                                        icon={faTimes as IconProp}
                                        className="visible"
                                        onClick={() => this.onSelect(null)}
                                    />
                                )}
                                <Icon
                                    icon={this.props.customIconDown || faChevronDown}
                                    className={classNames({ visible: !expanded })}
                                />
                                <div onClick={this.onBlur} ref={this.setIconRef}>
                                    <Icon
                                        icon={this.props.customIconUp || faChevronUp}
                                        className={classNames({ visible: expanded })}
                                    />
                                </div>
                            </IconContainer>
                        </>
                    )}
                </MinimizedView>
                <PopupArea className={classNames('options-list-container', { visible: expanded })} border={border}>
                    <SingleSelectOptionsList
                        SingleSelectOption={SingleSelectOption}
                        options={options}
                        selected={this.state.selected}
                        maxHeight={maxHeight}
                        focusedItem={focusedItem}
                        setListRef={this.setListRef}
                        onOptionClick={this.onOptionClick}
                        nested={nested}
                    />
                </PopupArea>
            </SelectElement>
        )
    }
}
