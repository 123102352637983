import { pathUtils } from 'utils/pathnameFormatter'

export const ORDER_PATHS = {
    pagePaths: {
        transactionPath: '/account/:accountSlug/orders/transaction',
        orderDetailsPath: '/account/:accountSlug/orders/details',
        orderPath: '/account/:accountSlug/orders/orders',
        cartPath: '/account/:accountSlug/orders/pending-reservations',
        channelsPath: '/account/:accountSlug/orders/channels',
        paypalDisputesPath: '/account/:accountSlug/orders/paypal/disputes',
        newOrderPath: '/account/:accountSlug/orders/reseller/new_order',
    },
    internalPaths: {
        orderPath: {
            export: 'export',
            redeemMode: 'redeemMode',
            groupRedeemMode: 'groupRedeemMode',
            orderRedeem: 'redeem',
        },
        cartPath: {
            edit: 'edit/:id',
            sentEmails: ':id/sent_emails',
        },
    },
    get fullPaths() {
        return {
            ordersPage: this.pagePaths.orderPath,
            ordersExport: pathUtils.addPath(this.pagePaths.orderPath, this.internalPaths.orderPath.export),
            redeemMode: pathUtils.addPath(this.pagePaths.orderPath, this.internalPaths.orderPath.redeemMode),
            groupRedeemMode: pathUtils.addPath(this.pagePaths.orderPath, this.internalPaths.orderPath.groupRedeemMode),
            orderRedeem: pathUtils.addPath(this.pagePaths.orderPath, this.internalPaths.orderPath.orderRedeem),
            cartPage: this.pagePaths.cartPath,
            cartEditPage: pathUtils.addPath(this.pagePaths.cartPath, this.internalPaths.cartPath.edit),
            cartSentEmailsPage: pathUtils.addPath(this.pagePaths.cartPath, this.internalPaths.cartPath.sentEmails),
        }
    },
}
