import React, { useEffect, useState } from 'react'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { DateFormats, formatOrInvalid } from 'utils'
import { ReplaceMessagesFunc } from 'hocs'
import { IOrder } from 'orders/schema'

interface UpdateDialogProps {
    accountSlug: string
    orderItemUuid: string
    order: IOrder | null
    newDate: Date
    onCancel: () => void
    onConfirm: () => void
    replaceMessages: ReplaceMessagesFunc
}

function UpdateDialog({ newDate, order, onCancel, onConfirm, replaceMessages, orderItemUuid }: UpdateDialogProps) {
    const defaultMessage = `Are you sure you want to update the 'Valid to' date of this
    ticket to the ${formatOrInvalid(newDate, DateFormats.EXTRA_LONG_DATE)}?`
    const [text, setText] = useState<React.ReactNode>(defaultMessage)

    useEffect(() => {
        const orderItems = order?.items?.filter((x: any) => x.orderItemId === orderItemUuid)

        if (!orderItems?.length) {
            closeWithError()
        } else if (orderItems.length < 2) {
            return
        } else {
            const orderItemsBarcodes = orderItems?.map((x: any) => x?.barcode?.barcode)

            setText(
                <>
                    {defaultMessage}
                    <div>
                        <strong>Please note:</strong> the date will also be updated for all {orderItems.length} same
                        type tickets.
                    </div>
                    <div>{orderItemsBarcodes.length > 0 && <>Barcodes: {orderItemsBarcodes.join(', ')}</>}</div>
                </>,
            )
        }
    }, [order, orderItemUuid])

    function closeWithError() {
        replaceMessages('unknown_error', 'error', 'Oops! We could not retrieve ticket details. Please try again later.')
        onCancel()
    }

    return (
        <ConfirmationDialog
            title="Update ticket (Valid to) date?"
            text={text}
            buttonText="Update"
            onCancel={onCancel}
            onConfirm={onConfirm}
        />
    )
}

export default UpdateDialog
