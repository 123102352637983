import { dateRangeFromQuery } from 'dateRanges'
import { useLazyGetListOrdersQuery } from 'orders/reduxQueries'
import React, { useCallback, useContext, useMemo } from 'react'
import { BaseRouteParams, ReplaceMessagesFunc, useNavigation } from 'hocs'
import { useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-typed'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'
import { Sorting } from 'uiComponents/table'
import {
    ExtensibleReactTableComponentProps,
    LoadDataParams,
    ReactTable,
    ReactTableRows,
} from 'uiComponents/table/reactTable'
import OrderDetails from './orderDetails'
import { useOrdersColumns } from './columns'
import { OrderTableEventHandlerContext } from '../orderTableEventHandler'
import { useSelector } from 'react-redux'
import { getSelectedOrdersList, resetState } from '../redux'
import Header from './header'
import { format } from 'date-fns'
import { IOrder } from 'orders/schema'
import { useAppDispatch } from 'store/hooks'
import './ordersTable.scss'
import OrderComment from '../comment/orderComment'
import { useHasFeature } from 'utils/useHasFeature'

const OrdersTableContainer = styled.div`
    position: relative;
    box-shadow: ${CONTAINER_BOX_SHADOW};
`

interface OrdersTableProps {
    hideMessage: (id: string) => void
    replaceMessages: ReplaceMessagesFunc
}

interface QueryFilters {
    channels: string
    payment_method: string
    status: string
    email_status: string
    products: string
    location_id: string
}

const noResultOptions = {
    item: 'orders',
    alignLeft: true,
}

const emptyData = [] as any[]

const OrdersTable: React.FC<OrdersTableProps> = ({ replaceMessages, hideMessage }) => {
    const theme = useTheme()
    const initialSort = useMemo(() => ({ prop: '', direction: 'desc' } as Sorting), [])
    const [fetchOrders, status] = useLazyGetListOrdersQuery()
    const navigation = useNavigation()
    const { hiddenColumns, ...query } = navigation.query()
    const queryString = JSON.stringify(query)
    const { accountSlug } = useParams<BaseRouteParams>()
    const columns = useOrdersColumns()
    const { eventEmmitter } = useContext(OrderTableEventHandlerContext)
    const selectedOrdersUUID = useSelector(getSelectedOrdersList)
    const dispatch = useAppDispatch()
    const formatRangeDate = (date: Date) => format(date, 'yyyy-MM-dd')
    const ref = React.useRef(queryString)
    const showUnpaidOrders = useHasFeature({ featureName: 'showUnpaidOrders' })

    const loadData = useCallback(
        ({ token, pagination: { pageSize }, sort }: LoadDataParams) => {
            dispatch(resetState())
            const _query = JSON.parse(queryString)
            const decodedString = decodeURIComponent(query.filter)
            const keyValuePairs = decodedString.split(';')
            const resultObject = keyValuePairs.reduce((acc, pair) => {
                const [key, value] = pair.split(':')
                acc[key as keyof QueryFilters] = value
                return acc
            }, {} as QueryFilters)

            const dateFilterParams = ((query: Record<string, string>) => {
                const dateRange = dateRangeFromQuery(query, 'today')
                if (query.search) {
                    return {
                        fromCreatedAt: '',
                        toCreatedAt: '',
                        fromEventDate: '',
                        toEventDate: '',
                    }
                }

                return {
                    fromCreatedAt:
                        query.dateRangeType === 'sale' || !query.dateRangeType ? formatRangeDate(dateRange.from) : '',
                    toCreatedAt:
                        query.dateRangeType === 'sale' || !query.dateRangeType ? formatRangeDate(dateRange.to) : '',
                    fromEventDate: query.dateRangeType === 'visit' ? formatRangeDate(dateRange.from) : '',
                    toEventDate: query.dateRangeType === 'visit' ? formatRangeDate(dateRange.to) : '',
                }
            })(_query)

            fetchOrders({
                accountSlug: accountSlug,
                include: 'location,customer,payments,reseller,discounts,detailed_status',
                sortBy: sort.prop?.includes('email') ? 'email' : sort.prop ?? '',
                sortDirection: sort.direction ?? 'desc',
                search: _query.search ?? '',
                searchBy: _query.search ? _query.searchBy ?? '' : '',
                resellers: resultObject['channels'] !== 'direct_sales' ? resultObject['channels'] ?? '' : '',
                directSales: resultObject['channels'] === 'direct_sales',
                paymentMethods: resultObject['payment_method'] ?? '',
                locations: resultObject['location_id'] ?? '',
                fromCreatedAt: dateFilterParams.fromCreatedAt,
                toCreatedAt: dateFilterParams.toCreatedAt,
                fromEventDate: dateFilterParams.fromEventDate,
                toEventDate: dateFilterParams.toEventDate,
                status: resultObject['status'],
                paymentStatus:
                    query.search || showUnpaidOrders ? '' : 'PAID,PENDING,CANCELED,EXPIRED,REFUNDED,CHARGED_BACK',
                emailStatus: resultObject['email_status'] ?? '',
                products: resultObject['products'] ?? '',
                pageSize: pageSize ?? 10,
                offset: ref.current !== queryString ? '0' : token ?? '0',
            })

            ref.current = queryString
        },
        [accountSlug, queryString],
    )

    return (
        <OrdersTableContainer className="orders-table">
            <div className="action-bar">
                <Header replaceMessages={replaceMessages} hideMessage={hideMessage} />
            </div>
            <div>
                <ReactTable
                    sticky
                    pagination={{
                        next: status.data?.next,
                        previous: status.data?.previous,
                    }}
                    expanded={({ row }: ExtensibleReactTableComponentProps<IOrder>) => {
                        return (
                            <div className="orders-table-expanded">
                                <OrderComment orderId={row.original.id} />
                                <OrderDetails row={row} replaceMessages={replaceMessages} hideMessage={hideMessage} />
                            </div>
                        )
                    }}
                    sort={initialSort}
                    rowSelect
                    data={status.data?.results ?? emptyData}
                    loading={status.isFetching}
                    totalCount={status.data?.results?.length || 0}
                    columns={columns}
                    loadData={loadData}
                    elevation={false}
                    event={eventEmmitter}
                    noResultsRow={noResultOptions}
                    rowProps={(row: ReactTableRows<IOrder>) => {
                        return {
                            selected: selectedOrdersUUID.includes(row.original.id),
                            style: {
                                height: 56,
                            },
                        }
                    }}
                    rowContainerProps={(row: ReactTableRows<IOrder>) => ({
                        style: {
                            border: `1px solid ${
                                selectedOrdersUUID.includes(row.original.id)
                                    ? theme.colors.boyBlue
                                    : theme.colors.aluminiumShades[5]
                            }`,
                            width: '100%',
                            backgroundColor: selectedOrdersUUID.includes(row.original.id)
                                ? theme.colors.boyBlueShades[5]
                                : row.isExpanded
                                ? theme.colors.tableRow
                                : theme.colors.white,
                        },
                    })}
                    bodyProps={{
                        style: { minWidth: 'fit-content', width: '100%' },
                    }}
                    bordered
                    backgroundColor={
                        status.data && status.data?.results?.length > 0 ? theme.colors.aluminiumShades[5] : undefined
                    }
                />
            </div>
        </OrdersTableContainer>
    )
}

export default OrdersTable
