import { calculateOrderPartnerRefundFee } from 'orders/utils/calculateOrderRefund'
import { OrderRefundDetails } from '../types'

export const filterNonRefundableOrders = (orders: OrderRefundDetails[]) => {
    return orders.filter((order) => !['partially_canceled', 'cancelled'].includes(order.status))
}

export const getTotalRefundedAmount = (orders: OrderRefundDetails[], partnerRefundFee: boolean) => {
    const total = orders.reduce((sum: number, order) => {
        const partnerFee = partnerRefundFee ? calculateOrderPartnerRefundFee({ order: order }) : 0

        return Number(order.totalDiscountedPriceInclTax) + sum - partnerFee
    }, 0)

    return Math.round(total * 100) / 100
}

export const calculateTotalRefundFee = (orders: OrderRefundDetails[]) => {
    const total = orders.reduce((sum: number, order) => {
        return sum + calculateOrderPartnerRefundFee({ order: order })
    }, 0)

    return Math.round(total * 100) / 100
}
