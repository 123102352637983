import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton, ActionButtonProps } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { ChartDataLoader } from 'uiComponents/loaders'

export const Text = styled.div`
    line-height: 1.75em;
    margin-bottom: 1.5em;
`
const Container = styled.div`
    width: 25em;
    word-break: break-word;
`
const NavigationSection = styled.div`
    font-size: 0.875em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5em;
`

interface DialogProps {
    children?: React.ReactNode
    text?: string | React.ReactNode
    title: string
    buttonText: string
    cancelButtonText?: string
    destructive?: boolean
    loading?: boolean
    className?: string
    onCancel?: () => void
    onConfirm: () => void
}

export const ConfirmationDialog = ({
    buttonText,
    onConfirm,
    title,
    cancelButtonText,
    children,
    className,
    destructive,
    loading,
    onCancel,
    text,
}: DialogProps) => {
    const cancelButtonProps: Partial<ActionButtonProps> = destructive ? { kind: 'default' } : { secondary: true }

    return (
        <ModalDialog onDismiss={onCancel} interactive fromTop="20%" className={className}>
            {loading && <ChartDataLoader />}
            <Container>
                <ModalDialogTitle>{title}</ModalDialogTitle>
                {text && <Text>{text}</Text>}
                {children}
                <NavigationSection>
                    {onCancel && (
                        <ActionButton size="large" {...cancelButtonProps} onClick={onCancel}>
                            {cancelButtonText || 'Cancel'}
                        </ActionButton>
                    )}
                    <ActionButton
                        data-testid="confirmation-dialog-confirm-button"
                        size="large"
                        secondary={!!destructive}
                        kind={destructive ? 'destructive' : 'default'}
                        style={{ marginLeft: '1em' }}
                        onClick={onConfirm}
                    >
                        {buttonText}
                    </ActionButton>
                </NavigationSection>
            </Container>
        </ModalDialog>
    )
}
