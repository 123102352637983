import { AccountService, AccountCreateRequest } from 'admin/accountService'
import { ProductsListService, ProductsListCreateRequest } from 'admin/productsListService'
import { ArticleService, ArticleData } from 'admin/articleService'
import { PricingService } from 'products/pricing/pricingService'
import { DEFAULT_STEP_SIZE } from 'products/pricing/common'
import { ComponentsService, ProductName } from 'engageTools/studio/componentsService'

export interface Pricing {
    originalPrice: number | null
    minAcceptedPrice: number | null
    avgTargetPrice: number | null
    gatePrice: number | null
    stepSize?: string
    charmPricing?: boolean
}

export interface ArticleDefinition {
    articleData: ArticleData
    pricing: Pricing
}

export interface ProductsListDefinition {
    productsList: ProductsListCreateRequest
    articles: ArticleDefinition[]
}

export interface OnboardingTemplate {
    accountSlug: string
    accountData: AccountCreateRequest
    productsLists: ProductsListDefinition[]
}

async function enableProduct(componentsService: ComponentsService, accountSlug: string, productSlug: ProductName) {
    const version = await componentsService.createEmptyDraft(accountSlug, productSlug, 'default')
    await componentsService.publishDraft(accountSlug, 'default', version.id, productSlug)
}

export async function createAccount(
    accountService: AccountService,
    productsListService: ProductsListService,
    articleService: ArticleService,
    pricingService: PricingService,
    componentsService: ComponentsService,
    onboardingTemplate: OnboardingTemplate,
) {
    await accountService.createAccount(onboardingTemplate.accountSlug, onboardingTemplate.accountData)

    for (const productsList of onboardingTemplate.productsLists) {
        const createdList = await productsListService.createProductsList(
            onboardingTemplate.accountSlug,
            productsList.productsList,
        )

        for (const article of productsList.articles) {
            const createdArticle = await articleService.createArticle(onboardingTemplate.accountSlug, {
                ...article.articleData,
                productsListIds: [createdList.id],
            })
            await pricingService.sendPricingSettings({
                pricingType: article.articleData.pricingType,
                id: createdArticle.id,
                account: onboardingTemplate.accountSlug,
                originalPrice: article.pricing.originalPrice,
                minAcceptedPrice: article.pricing.minAcceptedPrice,
                avgTargetPrice: article.pricing.avgTargetPrice,
                gatePrice: article.pricing.gatePrice,
                stepSize: article.pricing.stepSize || DEFAULT_STEP_SIZE,
                charmPricing: article.pricing.charmPricing || false,
                version: null,
            })
        }
    }

    await enableProduct(componentsService, onboardingTemplate.accountSlug, 'trigger_button')
    await enableProduct(componentsService, onboardingTemplate.accountSlug, 'wonderbar')
    await enableProduct(componentsService, onboardingTemplate.accountSlug, 'modal_window')
}
