import React from 'react'
import { DateRange, dateRangeToQuery, dateRangeFromQuery } from 'dateRanges'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { useSelector } from 'react-redux'
import { getListOrderData } from '../redux'
import './searchAndDate.scss'
import { isLocalStorageAvailable } from 'utils/storage'
import SearchBarWithSubtitle from 'uiComponents/search/searchBarWithSubtitle'

interface OrderFiltersProps extends WithNavigationProps<BaseRouteParams> {}

const OrderFilters: React.FC<OrderFiltersProps> = ({ navigation }) => {
    const query = navigation.query()
    const status = useSelector(getListOrderData)

    const onDateRangeChanged = (range: DateRange) => {
        navigation.addQueryWithReplace({ ...dateRangeToQuery(range), page: '1' })
    }

    const onDateRangeTypeChanged = (rangeType: DateRangeType) => {
        navigation.addQueryWithReplace({ dateRangeType: rangeType, page: '1' })
    }

    const onSearchByChange = (searchBy: string) => {
        if (isLocalStorageAvailable()) {
            localStorage.setItem('orders_search_by', searchBy)
        }
    }

    const defaultSearchBy =
        query.searchBy || (isLocalStorageAvailable() ? localStorage.getItem('orders_search_by') : 'email') || 'email'
    const searchByOptions = [
        { value: 'email', name: 'Email' },
        { value: 'id', name: 'Order number/ID' },
        { value: 'barcode', name: 'Barcode' },
        { value: 'discount_code', name: 'Discount code' },
        { value: 'reference_id', name: 'Reservation ID' },
    ]

    const dateRange = dateRangeFromQuery(query, 'today')
    const dateRangeType = query.dateRangeType === 'visit' ? 'visit' : 'sale'

    return (
        <div className="order-filters-container">
            <div className="left-container">
                <SearchBarWithSubtitle
                    key={query.search}
                    searchByOptions={searchByOptions}
                    defaultSearchBy={defaultSearchBy}
                    onSearchByChange={onSearchByChange}
                    showClearButton
                    autoSearchByPatterns={[
                        [/@/, 'email'],
                        [/^\d{8,9}$/, 'id'],
                        [/^\d{14}$/, 'barcode'],
                        [/^\d{5}-\d{5}-\d{5}$/, 'reference_id'],
                    ]}
                    loading={status.isLoading}
                />
            </div>
            <div className="right-container" id="orders-filter">
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    dateRangeTypeDisabled={status.isLoading}
                    dateRangeType={dateRangeType}
                    onDateRangeTypeChange={onDateRangeTypeChanged}
                    allowFutureDateSelection
                />
            </div>
        </div>
    )
}

export default withNavigation(OrderFilters)
