import React, { useState, useRef, useEffect } from 'react'
import { Tax, TaxConfiguration, taxConfigurationFormValidationSchema } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CircleCheckRegular } from '@convious/icons'
import './css/taxConfigurationElement.scss'
import { FormWrapper } from 'uiComponents/form/form'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import ActionButton from 'uiComponents/buttons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FormikState, useField } from 'formik'
import { ActionMenu } from './actionMenu'
import { CancelButton } from 'uiComponents/buttons/cancelButton'
import { SingleSelectFieldFormik, SingleSelectOption } from 'uiComponents/input'
import classNames from 'classnames'
import { useHasFeature } from 'features'
import { DetailedError } from 'utils/errorHandler'

interface EditableFieldProps {
    name: string
    placeholder: string
    numbersOnly?: boolean
    title?: string
    showEditButton?: boolean
    maxLength?: number
    suffix?: string
    editingField?: boolean
}

const EditableField = (props: EditableFieldProps) => {
    const [editingField, setEditingField] = useState(props.editingField)
    const [initialValue, setInitialValue] = useState<string | number | undefined>()
    const { name, placeholder, numbersOnly, title, showEditButton, maxLength, suffix } = props
    const [{ value }, { error }, { setValue, setError }] = useField(name)
    const inputRef = useRef<HTMLInputElement>(null)

    const onEditButtonClick = () => {
        setEditingField(true)
        setInitialValue(value)
        inputRef.current?.focus()
        setTimeout(() => {
            inputRef.current?.select()
        }, 0)
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const numberOnlyAllowedKeys = ['Backspace', 'Tab', 'Enter', 'Delete', 'ArrowLeft', 'ArrowRight', '.']

        if (event.key === 'Escape') {
            event.preventDefault()
            setValue(initialValue)
            setEditingField(false)
        } else if (event.key === 'Enter') {
            event.preventDefault()
            setEditingField(false)
        } else if (
            numbersOnly &&
            !numberOnlyAllowedKeys.includes(event.key) &&
            isNaN(parseInt(event.key, 10)) &&
            !(event.ctrlKey || event.metaKey)
        ) {
            event.preventDefault()
        }
    }

    useEffect(() => {
        if (editingField) {
            inputRef.current?.focus()
            setError(undefined)
        }
    }, [editingField])

    const displayValue = value || value === 0 ? value : placeholder

    return (
        <>
            {editingField ? (
                <TextInputFieldFormik
                    className="input-field editing"
                    name={name}
                    placeholder={placeholder}
                    title={title}
                    maxLength={maxLength}
                    inputRef={inputRef}
                    style={{ width: '100%' }}
                    onBlur={() => {
                        setEditingField(false)
                    }}
                    onKeyDown={onKeyDown}
                    validationMessageProps={{ style: { visibility: 'hidden' } }}
                />
            ) : (
                <div
                    className={classNames('field', { error: error, placeholder: displayValue === placeholder })}
                    title={title}
                >
                    {displayValue}
                </div>
            )}
            {suffix && <div className="input-suffix">{suffix}</div>}
            <div
                className={classNames('edit-button', { invisible: !showEditButton || editingField })}
                onClick={onEditButtonClick}
            >
                <FontAwesomeIcon icon={faPencil as IconProp} />
            </div>
        </>
    )
}

interface EditableDropDownProps extends Omit<EditableFieldProps, 'numbersOnly' | 'maxLength' | 'placeholder'> {
    options: SingleSelectOption[]
}

const EditableDropDown = (props: EditableDropDownProps) => {
    const { name, title, showEditButton, suffix, options } = props
    let [{ value }, { error }] = useField(name)

    return (
        <>
            {showEditButton ? (
                <SingleSelectFieldFormik
                    className="input-field drop-down editing"
                    name={name}
                    options={options}
                    style={{ width: '100%' }}
                    validateOnBlur={false}
                    validateOnChange={false}
                />
            ) : (
                <div className={classNames('field', { error: error })} title={title}>
                    {options.find((option) => option.value === value)?.name}
                </div>
            )}
            {suffix && <div className="input-suffix">{suffix}</div>}
        </>
    )
}

interface TaxRowProps {
    tax: Partial<Tax>
    taxTypeOptions: SingleSelectOption[]
    displayTaxTypes: boolean
    displayProportion: boolean
    displayDeleteButton: boolean
    editingTaxConfiguration?: boolean
    deleteTax: (index: number) => void
    index: number
}

const TaxRow = ({
    taxTypeOptions,
    tax = { proportionPercentage: 100, taxType: taxTypeOptions[0].value } as Tax,
    displayTaxTypes,
    displayProportion,
    displayDeleteButton,
    editingTaxConfiguration,
    index,
    deleteTax,
}: TaxRowProps) => (
    <tr>
        <td className="tax-name" title={tax.name}>
            <EditableField
                name={`taxes[${index}].name`}
                placeholder="Tax name"
                showEditButton={editingTaxConfiguration}
            />
        </td>
        <td className={classNames('tax-type', { hidden: !displayTaxTypes })}>
            <EditableDropDown
                name={`taxes[${index}].taxType`}
                showEditButton={editingTaxConfiguration}
                options={taxTypeOptions}
            />
        </td>
        <td className="tax-rate">
            <EditableField
                name={`taxes[${index}].taxPercentage`}
                placeholder="Tax rate"
                numbersOnly
                showEditButton={editingTaxConfiguration}
                maxLength={6}
                suffix="%"
            />
        </td>
        <td className={classNames('tax-proportion', { hidden: !displayProportion })}>
            <EditableField
                name={`taxes[${index}].proportionPercentage`}
                placeholder="Proportion"
                numbersOnly
                showEditButton={editingTaxConfiguration}
                maxLength={6}
                suffix="%"
            />
        </td>
        {displayDeleteButton && (
            <td className="tax-delete">
                <div
                    className={classNames('delete-button', { hidden: !editingTaxConfiguration })}
                    onClick={() => {
                        deleteTax(index)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash as IconProp} />
                </div>
            </td>
        )}
    </tr>
)

const AddTaxButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <ActionButton className="add-tax-button" {...props}>
        + Add another rate
    </ActionButton>
)

export interface TaxConfigurationElementProps {
    taxTypeOptions: SingleSelectOption[]
    taxConfiguration?: TaxConfiguration
    createTaxConfiguration: (
        taxConfiguration: Omit<TaxConfiguration, 'accountSlug' | 'uuid'>,
    ) => Promise<TaxConfiguration>
    updateTaxConfiguration: (taxConfiguration: Omit<TaxConfiguration, 'accountSlug'>) => Promise<TaxConfiguration>
    deleteTaxConfiguration: (taxConfigurationUuid: string) => Promise<void>
    onError: (error: DetailedError) => void
    setDefaultTaxConfiguration: (taxConfigurationUuid: string) => Promise<void>
    setEditingAnyTaxConfiguration: (editing: boolean) => void
    editing: boolean
    editingAnyTaxConfiguration: boolean
    isDefault: boolean
    onCancel?: () => void
}

export const TaxConfigurationElement = ({
    taxTypeOptions,
    taxConfiguration = {
        taxes: [{ proportionPercentage: 100, taxType: taxTypeOptions[0].value } as Tax],
    } as TaxConfiguration,
    createTaxConfiguration,
    updateTaxConfiguration,
    deleteTaxConfiguration,
    onError,
    setDefaultTaxConfiguration,
    setEditingAnyTaxConfiguration,
    editing,
    editingAnyTaxConfiguration,
    isDefault,
    onCancel,
}: TaxConfigurationElementProps) => {
    const [editingTaxConfiguration, setEditingTaxConfiguration] = useState(editing)
    const [updating, setUpdating] = useState(false)
    const [initialValues, setInitialValues] = useState(taxConfiguration)
    const displayTaxTypes = useHasFeature('TaxConfigurationsDisplayTaxTypes')
    const displayProportion = useHasFeature('TaxConfigurationsDisplayProportions')
    const allowMultipleRates = useHasFeature('TaxConfigurationsAllowMultipleRates')

    const onEditButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        setEditingTaxConfiguration(true)
        setEditingAnyTaxConfiguration(true)
    }

    const onAddTaxButtonClick = (values: TaxConfiguration, setFieldValue: (field: string, value: any) => void) => {
        const newTax = { proportionPercentage: 100, taxType: taxTypeOptions[0].value } as Tax
        const newTaxes = [...values.taxes, newTax]
        setFieldValue('taxes', newTaxes)
    }

    const onDeleteTaxButtonClick = (
        index: number,
        values: TaxConfiguration,
        setFieldValue: (field: string, value: any) => void,
    ) => {
        const newTaxes = values.taxes.filter((_, i) => i !== index)
        setFieldValue('taxes', newTaxes)
    }

    const onCancelButtonClick = (resetForm: (nextState?: Partial<FormikState<TaxConfiguration>>) => void) => {
        resetForm({ values: initialValues })
        setEditingTaxConfiguration(false)
        setEditingAnyTaxConfiguration(false)
        if (onCancel) {
            onCancel()
        }
    }

    const onSuccessfulSave = (updatedConfiguration: TaxConfiguration) => {
        setInitialValues(updatedConfiguration)
        setUpdating(false)
        setEditingTaxConfiguration(false)
        setEditingAnyTaxConfiguration(false)
    }

    const onSubmit = async (values: TaxConfiguration) => {
        setUpdating(true)
        if (taxConfiguration.uuid) {
            await updateTaxConfiguration(values).then(onSuccessfulSave).catch(onError)
        } else {
            await createTaxConfiguration(values).then(onSuccessfulSave).catch(onError)
        }
    }

    return (
        <div
            className={classNames('tax-configuration-container', {
                default: isDefault,
                editing: editingTaxConfiguration,
            })}
        >
            <FormWrapper
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={taxConfigurationFormValidationSchema}
            >
                {({ values, resetForm, setFieldValue }) => (
                    <>
                        <div className="name" title={values.name}>
                            <EditableField
                                name="name"
                                placeholder="Tax configuration name"
                                showEditButton={editingTaxConfiguration}
                                editingField={!values.uuid}
                            />
                        </div>
                        {isDefault && (
                            <div className="default-tax-configuration-badge">
                                <span>Default</span>
                                <CircleCheckRegular />
                            </div>
                        )}
                        <ActionMenu
                            taxConfiguration={values}
                            editingTaxConfiguration={editingTaxConfiguration}
                            isDefault={isDefault}
                            setDefaultTaxConfiguration={setDefaultTaxConfiguration}
                            deleteTaxConfiguration={deleteTaxConfiguration}
                            cancel={() => {
                                if (onCancel) {
                                    onCancel()
                                }
                            }}
                        />
                        <table>
                            <tbody>
                                <tr>
                                    <th className="tax-name">Name</th>
                                    <th className={classNames('tax-type', { hidden: !displayTaxTypes })}>Tax type</th>
                                    <th className="tax-rate">Tax rate</th>
                                    <th className={classNames('tax-proportion', { hidden: !displayProportion })}>
                                        Proportion
                                    </th>
                                    {values.taxes.length > 1 && <th className="tax-delete" />}
                                </tr>
                                {values.taxes.map((tax: Partial<Tax>, index: number) => (
                                    <TaxRow
                                        tax={tax}
                                        taxTypeOptions={taxTypeOptions}
                                        displayTaxTypes={displayTaxTypes}
                                        displayProportion={displayProportion}
                                        editingTaxConfiguration={editingTaxConfiguration}
                                        index={index}
                                        key={tax.uuid ?? `new-${index}`}
                                        displayDeleteButton={values.taxes.length > 1}
                                        deleteTax={(index: number) => {
                                            onDeleteTaxButtonClick(index, values, setFieldValue)
                                        }}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {allowMultipleRates && editingTaxConfiguration && (
                            <AddTaxButton
                                onClick={() => {
                                    onAddTaxButtonClick(values, setFieldValue)
                                }}
                            />
                        )}
                        <div className="buttons">
                            {editingTaxConfiguration ? (
                                <>
                                    <CancelButton
                                        onClick={() => {
                                            onCancelButtonClick(resetForm)
                                        }}
                                        disabled={updating}
                                        title="Cancel"
                                    >
                                        Cancel
                                    </CancelButton>
                                    <ActionButton
                                        className="save"
                                        type="submit"
                                        disabled={updating}
                                        title={updating ? 'Saving...' : 'Save'}
                                    >
                                        {updating ? <FontAwesomeIcon icon={faSpinner} className="fa-pulse" /> : 'Save'}
                                    </ActionButton>
                                </>
                            ) : (
                                <ActionButton
                                    onClick={onEditButtonClick}
                                    disabled={editingAnyTaxConfiguration}
                                    title={
                                        editingAnyTaxConfiguration ? 'Save or discard your other changes first' : 'Edit'
                                    }
                                >
                                    Edit
                                </ActionButton>
                            )}
                        </div>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}

export default TaxConfigurationElement
