import * as React from 'react'
import { Button } from '@mui/material'
import { NavigationSection } from 'uiComponents/popups/modal'
import { CancelRefundFooterProps } from '../types'
import './styles.scss'

const CancelRefundFooter = (props: CancelRefundFooterProps) => {
    const {
        confirmMessage,
        confirmMessageImportant,
        cancelButtonCaption,
        onCancel,
        onNext,
        nextButtonCaption,
        nextButtonDisabled,
    } = props
    return (
        <div className="cancel-refund-footer">
            {confirmMessage && (
                <div className="confirmation-message">
                    <p className="body1 noMargin">
                        {confirmMessage}&nbsp;
                        <strong>{confirmMessageImportant}</strong>
                    </p>
                </div>
            )}
            <NavigationSection>
                <Button variant="outlined" size="large" onClick={onCancel} color="secondary">
                    {cancelButtonCaption}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    id="cancel-refund-next-button"
                    onClick={onNext}
                    color="error"
                    disabled={nextButtonDisabled}
                >
                    {nextButtonCaption}
                </Button>
            </NavigationSection>
        </div>
    )
}

export default CancelRefundFooter
