import React, { useState } from 'react'
import { PriceSetting, AdjustmentType, OverrideType, PricingSettingToEnforce } from 'products/pricing/pricingService'
import { Currency } from 'uiComponents/money/moneyHoc'
import { Row, Column, ContainerWithStatus } from 'uiComponents/pageElements'
import { Col } from 'uiComponents/flex'
import { SecondaryText } from 'uiComponents/typography'
import { SingleSelect } from 'uiComponents/input/singleSelect'
import { Radio } from 'uiComponents/input/radio'
import Infotip from 'uiComponents/infotip'
import { ValidationNotice } from 'products/components/validationNotice'
import { Adjustment, RuleFormContainer, RadioWrapper, HighlightedText, RuleHeader } from './shared'
import { RulesWithToggle } from './rulesWithToggle'

interface RulesForRtpProps {
    currency: Currency
    adjustments: Adjustment[]
    activatePriceSettingRule: (priceType: PriceSetting, activate: boolean) => void
    setAdjustment: (priceType: PriceSetting, value: number, type: AdjustmentType) => void
    setEnforceRule: (priceSetting: PricingSettingToEnforce | null) => void
    priceSettingToEnforce: PricingSettingToEnforce | null
    validate: boolean
    onOverrideTypeChange: (type: OverrideType) => void
}

type Strategy = 'regular' | 'enforce'

export function RulesForRtp(props: RulesForRtpProps) {
    const [strategy, setStrategy] = useState<Strategy>(props.priceSettingToEnforce ? 'enforce' : 'regular')

    function handlePriceSettingRuleToggle(priceSetting: PriceSetting, on: boolean) {
        props.activatePriceSettingRule(priceSetting, on)
    }

    function changeStrategy(e: React.ChangeEvent<HTMLInputElement>) {
        const s = e.target.value as Strategy
        setStrategy(s)
        if (s === 'regular') {
            props.setEnforceRule(null)
        }
        props.onOverrideTypeChange(s === 'regular' ? 'adjust' : 'enforce')
    }

    function handleEnforceRuleChange(priceSetting: PricingSettingToEnforce | null) {
        props.setEnforceRule(priceSetting)
    }

    const { currency, adjustments, validate, priceSettingToEnforce } = props
    const rulesNotValid = !adjustments.find((a) => a.on) && !priceSettingToEnforce && validate
    return (
        <>
            <ValidationNotice className={rulesNotValid ? 'validation-message-visible' : ''}>
                Select a rule
            </ValidationNotice>
            <ContainerWithStatus status={rulesNotValid ? 'error' : 'success'} style={{ flexDirection: 'column' }}>
                <Row style={{ marginBottom: '2em' }}>
                    <RadioWrapper style={{ marginLeft: '.5em' }}>
                        <Radio
                            id="regularStrategy"
                            name="strategy"
                            value="regular"
                            onChange={changeStrategy}
                            defaultChecked={strategy === 'regular'}
                        />
                        <HighlightedText>Regular price strategy</HighlightedText>
                        <Infotip pointer="left" maxWidth="25em">
                            {/* tslint:disable-next-line:max-line-length */}
                            Change one or more price attributes at the same time, lowering you minimum accepted price,
                            setting specific prices, or applying percentage-based increases/decreases.
                        </Infotip>
                    </RadioWrapper>
                    <SecondaryText style={{ margin: '0 2em' }}>or</SecondaryText>
                    <RadioWrapper>
                        <Radio
                            id="enforceStrategy"
                            name="strategy"
                            value="enforce"
                            onChange={changeStrategy}
                            defaultChecked={strategy === 'enforce'}
                        />
                        <HighlightedText>Enforce price strategy</HighlightedText>
                        <Infotip pointer="left" maxWidth="25em">
                            {/* tslint:disable-next-line:max-line-length */}
                            Your price will become static for the date range, weekdays, and time slots that you define.
                            Enforce all prices to be sold at &apos;Maximum price, for example.
                        </Infotip>
                    </RadioWrapper>
                </Row>
                {strategy === 'regular' && (
                    <RulesWithToggle
                        adjustments={adjustments}
                        currency={currency}
                        handleRuleChange={props.setAdjustment}
                        validate={validate}
                        handlePriceSettingRuleToggle={handlePriceSettingRuleToggle}
                    />
                )}
                {strategy === 'enforce' && (
                    <div>
                        <Row>
                            <Column>
                                <RuleHeader>
                                    <div>Rule</div>
                                </RuleHeader>
                            </Column>
                        </Row>
                        <Row>
                            <EnforceRuleItem
                                priceSetting={props.priceSettingToEnforce}
                                handleRuleChange={handleEnforceRuleChange}
                                validate={validate}
                            />
                        </Row>
                    </div>
                )}
            </ContainerWithStatus>
        </>
    )
}

interface EnforceRuleProps {
    priceSetting: PricingSettingToEnforce | null
    handleRuleChange: (priceSetting: PricingSettingToEnforce) => void
    validate: boolean
}

function EnforceRuleItem(props: EnforceRuleProps) {
    const options = [
        { value: 'minimum', name: 'Min price' },
        { value: 'average', name: 'Regular price' },
        { value: 'maximum', name: 'Max price' },
    ]
    return (
        <RuleFormContainer style={{ height: '5em', display: 'flex', alignItems: 'center' }}>
            <Col span={9}>
                <Row style={{ alignItems: 'center' }}>
                    <Col span={3}>Enforce price to equal</Col>
                    <Col span={4}>
                        <SingleSelect
                            id="enforceFrom"
                            noSelectOption="Select a price setting"
                            options={options}
                            selected={props.priceSetting}
                            width="13em"
                            height="2.8em"
                            onSelect={props.handleRuleChange}
                            status="highlight"
                        />
                    </Col>
                    <Col span={5}>for each selected product (make price static)</Col>
                </Row>
            </Col>
            <Col span={3} />
        </RuleFormContainer>
    )
}
