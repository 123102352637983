import React, { useState, useEffect } from 'react'
import TaxConfigurationElement from './taxConfigurationElement'
import { AddTaxConfigurationElement } from './addTaxConfigurationElement'
import { TaxConfiguration } from './types'
import { AppServices } from 'middleware'
import { State } from 'store'
import { connect, useDispatch } from 'react-redux'
import { PageLoader } from 'uiComponents/loaders'
import './css/taxConfigurations.scss'
import { TaxConfigurationsError, TaxType } from './taxConfigurationsService'
import { capitalize } from 'lodash'
import { replaceMessage } from 'uiComponents/messages/actions'
import { DetailedError } from 'utils/errorHandler'

type TaxConfigurationsProps = {
    accountSlug: string
}

const parseTaxConfigurationsError = (error: TaxConfigurationsError): string => {
    let errorString = ''
    if (error.errors.taxes) {
        error.errors.taxes.forEach((taxError) => {
            Object.keys(taxError).forEach((key) => {
                errorString += `${key}: ${taxError[key].join(', ')}`
            })
        })
    }
    return errorString
}

export const formatTaxType = (taxType: string) => (taxType === 'VAT' ? taxType : capitalize(taxType))

const TaxConfigurationsSection = ({ accountSlug }: TaxConfigurationsProps) => {
    const [taxConfigurations, setTaxConfigurations] = useState<TaxConfiguration[] | null>(null)
    const [taxTypes, setTaxTypes] = useState<TaxType[] | null>(null)
    const [loadingTaxConfigurations, setLoadingTaxConfigurations] = useState(false)
    const [loadingTaxTypes, setLoadingTaxTypes] = useState(false)
    const [defaultTaxConfigurationUuid, setDefaultTaxConfigurationUuid] = useState<string | null>(null)
    const [addTaxConfigurationButtonClicked, setAddTaxConfigurationButtonClicked] = useState(false)
    const [editingAnyTaxConfiguration, setEditingAnyTaxConfiguration] = useState(false)
    const dispatch = useDispatch()

    const getTaxConfigurations = async () => {
        setLoadingTaxConfigurations(true)
        const taxConfigurationsData = await AppServices.taxConfigurationsService.getTaxConfigurations(accountSlug)
        setTaxConfigurations(taxConfigurationsData?.taxConfigurations || null)
        setDefaultTaxConfigurationUuid(taxConfigurationsData?.defaultTaxConfigurationUuid || null)
        setEditingAnyTaxConfiguration(false)
        setAddTaxConfigurationButtonClicked(false)
        setLoadingTaxConfigurations(false)
    }

    const getTaxTypes = async () => {
        setLoadingTaxTypes(true)
        const taxTypesData = await AppServices.taxConfigurationsService.getTaxTypes(accountSlug)
        setTaxTypes(taxTypesData?.taxTypes || null)
        setLoadingTaxTypes(false)
    }

    useEffect(() => {
        getTaxConfigurations()
        getTaxTypes()
    }, [accountSlug])

    const createTaxConfiguration = async (taxConfiguration: TaxConfiguration) => {
        const newTaxConfiguration = await AppServices.taxConfigurationsService.createTaxConfiguration(
            accountSlug,
            taxConfiguration,
        )
        setTaxConfigurations(taxConfigurations ? [...taxConfigurations, newTaxConfiguration] : [newTaxConfiguration])
        setAddTaxConfigurationButtonClicked(false)
        return newTaxConfiguration
    }

    const updateTaxConfiguration = async (taxConfiguration: TaxConfiguration) =>
        await AppServices.taxConfigurationsService.updateTaxConfiguration(accountSlug, taxConfiguration)

    const deleteTaxConfiguration = async (taxConfigurationUuid: string) => {
        await AppServices.taxConfigurationsService.deleteTaxConfiguration(accountSlug, taxConfigurationUuid)
        getTaxConfigurations()
    }

    const setDefaultTaxConfiguration = async (taxConfigurationUuid: string) => {
        await AppServices.taxConfigurationsService.setDefaultTaxConfiguration(accountSlug, taxConfigurationUuid)
        getTaxConfigurations()
    }

    const onError = (error: DetailedError) => {
        dispatch(
            replaceMessage(
                'server_error',
                'error',
                `${error.message}. ${parseTaxConfigurationsError(error.detail as TaxConfigurationsError)}`,
                15000,
            ),
        )
        getTaxConfigurations()
    }

    const taxTypeOptions = (taxTypes as TaxType[])?.map((taxType) => ({
        name: formatTaxType(taxType),
        value: taxType,
    }))

    return (
        <div className="tax-configurations-container">
            {loadingTaxConfigurations || loadingTaxTypes ? (
                <PageLoader style={{ width: '100%' }} />
            ) : (
                <>
                    {taxConfigurations?.map((taxConfiguration: TaxConfiguration) => {
                        const isDefault = taxConfiguration.uuid === defaultTaxConfigurationUuid
                        return (
                            <TaxConfigurationElement
                                taxConfiguration={taxConfiguration}
                                taxTypeOptions={taxTypeOptions}
                                createTaxConfiguration={createTaxConfiguration}
                                updateTaxConfiguration={updateTaxConfiguration}
                                deleteTaxConfiguration={deleteTaxConfiguration}
                                onError={onError}
                                setDefaultTaxConfiguration={setDefaultTaxConfiguration}
                                setEditingAnyTaxConfiguration={setEditingAnyTaxConfiguration}
                                editing={false}
                                editingAnyTaxConfiguration={editingAnyTaxConfiguration}
                                isDefault={isDefault}
                                key={taxConfiguration.uuid}
                            />
                        )
                    })}
                    {addTaxConfigurationButtonClicked ? (
                        <TaxConfigurationElement
                            taxTypeOptions={taxTypeOptions}
                            createTaxConfiguration={createTaxConfiguration}
                            updateTaxConfiguration={updateTaxConfiguration}
                            deleteTaxConfiguration={deleteTaxConfiguration}
                            onError={onError}
                            setDefaultTaxConfiguration={setDefaultTaxConfiguration}
                            setEditingAnyTaxConfiguration={setEditingAnyTaxConfiguration}
                            editing={true}
                            editingAnyTaxConfiguration={editingAnyTaxConfiguration}
                            isDefault={false}
                            onCancel={() => setAddTaxConfigurationButtonClicked(false)}
                        />
                    ) : (
                        <AddTaxConfigurationElement
                            fullWidth={!taxConfigurations || taxConfigurations.length % 2 === 0}
                            onClick={() => setAddTaxConfigurationButtonClicked(true)}
                        />
                    )}
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state: State) => ({
    accountSlug: state.preferences.activeAccount as string,
})

export default connect(mapStateToProps, null)(TaxConfigurationsSection)
