import React from 'react'
import styled from 'styled-typed'
import { DateRangeName, DateRangePeriod, DateRange } from 'dateRanges'
import { format } from 'date-fns'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { BigCheckbox, TimePicker } from 'uiComponents/input'
import { SingleSelect } from 'uiComponents/input/singleSelect'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { WeekdayPattern } from 'uiComponents/weekdayPattern'
import { Row, Column, ColumnGap, ContainerWithStatus } from 'uiComponents/pageElements'
import { SecondaryText } from 'uiComponents/typography'
import { ValidationNotice } from 'products/components/validationNotice'
import { parseDate } from 'utils'

export type Recurrence = 'WEEKLY' | 'BIWEEKLY' | 'FIRST_WEEK_OF_THE_MONTH' | 'LAST_WEEK_OF_THE_MONTH'

interface ExceptionDayTimeFormItemProps {
    showPatterns: boolean
    handleCalendarInputChange: (from: string | null, to: string | null) => void
    handleWeekdayToggleChange: (days: string[]) => void
    handleRecurrenceSelectChange: (recurrence: Recurrence) => void
    handleDayTimeInputChange: (startTime: string | null, endTime: string | null) => void
    recurrence: string | null
    weekdays: string | null
    startTime: string | null
    endTime: string | null
    startDate: string | null
    endDate: string | null
    validate: boolean
}

interface ExceptionDayTimeFormItemState {
    openAllDay: boolean
    startTime: string | null
    endTime: string | null
    startDate: string | null
    endDate: string | null
    endDateBeyond: boolean
    endTimeValid: boolean
}

const DayTimeWeekdayPattern = styled(WeekdayPattern)`
    width: 100%;
`

export const recurrenceOptions = [
    { value: 'WEEKLY', name: 'Weekly' },
    { value: 'BIWEEKLY', name: 'Every two weeks' },
    { value: 'FIRST_WEEK_OF_THE_MONTH', name: 'First week of the month' },
    { value: 'LAST_WEEK_OF_THE_MONTH', name: 'Last week of the month' },
]

export class ExceptionDayTimeFormItem extends React.Component<
    ExceptionDayTimeFormItemProps,
    ExceptionDayTimeFormItemState
> {
    constructor(props: ExceptionDayTimeFormItemProps) {
        super(props)
        this.state = {
            openAllDay: !this.props.startTime || !this.props.endTime,
            startTime: this.props.startTime,
            endTime: this.props.endTime,
            endTimeValid: true,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            endDateBeyond: false,
        }
    }

    compontentDidUpdate(prevProps: ExceptionDayTimeFormItemProps) {
        if (prevProps.validate !== this.props.validate && this.props.validate) {
            if (this.state.endDate) {
                this.checkEndDateValidity(this.state.endDate)
            }
        }
    }

    formatDateRange = (startDate: string | null, endDate: string | null): DateRange | null => {
        if (startDate && endDate) {
            const range: DateRange = {
                name: 'selectedRange' as DateRangeName,
                period: 'day' as DateRangePeriod,
                from: new Date(startDate),
                to: new Date(endDate),
            }
            return range
        }
        return null
    }

    checkEndDateValidity = (endDate: string) => {
        if (this.state.startDate && new Date(endDate) < new Date(this.state.startDate)) {
            this.setState({ endDateBeyond: true })
        } else {
            this.setState({ endDateBeyond: false })
        }
    }

    onDateChanged = (date: Date, type: 'startDate' | 'endDate') => {
        const formattedDate = date ? format(date, 'yyyy-MM-dd') : null

        if (type === 'startDate') {
            this.setState({ startDate: formattedDate }, () => {
                if (this.state.endDate) {
                    this.checkEndDateValidity(this.state.endDate)
                }
            })
            this.props.handleCalendarInputChange(
                formattedDate,
                this.state.endDate ? format(this.state.endDate, 'yyyy-MM-dd') : null,
            )
        } else {
            this.setState({ endDate: formattedDate })
            if (date) {
                this.checkEndDateValidity(date.toString())
            }
            this.props.handleCalendarInputChange(
                this.state.startDate ? format(this.state.startDate, 'yyyy-MM-dd') : null,
                formattedDate,
            )
        }
    }

    isEndTimeValid = (start: string | null, end: string | null) => {
        if (!start || !end) {
            return true
        }
        return end >= start
    }

    onStartTimeChange = (time: string) => {
        this.props.handleDayTimeInputChange(this.state.endTime ? time : null, this.state.endTime)
        this.setState({
            openAllDay: false,
            startTime: time,
            endTimeValid: this.isEndTimeValid(time, this.state.endTime),
        })
    }

    onEndTimeChange = (time: string) => {
        this.props.handleDayTimeInputChange(this.state.startTime, this.state.startTime ? time : null)
        this.setState({
            openAllDay: false,
            endTime: time,
            endTimeValid: this.isEndTimeValid(this.state.startTime, time),
        })
    }

    handleAllDayInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked
        const start = value ? null : '00:00'
        const end = value ? null : '23:59'
        this.setState({
            startTime: start,
            endTime: end,
            endTimeValid: true,
        })
        this.setState({ openAllDay: value })
        this.props.handleDayTimeInputChange(start, end)
    }

    getCurrentStartTimeValue = () => {
        return this.state.startTime || (this.state.openAllDay ? '00:00' : '')
    }

    getCurrentEndTimeValue = () => {
        return this.state.endTime || (this.state.openAllDay ? '23:59' : '')
    }

    areDateAndTimeSettingsValid = (): boolean => {
        if (this.props.showPatterns) {
            if (
                !(this.state.startDate && this.state.endDate) &&
                !(this.state.startTime && this.state.endTime) &&
                !this.props.weekdays
            ) {
                return false
            }
            if (
                this.state.startDate &&
                this.state.endDate &&
                new Date(this.state.endDate) < new Date(this.state.startDate)
            ) {
                return false
            }
            return true
        } else {
            if (!(this.state.startDate && this.state.endDate) && !(this.state.startTime && this.state.endTime)) {
                return false
            }
            if (
                this.state.startDate &&
                this.state.endDate &&
                new Date(this.state.endDate) < new Date(this.state.startDate)
            ) {
                return false
            }
            return true
        }
    }

    render() {
        const { weekdays, validate } = this.props
        const endDateNotValid =
            ((!!this.state.startDate && !this.state.endDate) || this.state.endDateBeyond) && validate
        const timeSettingsNotValid = !this.areDateAndTimeSettingsValid() && validate
        const endTimeValid = this.state.openAllDay
            ? true
            : this.isEndTimeValid(this.getCurrentStartTimeValue(), this.getCurrentEndTimeValue())
        return (
            <>
                <ContainerWithStatus status={timeSettingsNotValid ? 'error' : 'success'}>
                    <Column>
                        <Row>
                            <Column>
                                <FormItem htmlFor="startDate" style={{ flex: '1' }}>
                                    <FormItemName style={{ display: 'flex' }}>Exception start date</FormItemName>
                                    <DatePicker
                                        id="exceptionStartDate"
                                        date={this.state.startDate ? parseDate(this.state.startDate) : null}
                                        onChange={(value: Date) => this.onDateChanged(value, 'startDate')}
                                        allowNullDate={true}
                                    />
                                </FormItem>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <FormItem htmlFor="endDate" style={{ flex: '1' }}>
                                    <FormItemName style={{ display: 'flex' }}>Exception end date</FormItemName>
                                    <DatePicker
                                        id="exceptionEndDate"
                                        status={endDateNotValid ? 'error' : 'normal'}
                                        date={this.state.endDate ? parseDate(this.state.endDate) : null}
                                        onChange={(value: Date) => this.onDateChanged(value, 'endDate')}
                                        lowerBoundary={this.state.startDate ? parseDate(this.state.startDate) : null}
                                        allowNullDate={true}
                                    />
                                </FormItem>
                                <ValidationNotice className={endDateNotValid ? 'validation-message-visible' : ''}>
                                    {!this.state.endDate && 'Select an end date'}
                                    {this.state.endDateBeyond && 'The end date should be later than the start date'}
                                </ValidationNotice>
                            </Column>
                        </Row>
                    </Column>
                    {this.props.showPatterns ? (
                        <>
                            <ColumnGap>
                                <Row>
                                    <SecondaryText style={{ marginTop: '3em', padding: '0 .5em' }}>
                                        and/or
                                    </SecondaryText>
                                </Row>
                            </ColumnGap>
                            <Column>
                                <Row>
                                    <FormItem htmlFor="recurrence" style={{ flex: '1' }}>
                                        <FormItemName style={{ display: 'flex' }}>Define daily pattern</FormItemName>
                                        <DayTimeWeekdayPattern
                                            id="exceptionPattern"
                                            weekdays={weekdays ? weekdays : '0,1,2,3,4,5,6'}
                                            large
                                            handleWeekdayToggleChange={this.props.handleWeekdayToggleChange}
                                        />
                                    </FormItem>
                                </Row>
                                <Row style={{ marginBottom: '1em' }}>
                                    <Column>
                                        <FormItem htmlFor="recurrence">
                                            <FormItemName style={{ display: 'flex' }}>Recurrence</FormItemName>
                                            <SingleSelect
                                                id="exceptionRecurrence"
                                                noSelectOption="Select recurrence"
                                                options={recurrenceOptions}
                                                selected={this.props.recurrence}
                                                height="2.8em"
                                                onSelect={this.props.handleRecurrenceSelectChange}
                                            />
                                        </FormItem>
                                    </Column>
                                </Row>
                                <Row style={{ marginBottom: '1em' }}>
                                    <Column>
                                        <FormItem htmlFor="startTime" style={{ margin: '0 1em 0 0' }}>
                                            <FormItemName style={{ display: 'flex' }}>Start Time</FormItemName>
                                            <TimePicker
                                                id="startTime"
                                                name="startTime"
                                                value={this.getCurrentStartTimeValue()}
                                                onChange={this.onStartTimeChange}
                                                errorMessage={
                                                    !endTimeValid ? 'Start time can not be after end time' : null
                                                }
                                            />
                                        </FormItem>
                                    </Column>
                                    <Column>
                                        <FormItem htmlFor="endTime" style={{ margin: '0 1em 0 0' }}>
                                            <FormItemName style={{ display: 'flex' }}>End Time</FormItemName>
                                            <TimePicker
                                                id="endTime"
                                                name="endTime"
                                                value={this.getCurrentEndTimeValue()}
                                                onChange={this.onEndTimeChange}
                                                errorMessage={
                                                    !endTimeValid ? 'End time can not be before start time' : null
                                                }
                                            />
                                        </FormItem>
                                    </Column>
                                    <Column style={{ maxWidth: '4em' }}>
                                        <FormItem htmlFor="endTime" style={{ margin: '0 1em 0 0' }}>
                                            <FormItemName style={{ display: 'flex' }}>All day</FormItemName>
                                            <BigCheckbox
                                                id="openAllDay"
                                                name="openAllDay"
                                                checked={this.state.openAllDay}
                                                onChange={this.handleAllDayInputChange}
                                            />
                                        </FormItem>
                                    </Column>
                                </Row>
                            </Column>
                        </>
                    ) : (
                        <Column />
                    )}
                </ContainerWithStatus>
                <ValidationNotice className={timeSettingsNotValid ? 'validation-message-visible' : ''}>
                    {this.props.showPatterns
                        ? 'Either select the dates, set the start and end times or define the daily pattern'
                        : 'Please select the dates'}
                </ValidationNotice>
            </>
        )
    }
}
