import * as React from 'react'
import styled from 'styled-typed'
import { RouteComponentProps } from 'react-router-dom'
import { withRouter, Route } from 'react-router-dom'
import { set } from 'lodash'

import { delay, setPropertyPath } from 'utils'
import { PageLoader } from 'uiComponents/loaders'
import { EventType } from 'http/loggingService'
import { MessageKind } from 'uiComponents/messages'
import StudioConviousLogo from 'uiComponents/studio/studioLogo'
import { ImagesService } from 'http/imagesService'
import { parseSearch } from 'navigation'
import NavigationPrompt from 'react-router-navigation-prompt'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import SidebarMenuItem, { SectionObject } from 'uiComponents/studio/menuItem'
import { MenuItem } from 'engageTools/studio/'
import { Footer } from 'uiComponents/studio/form/footer'
import {
    WonderbarConfiguration,
    ModalWindowConfiguration,
    Languages,
    TriggerButtonConfiguration,
    UrlRules,
    Translation,
    ProductName,
    TargetType,
    CheckoutConfiguration,
    StudioStep,
    EngageToolConfig,
    ProductConfig,
    Configuration,
} from 'engageTools/studio/schema'
import {
    FormContainer,
    FormContent,
    SidebarMenu,
    Form,
    FormBody,
    ScrollArea,
    Menu,
    SidebarTitle,
} from 'uiComponents/studio/form/formComponents'
import { ComponentsService, ConfigurationVersions } from 'engageTools/studio/componentsService'
import { WonderBarFormDefinition } from '../wonderbar/configurationOptions'
import { ModalWindowFormDefinition } from '../modalwindow/configurationOptions'
import { TriggerButtonFormDefinition } from '../triggerbutton/configurationOptions'
import { CheckoutFormDefinition } from '../checkout/configurationOptions'
import { WondebarForm } from 'engageTools/studio/wonderbar/form'
import { CheckoutForm } from 'engageTools/studio/checkout/form'
import { ModalWindowForm } from 'engageTools/studio/modalwindow/form'
import { TriggerButtonForm } from 'engageTools/studio/triggerbutton/form'
import { SuccessDialog } from './successDialog'
import {
    productURLSlugs,
    getProductSlug,
    getLanguageWithMissingTranslations,
    productTitles,
    updateTranslations,
    increasedUrlRulesBlacklist,
    decreasedUrlRulesBlacklist,
    buildConfiguration,
    increasedUrlRulesWhitelist,
    decreasedUrlRulesWhitelist,
} from 'engageTools/studio/shared'
import { onNavigateAway } from 'uiComponents/studio/onNavigateAway'
import { COPY_KEYS } from 'engageTools/studio/modalwindow/copySection'
import { StudioFormContext } from '../context'
import { HandleStudioSubmit } from '../audienceConfirmation'

const FormHeader = styled.div`
    height: 6em;
    display: table;
    text-align: center;
    & > span {
        font-size: 1.3em;
        padding: 0 0.4em;
        line-height: 1.5em;
        display: table-cell;
        vertical-align: middle;
    }
`

const FormDescription = styled.div`
    height: 5em;
    padding: 0.7em;
    color: ${(props) => props.theme.colors.textDark};
    & span {
        font-size: 0.75em;
    }
`

const NameWrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    width: 8rem;
    max-height: 3rem;
    line-height: 1rem;
    font-size: 0.8rem;
    margin: auto;
`

interface StudioFormProps {
    accountSlug: string
    currentStep: StudioStep
    menuItems: MenuItem[]
    product: ProductName
    setActiveLanguage: (v: string) => void
    activeLanguage: string
    componentsService: ComponentsService
    imagesService: ImagesService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    loading: boolean
    translationsLoading: boolean
    translations: Translation[]
    targetDevice: TargetType
    configurationId: string
    onComponentValuesUpdate: (v: EngageToolConfig) => void
    onProductConfigUpdate: (v: ProductConfig) => void
    onLanguagesUpdate: (v: Languages) => void
    onTranslationsUpdate: (v: Translation[]) => void
    onUrlRulesUpdate: (v: UrlRules | null) => void
    setDraftId: (id: string) => void
    publish: boolean
    saveDraft: boolean
    logEvent: (eventType: EventType, additionalData?: { [key: string]: string }) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
    setCurrentProductConfiguration: (v: ConfigurationVersions) => void
    handleSubmit: HandleStudioSubmit
}

interface StudioFormState {
    loading: boolean
    ongoingPublish: boolean
    componentValues: EngageToolConfig | null
    productConfig: ProductConfig | null
    translations: Translation[]
    languages: Languages | null
    urlRules: UrlRules | null
    translationsValidation: boolean
    checkoutFontsValidation: boolean
    draftId: string
    unsavedChanges: boolean
    configurationName: string
    saveDraft: boolean
}

class StudioForm extends React.Component<StudioFormProps & RouteComponentProps<any>, StudioFormState> {
    private timeoutHandler: any = null
    productSlug = getProductSlug(this.props.product)
    constructor(props: StudioFormProps & RouteComponentProps<any>) {
        super(props)
        this.state = {
            loading: true,
            ongoingPublish: false,
            componentValues: null,
            productConfig: null,
            translations: [],
            languages: null,
            urlRules: null,
            translationsValidation: false,
            checkoutFontsValidation: false,
            draftId: '',
            unsavedChanges: false,
            configurationName: '',
            saveDraft: false,
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.saveDraft = this.saveDraft.bind(this)
    }

    async componentDidMount() {
        this.fetchConfigurationForTargetDevice()
    }

    componentDidUpdate(prevProps: StudioFormProps & RouteComponentProps<any>) {
        if (this.props.translations !== prevProps.translations && !!this.props.translations) {
            this.setState({ translations: this.props.translations })
        }
        if (this.props.publish !== prevProps.publish && !!this.props.publish) {
            this.handleSubmit()
        }
        if (this.props.saveDraft !== prevProps.saveDraft && !!this.props.saveDraft) {
            this.saveDraft(false)
        }
    }

    handleSubmit = () => {
        this.props.handleSubmit(this.safeGetConfigurationValues(), {
            submit: this.onSubmit,
            saveDraft: this.saveDraft,
        })
    }

    componentWillUnmount() {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler)
        }
    }

    fetchConfigurationForTargetDevice = async () => {
        try {
            const productConfigurations = await this.props.componentsService.getConfigurationsVersions(
                this.props.accountSlug,
                this.props.product,
            )
            const configurationById = productConfigurations.find((cv) => cv.id === this.props.configurationId)
            if (configurationById) {
                this.props.setCurrentProductConfiguration(configurationById)
            }
            if (!configurationById) {
                this.props.replaceTopMessages('error', 'error', 'Could not find configuration with this id.')
                return
            } else if (!configurationById.next) {
                const draft = await this.props.componentsService.createEmptyDraft(
                    this.props.accountSlug,
                    this.props.product,
                    this.props.configurationId,
                )
                this.setState({ draftId: draft.id })
            } else {
                this.setState({ draftId: configurationById.next.id })
                this.props.setDraftId(configurationById.next.id)
            }
            try {
                const values = await this.props.componentsService.getConfiguration(
                    this.props.accountSlug,
                    this.props.product,
                    this.props.configurationId,
                )
                this.setState({
                    componentValues: values.components[0].value,
                    translations: values.translations,
                    languages: values.languages,
                    urlRules: values.urlRules,
                    productConfig: values.productConfig,
                    configurationName: productConfigurations.find((pc) => pc.id === configurationById.id)?.name || '',
                })
                this.props.onComponentValuesUpdate(values.components[0].value)
                this.props.onTranslationsUpdate(values.translations)
                this.props.onLanguagesUpdate(values.languages)
                this.props.onUrlRulesUpdate(values.urlRules)
                this.props.onProductConfigUpdate(values.productConfig)
            } catch (e) {
                this.props.replaceTopMessages(
                    'config_error',
                    'error',
                    'Oops! An unknown error occured. Please try again later.',
                )
            }
        } catch (e) {
            this.props.replaceTopMessages('server_error', 'error', e)
        } finally {
            this.setState({ loading: false })
        }
    }

    flashErrorMessage = async (message: string) => {
        this.props.replaceTopMessages('form_error', 'error', message)
        await delay(3000)
        this.props.removeAllMessages()
    }

    onSubmit = (e?: React.MouseEvent<any>): void => {
        if (this.props.product === 'checkout') {
            if (this.validateCustomFonts()) {
                this.saveDraft(true)
            }
        } else if (this.validateTranslations()) {
            this.saveDraft(true)
        }
    }

    validateTranslations = (): boolean => {
        const search = location.search

        if (this.props.product === 'modal_window' && this.state.componentValues) {
            if ((this.state.componentValues as ModalWindowConfiguration).linkToCheckout) {
                this.resetATranslation(COPY_KEYS.URL)
            }
            if ((this.state.componentValues as ModalWindowConfiguration).hideProceedButton) {
                this.resetATranslation(COPY_KEYS.BUTTON)
            }
        }
        const languageWithError = getLanguageWithMissingTranslations(
            this.props.product,
            this.state.componentValues,
            this.state.languages,
            this.state.translations,
        )

        if ((this.state.languages && this.state.languages.selected.length < 1) || languageWithError) {
            if (languageWithError) {
                this.props.setActiveLanguage(languageWithError)
                this.setState({ translationsValidation: true })
            }
            this.props.history.replace(
                `/account/${this.props.accountSlug}/engage/tools/${productURLSlugs[this.props.product]}/${
                    this.props.configurationId
                }/text${search}`,
            )
            this.flashErrorMessage('We detected some errors! Please review the Text section before publishing.')
            return false
        } else {
            return true
        }
    }

    validateCustomFonts = (): boolean => {
        if (
            this.props.product === 'checkout' &&
            this.state.componentValues &&
            ((this.state.componentValues as CheckoutConfiguration).primaryFont === 'custom' ||
                (this.state.componentValues as CheckoutConfiguration).secondaryFont === 'custom')
        ) {
            this.setState({ checkoutFontsValidation: true })
            this.flashErrorMessage('We detected some errors! Please review the Fonts section before publishing.')
            return false
        } else {
            return true
        }
    }

    getConfigurationValues = () => {
        return buildConfiguration({
            config: this.state.componentValues,
            transl: this.state.translations,
            langs: this.state.languages,
            urlRules: this.state.urlRules,
            product: this.props.product,
            productConfig: this.state.productConfig,
        })
    }

    safeGetConfigurationValues = (): Configuration => {
        try {
            return this.getConfigurationValues()
        } catch (error) {
            return {} as Configuration
        }
    }

    async saveDraft(publish: boolean) {
        this.props.removeAllMessages()
        this.setState({ loading: true, ongoingPublish: true, saveDraft: !publish }, async () => {
            try {
                const configurationValues = this.getConfigurationValues()
                await this.props.componentsService.sendConfiguration(
                    this.props.accountSlug,
                    configurationValues,
                    this.props.product,
                    this.props.configurationId,
                )
                if (publish) {
                    await this.props.componentsService.publishDraft(
                        this.props.accountSlug,
                        this.props.configurationId,
                        this.state.draftId,
                        this.props.product,
                    )
                }
                this.setState({ unsavedChanges: false })
                const search = location.search
                this.props.history.push(
                    `/account/${this.props.accountSlug}/engage/tools/${productURLSlugs[this.props.product]}/${
                        this.props.configurationId
                    }/success${search}`,
                )
            } catch (e) {
                this.props.replaceTopMessages(
                    'config_error',
                    'error',
                    e?.message || 'Oops! An unknown error occured. Please try again later.',
                )
                this.setState({ ongoingPublish: false })
            } finally {
                this.setState({ loading: false })
            }
        })
    }

    onDialogButtonClick = (e: React.MouseEvent<any>) => {
        const origin = parseSearch(location.search).from
        const destination = origin === 'modal_window' ? 'pop_up' : origin || 'checkout'

        const summaryLink = `/account/${this.props.accountSlug}/engage/tools/home/${destination}`
        this.props.history.replace(summaryLink)
    }

    updateComponent = (path: string, value: string | number | boolean | string[]) => {
        const updatedComponentValues = setPropertyPath(this.state.componentValues, path, value)
        if (updatedComponentValues) {
            this.setState({
                componentValues: updatedComponentValues,
                unsavedChanges: true,
            })
            this.props.onComponentValuesUpdate(updatedComponentValues)
        }
    }

    updateWholeConfig = (config: EngageToolConfig) => {
        this.setState({ componentValues: config, unsavedChanges: true })
        this.props.onComponentValuesUpdate(config)
    }

    updateProductConfig = (config: ProductConfig) => {
        this.setState({ productConfig: config })
        this.props.onProductConfigUpdate(config)
    }

    updateLanguages = (selected: string[], primary: string) => {
        this.setState({ languages: { selected, primary }, unsavedChanges: true })
        this.props.onLanguagesUpdate({ selected, primary })
    }

    updateTranslations = (slug: string, language: string, value: string) => {
        const updatedTranslations = updateTranslations(this.state.translations, slug, language, value)
        if (updatedTranslations) {
            this.setState({
                translations: updatedTranslations,
                unsavedChanges: true,
            })
            this.props.onTranslationsUpdate(updatedTranslations)
        }
    }

    resetATranslation = (slug: string) => {
        const updatedTranslations = this.state.translations.map((t) => {
            if (t.slug === slug) {
                t.values = {}
            }
            return t
        })
        if (updatedTranslations) {
            this.setState({
                translations: updatedTranslations,
                unsavedChanges: true,
            })
            this.props.onTranslationsUpdate(updatedTranslations)
        }
    }

    updateUrlRules = (list: 'blacklist' | 'whitelist', logic: string, ruleType: string, value: string) => {
        if (this.state.urlRules !== null) {
            if (list === 'blacklist') {
                let rules
                if (logic === 'add') {
                    rules = increasedUrlRulesBlacklist(this.state.urlRules, ruleType, value)
                } else {
                    rules = decreasedUrlRulesBlacklist(this.state.urlRules, ruleType, value)
                }
                this.setState({ urlRules: rules, unsavedChanges: true })
                this.props.onUrlRulesUpdate(rules)
            } else {
                let rules
                if (logic === 'add') {
                    rules = increasedUrlRulesWhitelist(this.state.urlRules, ruleType, value)
                } else {
                    rules = decreasedUrlRulesWhitelist(this.state.urlRules, ruleType, value)
                }
                this.setState({ urlRules: rules, unsavedChanges: true })
                this.props.onUrlRulesUpdate(rules)
            }
        }
    }

    getProductConfigurationObject = () => {
        switch (this.props.product) {
            case 'wonderbar':
                return WonderBarFormDefinition
            case 'modal_window':
                return ModalWindowFormDefinition
            case 'trigger_button':
                return TriggerButtonFormDefinition
            case 'checkout':
                return CheckoutFormDefinition
            default:
                return WonderBarFormDefinition
        }
    }

    getProductConfigurationDefinition = (step: string) => {
        const definition = this.getProductConfigurationObject()
        const currentDefinition = definition[step]

        if (!currentDefinition) {
            return definition['style']
        }

        return currentDefinition
    }

    getStepObject = () => {
        return this.getProductConfigurationDefinition(this.props.currentStep || 'step')
    }

    onConfirmNavigation = () => {
        this.props.logEvent('studio_closed')
    }

    clearWhitelist = () => {
        if (!this.state.urlRules) {
            return
        }
        const newUrlRules = {
            blacklist: this.state.urlRules.blacklist,
            whitelist: [],
        }
        this.setState({ urlRules: newUrlRules })
    }

    render() {
        const { currentStep, menuItems, history } = this.props

        const shouldTranslationsLoad = this.props.translationsLoading && currentStep === 'text'
        const currentStepObject = this.getStepObject()
        return (
            <StudioFormContext.Provider
                value={{
                    values: this.safeGetConfigurationValues(),
                    handleFieldChange: (name, value) => {
                        const config = set(this.getConfigurationValues(), name, value)

                        if (!!config.productConfig) {
                            this.updateProductConfig(config.productConfig)
                        }
                    },
                }}
            >
                <NavigationPrompt
                    when={(crntLocation, nextLocation) => onNavigateAway(nextLocation, this.state.unsavedChanges)}
                    afterConfirm={this.onConfirmNavigation}
                >
                    {({ onConfirm, onCancel }) => (
                        <ConfirmationDialog
                            title="Do you want to exit without publishing?"
                            text="Watch out! By exiting the Convious Studio, all your changes will be lost."
                            buttonText="Exit"
                            destructive
                            onCancel={onCancel}
                            onConfirm={onConfirm}
                        />
                    )}
                </NavigationPrompt>
                <Route
                    path={`/account/${this.props.accountSlug}/engage/tools/${productURLSlugs[this.props.product]}/${
                        this.props.configurationId
                    }/success`}
                    location={this.props.history.location}
                    render={() => (
                        <SuccessDialog
                            onButtonClick={this.onDialogButtonClick}
                            product={this.props.product}
                            draftSaved={this.state.saveDraft}
                        />
                    )}
                />
                <FormContainer>
                    <FormContent className={this.props.product === 'checkout' ? 'full-height' : ''}>
                        <SidebarMenu>
                            <StudioConviousLogo />
                            <NameWrapper>{this.state.configurationName}</NameWrapper>
                            <SidebarTitle>{productTitles[this.props.product]}</SidebarTitle>
                            <nav>
                                <Menu>
                                    {menuItems
                                        .filter((item: MenuItem) => {
                                            const definition = this.getProductConfigurationDefinition(item)

                                            if (!!definition.feature) {
                                                return this.props.hasFeature(definition.feature, this.props.accountSlug)
                                            }

                                            return true
                                        })
                                        .map((item: MenuItem, i) => (
                                            <div key={i}>
                                                {this.props.product === 'checkout' &&
                                                    !!CheckoutFormDefinition[item] && (
                                                        <SidebarMenuItem
                                                            product={this.props.product}
                                                            accountSlug={this.props.accountSlug}
                                                            item={CheckoutFormDefinition[item]}
                                                            highlighted={
                                                                CheckoutFormDefinition[item].name === currentStep
                                                            }
                                                            key={i}
                                                            disabled={false}
                                                            history={this.props.history}
                                                            configurationId={this.props.configurationId}
                                                        />
                                                    )}
                                                {this.props.product === 'wonderbar' && (
                                                    <SidebarMenuItem
                                                        product={this.props.product}
                                                        accountSlug={this.props.accountSlug}
                                                        item={WonderBarFormDefinition[item]}
                                                        highlighted={WonderBarFormDefinition[item].name === currentStep}
                                                        key={i}
                                                        disabled={!this.state.componentValues}
                                                        history={this.props.history}
                                                        configurationId={this.props.configurationId}
                                                    />
                                                )}
                                                {this.props.product === 'modal_window' && (
                                                    <SidebarMenuItem
                                                        product={this.props.product}
                                                        accountSlug={this.props.accountSlug}
                                                        item={ModalWindowFormDefinition[item]}
                                                        highlighted={
                                                            ModalWindowFormDefinition[item].name === currentStep
                                                        }
                                                        key={i}
                                                        disabled={!this.state.componentValues}
                                                        history={this.props.history}
                                                        configurationId={this.props.configurationId}
                                                    />
                                                )}
                                                {this.props.product === 'trigger_button' && (
                                                    <SidebarMenuItem
                                                        product={this.props.product}
                                                        accountSlug={this.props.accountSlug}
                                                        item={TriggerButtonFormDefinition[item]}
                                                        highlighted={
                                                            TriggerButtonFormDefinition[item].name === currentStep
                                                        }
                                                        key={i}
                                                        disabled={!this.state.componentValues}
                                                        history={this.props.history}
                                                        configurationId={this.props.configurationId}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </Menu>
                            </nav>
                        </SidebarMenu>
                        <Form noValidate>
                            <FormBody>
                                {!this.state.loading && !this.props.loading && (
                                    <>
                                        <FormHeader>
                                            <span>{currentStepObject.header}</span>
                                        </FormHeader>
                                        {currentStepObject.description && (
                                            <FormDescription>
                                                <span>{currentStepObject.description}</span>
                                            </FormDescription>
                                        )}
                                        <ScrollArea id="scroll-area">
                                            {currentStepObject.sections
                                                .filter((item: SectionObject) => {
                                                    if (!!item.feature) {
                                                        return this.props.hasFeature(
                                                            item.feature,
                                                            this.props.accountSlug,
                                                        )
                                                    }

                                                    return true
                                                })
                                                .map((item: SectionObject, i: number) => {
                                                    return (
                                                        <div key={i}>
                                                            {this.props.product === 'checkout' && (
                                                                <CheckoutForm
                                                                    accountSlug={this.props.accountSlug}
                                                                    section={item}
                                                                    imagesService={this.props.imagesService}
                                                                    componentsService={this.props.componentsService}
                                                                    replaceTopMessages={this.props.replaceTopMessages}
                                                                    removeAllMessages={this.props.removeAllMessages}
                                                                    checkoutConfiguration={
                                                                        this.state
                                                                            .componentValues as CheckoutConfiguration
                                                                    }
                                                                    updateConfiguration={this.updateComponent}
                                                                    updateWholeConfig={this.updateWholeConfig}
                                                                    fontsValidation={this.state.checkoutFontsValidation}
                                                                    setFontsValidation={(on: boolean) =>
                                                                        this.setState({
                                                                            checkoutFontsValidation: on,
                                                                        })
                                                                    }
                                                                />
                                                            )}
                                                            {this.props.product === 'wonderbar' && (
                                                                <WondebarForm
                                                                    accountSlug={this.props.accountSlug}
                                                                    section={item}
                                                                    wonderbarConfiguration={
                                                                        this.state
                                                                            .componentValues as WonderbarConfiguration
                                                                    }
                                                                    languages={this.state.languages}
                                                                    translations={this.state.translations}
                                                                    urlRules={this.state.urlRules}
                                                                    updateConfiguration={this.updateComponent}
                                                                    onLanguageChange={this.updateLanguages}
                                                                    updateTranslations={this.updateTranslations}
                                                                    resetATranslation={this.resetATranslation}
                                                                    updateUrlRules={this.updateUrlRules}
                                                                    deviceType={this.props.targetDevice}
                                                                    translationsValidation={
                                                                        this.state.translationsValidation
                                                                    }
                                                                    setTranslationsValidation={(on: boolean) =>
                                                                        this.setState({
                                                                            translationsValidation: on,
                                                                        })
                                                                    }
                                                                    activeLanguage={this.props.activeLanguage}
                                                                    setActiveLanguage={this.props.setActiveLanguage}
                                                                />
                                                            )}
                                                            {this.props.product === 'modal_window' && (
                                                                <ModalWindowForm
                                                                    accountSlug={this.props.accountSlug}
                                                                    section={item}
                                                                    imagesService={this.props.imagesService}
                                                                    componentsService={this.props.componentsService}
                                                                    modalWindowConfiguration={
                                                                        this.state
                                                                            .componentValues as ModalWindowConfiguration
                                                                    }
                                                                    languages={this.state.languages}
                                                                    onLanguageChange={this.updateLanguages}
                                                                    translations={this.state.translations}
                                                                    updateConfiguration={this.updateComponent}
                                                                    urlRules={this.state.urlRules}
                                                                    updateUrlRules={this.updateUrlRules}
                                                                    clearWhitelist={this.clearWhitelist}
                                                                    replaceTopMessages={this.props.replaceTopMessages}
                                                                    removeAllMessages={this.props.removeAllMessages}
                                                                    translationsLoading={shouldTranslationsLoad}
                                                                    updateTranslations={this.updateTranslations}
                                                                    resetATranslation={this.resetATranslation}
                                                                    translationsValidation={
                                                                        this.state.translationsValidation
                                                                    }
                                                                    setTranslationsValidation={(on: boolean) =>
                                                                        this.setState({
                                                                            translationsValidation: on,
                                                                        })
                                                                    }
                                                                    activeLanguage={this.props.activeLanguage}
                                                                    setActiveLanguage={this.props.setActiveLanguage}
                                                                />
                                                            )}
                                                            {this.props.product === 'trigger_button' && (
                                                                <TriggerButtonForm
                                                                    accountSlug={this.props.accountSlug}
                                                                    section={item}
                                                                    componentsService={this.props.componentsService}
                                                                    imagesService={this.props.imagesService}
                                                                    updateConfiguration={this.updateComponent}
                                                                    triggerButtonConfiguration={
                                                                        this.state
                                                                            .componentValues as TriggerButtonConfiguration
                                                                    }
                                                                    urlRules={this.state.urlRules}
                                                                    updateUrlRules={this.updateUrlRules}
                                                                    replaceTopMessages={this.props.replaceTopMessages}
                                                                    removeAllMessages={this.props.removeAllMessages}
                                                                    languages={this.state.languages}
                                                                    onLanguageChange={this.updateLanguages}
                                                                    translations={this.state.translations}
                                                                    translationsLoading={shouldTranslationsLoad}
                                                                    updateTranslations={this.updateTranslations}
                                                                    resetATranslation={this.resetATranslation}
                                                                    translationsValidation={
                                                                        this.state.translationsValidation
                                                                    }
                                                                    setTranslationsValidation={(on: boolean) =>
                                                                        this.setState({
                                                                            translationsValidation: on,
                                                                        })
                                                                    }
                                                                    activeLanguage={this.props.activeLanguage}
                                                                    setActiveLanguage={this.props.setActiveLanguage}
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                        </ScrollArea>
                                    </>
                                )}
                                {(this.state.loading || this.props.loading) && <PageLoader />}
                            </FormBody>
                        </Form>
                    </FormContent>
                    {this.props.product !== 'checkout' && (
                        <Footer
                            accountSlug={this.props.accountSlug}
                            product={this.props.product}
                            configurationId={this.props.configurationId}
                            componentValues={this.state.componentValues}
                            menuItems={menuItems}
                            formSubmit={this.handleSubmit}
                            history={history}
                            ongoingPublish={this.state.ongoingPublish}
                            currentStep={this.props.currentStep}
                        />
                    )}
                </FormContainer>
            </StudioFormContext.Provider>
        )
    }
}

export default withRouter(StudioForm)
