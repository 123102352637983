import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'
import { history } from 'middleware'
import { NavigationTabs } from 'uiComponents/navigation/tabs'
import { pathUtils } from 'utils/pathnameFormatter'
import { Col, Row } from 'uiComponents/flex'
import { CODES_PATHS } from '../paths'
import SearchBar from 'uiComponents/search/searchBar'
import './discountCodes.scss'

const DiscountCodes: React.FC = ({ children }) => {
    const location = useLocation()
    const activeTab = location.pathname.includes(CODES_PATHS.internalPaths.discountPath.pools) ? 'pools' : 'codes'
    const searchByOptions =
        activeTab === 'pools'
            ? [{ value: 'discount_reservoir_name', name: 'Pool name' }]
            : [
                  { value: 'code', name: 'Code' },
                  { value: 'discount_reservoir_name', name: 'Pool name' },
                  { value: 'product_id', name: 'Product ID' },
                  { value: 'order_number', name: 'Order number' },
                  { value: 'validity_date', name: 'Validity date', inputType: 'date' },
              ]
    const defaultSearchBy = activeTab === 'pools' ? 'discount_reservoir_name' : 'code'
    const routeParams = useParams<BaseRouteParams>()

    return (
        <div id="discount-codes">
            <Row align="flex-start" vAlign="center" style={{ padding: '1.25em 1em' }}>
                <Col span={5}>
                    <SearchBar
                        searchByOptions={searchByOptions}
                        defaultSearchBy={defaultSearchBy}
                        autoSearchByPatterns={[
                            [/^\d{8,9}$/, 'order_number'],
                            [/^\d{4}$/, 'product_id'],
                        ]}
                    />
                </Col>
                <Col span="auto">
                    <NavigationTabs
                        tabs={[
                            {
                                current: activeTab === 'pools',
                                name: 'Pools',
                                onClick: () => {
                                    history.push(pathUtils.populateParams(CODES_PATHS.fullPaths.poolsPage, routeParams))
                                },
                            },
                            {
                                current: activeTab === 'codes',
                                name: 'Codes',
                                onClick: () => {
                                    history.push(
                                        pathUtils.populateParams(
                                            CODES_PATHS.internalPaths.discountPath.codes,
                                            routeParams,
                                        ),
                                    )
                                },
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>{children}</Col>
            </Row>
        </div>
    )
}

export default DiscountCodes
