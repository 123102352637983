import styled from 'styled-typed'
import { Body, Text, TextProps } from 'uiComponents/typography'

export const FormItem = styled.label`
    display: block;
`
export const FormItemName = styled(Body)`
    margin: 0 0 0.25em 0;
    display: flex;
    align-items: center;

    span {
        &.disabled {
            opacity: 0.5;
        }
    }
`

interface WrapWithSymbolProps {
    symbol: string
    position: 'left' | 'right'
}
export const WrapWithSymbol = styled.div<WrapWithSymbolProps>`
    position: relative;
    &:before {
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        ${(props) => props.position}: .5rem;
        content: ${(props) => `'${props.symbol}'`};
        font-weight: lighter;
        font-size: 0.9rem;
    }
    input {
        display: block;
        width: 100%;
        ${(props) => props.position === 'right' && 'padding-right: 2.2em;'};
        ${(props) => props.position === 'left' && 'padding-left: 2.2em;'};
    }
`

export const ValidationMessage = styled(Text).attrs((props: TextProps) => ({
    status: props.status ? props.status : 'error',
}))`
    font-size: 0.625em;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in;
    margin-left: 1.7em;
    position: relative;
    top: -0.7em;

    &.validation-message-visible {
        opacity: 1;
        visibility: visible;
    }
`
