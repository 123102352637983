import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppServices } from 'middleware'
import {
    BulkRefundOrderProps,
    GetOrderDetailsRestParams,
    ListOrdersParams,
    OrdersListRequestPayload,
    IOrder,
    Cart,
    SelectedForRefund,
    SentEmailsResponse,
} from './schema'
import { toggleAllOrderItemsIfOrderSelected } from './orders/redux'
import { Location, ScheduleAsyncExportPayload } from './ordersService'
import { AuthTicket } from 'http/oauthService'
import { UseDownloadLinkPayload } from './orders/utils'
import { DeepPartial } from 'utility-types'

export const ordersApi = createApi({
    reducerPath: 'ordersApi',
    tagTypes: ['listOrders', 'orderDetails', 'listCarts'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

interface UpdateVisitDateAndTimeProps {
    orderUuid: string
    dateTime: string | null
    visitTime: string | null
}

interface UpdateTicketValidToDateProps {
    accountSlug: string
    orderItemId?: string
    orderItemUuid?: string
    validTo: string
}

const extendedOrderApi = ordersApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrderDetailsByUuid: builder.query({
            providesTags: (result, error, arg) =>
                result ? [{ type: 'orderDetails' as const, id: arg.uuid }, 'orderDetails'] : ['orderDetails'],
            queryFn: async (params: GetOrderDetailsRestParams, api) => {
                try {
                    const data = await AppServices.ordersService.getOrderDetailsRest(params)
                    api.dispatch(
                        toggleAllOrderItemsIfOrderSelected({
                            tableId: params.uuid,
                            rows:
                                (data.items &&
                                    data.items.reduce((acc, item) => {
                                        acc[item.id] = !item.refundInfo
                                        return acc
                                    }, {} as { [itemUuid: string]: boolean })) ??
                                {},
                        }),
                    )
                    return { data } as { data: IOrder }
                } catch (error) {
                    return { error }
                }
            },
        }),
        updateEmail: builder.mutation({
            invalidatesTags: ['listOrders', 'orderDetails'],
            queryFn: async ({ orderUuid, email }: { orderUuid: string; email: string }) => {
                try {
                    const data = await AppServices.ordersService.updateEmail(orderUuid, email)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        updateVisitDateAndTime: builder.mutation({
            invalidatesTags: (result, error, arg) => [{ type: 'orderDetails', id: arg.orderUuid }, 'listOrders'],
            queryFn: async ({ orderUuid, dateTime, visitTime }: UpdateVisitDateAndTimeProps) => {
                try {
                    const data = await AppServices.ordersService.updateVisitDateAndTime(orderUuid, dateTime, visitTime)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        bulkRefundOrder: builder.mutation({
            invalidatesTags: ['listOrders'],
            queryFn: async (options: BulkRefundOrderProps) => {
                try {
                    const data = await AppServices.ordersService.bulkRefundOrder(options)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        redeemBarcodes: builder.mutation({
            invalidatesTags: ['listOrders', 'orderDetails'],
            queryFn: async ({ accountSlug, barcodes }: { accountSlug: string; barcodes: string[] }) => {
                try {
                    const data = await AppServices.ordersService.redeemBarcodes(accountSlug, barcodes)
                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        listOrders: builder.query({
            providesTags: ['listOrders'],
            queryFn: async ({
                accountSlug,
                dateRangeFrom,
                dateRangeTo,
                dateRangeType,
                query,
                sortProp,
                sortDirection,
                page,
                pageSize,
                searchType,
                filter,
            }: ListOrdersParams) => {
                try {
                    const data = await AppServices.ordersService.getTransactionsList(
                        accountSlug,
                        dateRangeFrom,
                        dateRangeTo,
                        dateRangeType,
                        query,
                        sortProp,
                        sortDirection,
                        page,
                        pageSize,
                        searchType,
                        filter,
                    )

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getListOrders: builder.query({
            providesTags: ['listOrders'],
            queryFn: async ({
                accountSlug,
                include,
                sortBy,
                sortDirection,
                search,
                searchBy,
                resellers,
                directSales,
                paymentMethods,
                locations,
                fromCreatedAt,
                toCreatedAt,
                fromEventDate,
                toEventDate,
                status,
                paymentStatus,
                emailStatus,
                products,
                pageSize,
                offset,
            }: OrdersListRequestPayload) => {
                try {
                    const response = await AppServices.ordersService.getOrdersList({
                        accountSlug: accountSlug,
                        include: include,
                        sortBy: sortBy,
                        sortDirection: sortDirection,
                        search: search,
                        searchBy: searchBy,
                        resellers: resellers,
                        directSales: directSales,
                        paymentMethods: paymentMethods,
                        locations: locations,
                        fromCreatedAt: fromCreatedAt,
                        toCreatedAt: toCreatedAt,
                        fromEventDate: fromEventDate,
                        toEventDate: toEventDate,
                        status: status,
                        paymentStatus: paymentStatus,
                        emailStatus: emailStatus,
                        products: products,
                        pageSize: pageSize,
                        offset: offset,
                    })

                    return { data: response }
                } catch (error) {
                    return { error }
                }
            },
        }),
        listCarts: builder.query({
            providesTags: ['listCarts'],
            queryFn: async ({ accountSlug, query }: { accountSlug: string; query?: string }) => {
                try {
                    const data = await AppServices.ordersService.listCarts({ accountSlug, query })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getCart: builder.query({
            queryFn: async ({ accountSlug, id }: { accountSlug: string; id: string }) => {
                try {
                    const data = await AppServices.ordersService.getCart({ accountSlug, id })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        patchCart: builder.mutation({
            queryFn: async ({
                accountSlug,
                cartId,
                overrides,
            }: {
                accountSlug: string
                cartId: string
                overrides: DeepPartial<Cart>
            }) => {
                try {
                    const response = await AppServices.ordersService.patchCart({ accountSlug, cartId, overrides })

                    return { data: response }
                } catch (error) {
                    return { error }
                }
            },
        }),
        patchOrder: builder.mutation({
            queryFn: async ({ orderId, overrides }: { orderId: string; overrides: DeepPartial<IOrder> }) => {
                try {
                    const response = await AppServices.ordersService.patchOrder({ orderId, overrides })

                    return { data: response }
                } catch (error) {
                    return { error }
                }
            },
        }),
        changeCartExpirationDate: builder.mutation({
            invalidatesTags: ['listCarts'],
            queryFn: async ({
                accountSlug,
                id,
                expirationDate,
            }: {
                accountSlug: string
                id: string
                expirationDate: string
            }) => {
                try {
                    const data = await AppServices.ordersService.changeCartExpirationTime({
                        accountSlug,
                        id,
                        expirationDate,
                    })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        markCartAsPaid: builder.mutation({
            invalidatesTags: ['listCarts'],
            queryFn: async ({ accountSlug, cartId }: { accountSlug: string; cartId: string }) => {
                try {
                    await AppServices.ordersService.markCartAsPaid(accountSlug, cartId)
                    return { data: {} }
                } catch (error) {
                    return { error }
                }
            },
        }),
        updateTicketValidToDate: builder.mutation({
            invalidatesTags: ['orderDetails'],
            queryFn: async ({ accountSlug, orderItemId, orderItemUuid, validTo }: UpdateTicketValidToDateProps) => {
                try {
                    await AppServices.ordersService.updateTicketValidToDate({
                        account: accountSlug,
                        orderItemId,
                        validTo,
                        orderItemUuid,
                    })

                    return { data: {} }
                } catch (error) {
                    return { error }
                }
            },
        }),
        resendEmail: builder.mutation({
            queryFn: async ({ orderNumber }: { orderNumber: string }) => {
                try {
                    await AppServices.ordersService.resendEmail(orderNumber)

                    return { data: {} }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getSentEmails: builder.query({
            queryFn: async ({ id, accountSlug, type }: { id: string; accountSlug: string; type: 'cart' | 'order' }) => {
                try {
                    const getSentEmailsFunctions: Record<
                        'cart' | 'order',
                        (args: { id: string; accountSlug: string }) => Promise<SentEmailsResponse>
                    > = {
                        cart: (...args) => AppServices.ordersService.getSentCartEmails(...args),
                        order: (...args) => AppServices.ordersService.getSentOrderEmails(...args),
                    }

                    const data = await getSentEmailsFunctions[type]({ id, accountSlug })

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getApfConfig: builder.query({
            queryFn: async (params: { accountSlug: string }, api) => {
                try {
                    const data = await AppServices.articleService.getAPFConfig(params.accountSlug)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        uploadApfPhoto: builder.mutation({
            queryFn: async (params: { file: File }) => {
                try {
                    const data = await AppServices.ordersService.uploadApfPhoto(params.file)

                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        updateApfField: builder.mutation({
            invalidatesTags: ['orderDetails'],
            queryFn: async (params: { ticketUuid: string; fieldName: string; fieldValue: string }) => {
                try {
                    const data = await AppServices.ordersService.updateTicketApfField({ ...params })
                    return { data: data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getOrderDetailsForRefund: builder.query({
            queryFn: async (params: { orderId: string }) => {
                try {
                    const data = await AppServices.ordersService.getOrderDetailsForRefund(params.orderId)
                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getAccountLocations: builder.query({
            queryFn: async (params: { accountSlug: string }) => {
                try {
                    const response = await AppServices.openingTimesService.listLocations(params.accountSlug)

                    return {
                        data: response.map((item) => ({
                            uuid: item.id,
                            name: item.locationName,
                        })) as Location[],
                    }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getAccountLocales: builder.query({
            queryFn: async (params: { accountSlug: string }) => {
                try {
                    const data = await AppServices.articleService.getAccountLocales(params.accountSlug)

                    return {
                        data,
                    }
                } catch (error) {
                    return { error }
                }
            },
        }),
        getResellerList: builder.query({
            queryFn: async (params: { accountSlug: string; query?: string }) => {
                try {
                    const data = await AppServices.channelsService.getResellersList(params.accountSlug, params.query)

                    return {
                        data,
                    }
                } catch (error) {
                    return { error }
                }
            },
        }),
        scheduleAsyncExport: builder.mutation({
            queryFn: async (params: {
                endpoint: string
                payload: ScheduleAsyncExportPayload | UseDownloadLinkPayload
            }) => {
                try {
                    await AppServices.ordersService.scheduleAsyncExport(params.endpoint, params.payload)
                    return { data: {} }
                } catch (error) {
                    return { error }
                }
            },
        }),
        ensureTokenNotExpired: builder.mutation({
            queryFn: async (ticket: AuthTicket) => {
                try {
                    await AppServices.loginService.ensureTokenNotExpired(ticket)
                    return { data: {} }
                } catch (error) {
                    return { error }
                }
            },
        }),
        refundOrder: builder.mutation({
            invalidatesTags: ['listOrders', 'orderDetails'],
            queryFn: async (params: {
                accountSlug: string
                orderId: string
                selectedForRefundOrderItems: SelectedForRefund[]
                refundReason: string
                forgoPartnerRefundFee: boolean
                isCancellation: boolean
            }) => {
                try {
                    await AppServices.ordersService.refundOrder(
                        params.accountSlug,
                        params.orderId,
                        params.selectedForRefundOrderItems,
                        params.refundReason,
                        params.forgoPartnerRefundFee,
                        params.isCancellation,
                    )
                    return { data: {} }
                } catch (error) {
                    return { error }
                }
            },
        }),
        cancelCart: builder.mutation({
            queryFn: async (args: { accountSlug: string; cartIds: string[] }) => {
                try {
                    await AppServices.ordersService.cancelCart(args)
                    return { data: {} }
                } catch (error) {
                    return { error }
                }
            },
        }),
    }),
})

export const { reducer, reducerPath } = ordersApi
export const {
    useGetOrderDetailsByUuidQuery,
    useLazyListOrdersQuery,
    useUpdateEmailMutation,
    useUpdateVisitDateAndTimeMutation,
    useBulkRefundOrderMutation,
    useRedeemBarcodesMutation,
    useLazyListCartsQuery,
    useLazyGetCartQuery,
    useChangeCartExpirationDateMutation,
    useMarkCartAsPaidMutation,
    usePatchCartMutation,
    useUpdateTicketValidToDateMutation,
    useResendEmailMutation,
    useLazyGetListOrdersQuery,
    useGetApfConfigQuery,
    useUploadApfPhotoMutation,
    useUpdateApfFieldMutation,
    useGetOrderDetailsForRefundQuery,
    useLazyGetOrderDetailsForRefundQuery,
    useGetAccountLocationsQuery,
    useGetAccountLocalesQuery,
    useGetResellerListQuery,
    useScheduleAsyncExportMutation,
    useEnsureTokenNotExpiredMutation,
    useLazyGetSentEmailsQuery,
    usePatchOrderMutation,
    useLazyGetOrderDetailsByUuidQuery,
    useRefundOrderMutation,
    useCancelCartMutation,
} = extendedOrderApi

export const selectListOrderResult = extendedOrderApi.endpoints.getListOrders.select
export const selectGetOrderDetailsResult = extendedOrderApi.endpoints.getOrderDetailsByUuid.select
export const invalidateCartTag = () => extendedOrderApi.util.invalidateTags(['listCarts'])
export const endpoints = extendedOrderApi.endpoints
