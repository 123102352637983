import * as React from 'react'
import { ChartDataLoader } from 'uiComponents/loaders'
import { CancelRefundHeader, CancelRefundNotes, CancelRefundFooter } from '.'
import CancelRefundTable from './cancelRefundTable'
import { CancelRefundDialogProps } from '../types'
import './styles.scss'

const CancelRefundDialog = ({
    accountSlug,
    loading,
    refunding,
    confirmMode,
    headerProps,
    tableData,
    notesProps,
    footerProps,
    handleItemSelect,
    handleRefundFeeSelect,
}: CancelRefundDialogProps) => {
    return (
        <div id="cancel-refund-container">
            {(refunding || loading) && <ChartDataLoader />}
            <div className="cancel-refund-content">
                <CancelRefundHeader {...headerProps} />
                <div>
                    <CancelRefundTable
                        data={tableData}
                        accountSlug={accountSlug}
                        confirmMode={confirmMode}
                        onSelectItem={handleItemSelect}
                        onRefundFeeSelect={handleRefundFeeSelect}
                    />
                </div>
                <CancelRefundNotes {...notesProps} />
                <CancelRefundFooter {...footerProps} />
            </div>
        </div>
    )
}

export default CancelRefundDialog
