import * as React from 'react'
import styled, { css } from 'styled-typed'
import Svg from 'svg'
import { Card } from 'uiComponents/cards'
import ComingSoonImg from 'images/badge-coming-soon2x.png'
import { Row, Col } from 'uiComponents/flex'
import { CardData } from './index'
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import { RELEASED_BADGE } from 'releaseNotes/htmlComponents'

const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    margin-bottom: 0.6em;
    font-size: 1em;
`
const Icon = styled(Svg)`
    height: 4.3em;
`
const IconColumn = styled(Col)`
    display: flex;
    justify-content: center;
`
const Text = styled.span`
    font-size: 0.875em;
`

export const cardStyle = css`
    border: none;
    border-radius: 0.5em;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
`

const ActionCardItem = styled(Card)`
    width: 22.5em;
    height: 8.125em;
    position: relative;
    margin: 0 1em 1em 1em;
    padding: 1em 1.2em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${cardStyle}

    .arrow {
        margin-left: 0.5em;
        transition: all 0.2s ease-in;
    }

    ${Icon} {
        color: white;
        stroke: none;
    }
    &:hover {
        ${Title} {
            color: ${(p) => p.theme.colors.boyBlue};
        }
        .arrow {
            margin-left: 1.1em;
        }
    }
    &.comingSoon {
        &:before {
            width: 77px;
            height: 20px;
            content: ' ';
            background-image: url(${ComingSoonImg});
            background-size: cover;
            position: absolute;
            right: -5px;
            top: 15px;
        }
    }
`

const ReleasedNotesBadge = styled.div`
    margin-left: 0.25rem;
`

interface ActionCardProps {
    data: CardData
    onCardClick: (destination: string) => void
    isReleaseNotes?: boolean
}

export function ActionCard(props: ActionCardProps) {
    const { title, text, icon, destination } = props.data

    return (
        <>
            {!!destination ? (
                <ActionCardItem onClick={() => props.onCardClick(destination)}>
                    <Row>
                        <IconColumn span={4} style={{ paddingRight: '0' }}>
                            <Icon src={icon} />
                        </IconColumn>
                        <Col span={8} style={{ paddingLeft: '25px' }}>
                            <Title>
                                {title}
                                {props.isReleaseNotes && (
                                    <ReleasedNotesBadge dangerouslySetInnerHTML={{ __html: RELEASED_BADGE }} />
                                )}
                                <span className="arrow">
                                    <FontAwesomeIcon icon={faLongArrowRight as IconProp} />
                                </span>
                            </Title>
                            <Text>{text}</Text>
                        </Col>
                    </Row>
                </ActionCardItem>
            ) : (
                <ActionCardItem onClick={() => {}} className="comingSoon">
                    <Row>
                        <IconColumn span={4} style={{ paddingRight: '0' }}>
                            <Icon src={icon} />
                        </IconColumn>
                        <Col span={8}>
                            <Title>{title}</Title>
                            <Text>{text}</Text>
                        </Col>
                    </Row>
                </ActionCardItem>
            )}
        </>
    )
}
