import React from 'react'
import { TaxConfiguration } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import './css/taxConfigurationElement.scss'
import classNames from 'classnames'
import { useHasFeature } from 'features'
import { formatTaxType } from '.'

export interface ReadOnlyTaxConfigurationElementProps {
    taxConfiguration: TaxConfiguration
    isDefault: boolean
}

export const ReadOnlyTaxConfigurationElement = ({
    taxConfiguration,
    isDefault,
}: ReadOnlyTaxConfigurationElementProps) => {
    const displayTaxTypes = useHasFeature('TaxConfigurationsDisplayTaxTypes')
    const displayProportion = useHasFeature('TaxConfigurationsDisplayProportions')

    return (
        <div className={`tax-configuration-container read-only${isDefault ? ' default' : ''}`}>
            <div className="name" title={taxConfiguration.name}>
                <div className="field">{taxConfiguration.name}</div>
            </div>
            {isDefault && (
                <div className="default-tax-configuration-badge">
                    Default
                    <FontAwesomeIcon icon={faCheckCircle as IconProp} style={{ marginLeft: '0.5rem' }} />
                </div>
            )}
            <table>
                <tbody>
                    <tr>
                        <th className="tax-name">Name</th>
                        <th className={classNames('tax-type', { hidden: !displayTaxTypes })}>Tax type</th>
                        <th className="tax-rate">Tax rate</th>
                        <th className={classNames('tax-proportion', { hidden: !displayProportion })}>Proportion</th>
                    </tr>
                    {taxConfiguration.taxes.map((tax, index) => (
                        <tr key={index}>
                            <td className="tax-name" title={tax.name}>
                                <div className="field">{tax.name}</div>
                            </td>
                            <td className={classNames('tax-type', { hidden: !displayTaxTypes })}>
                                <div className="field">{formatTaxType(tax.taxType)}</div>
                            </td>
                            <td className="tax-rate">
                                <div className="field">{tax.taxPercentage}</div>
                                <div className="input-suffix">%</div>
                            </td>
                            <td className={classNames('tax-proportion', { hidden: !displayProportion })}>
                                {tax.proportionPercentage}%
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ReadOnlyTaxConfigurationElement
