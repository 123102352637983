import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { BaseRouteParams, useNavigation } from 'hocs'
import { DateFormatter } from 'uiComponents/date'
import Money from 'uiComponents/money'
import { LoadDataParams, ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import { formatTableState } from 'uiComponents/table/reactTable/tableState'
import { DiscountCode } from 'venue/bookingCodes/bookingCodesService'
import { useLazyListDiscountCodesCursorPaginatedQuery } from 'venue/reduxQuery'
import AffectsCell from '../_shared/affectsCell'
import AffectedOrders from '../_shared/affectedOrders'
import UsageCount from '../_shared/usageCount'
import CodeStatus from '../Pools/codeStatus'
import DiscountReservoirName from '../_shared/discountReservoirName'
import { DateFormats } from 'utils'

const emptyArray = [] as any[]

const Codes = () => {
    const [fetchCodesCursorPaginated, fetchCodesCursorPaginatedResponse] =
        useLazyListDiscountCodesCursorPaginatedQuery()
    const { accountSlug } = useParams<BaseRouteParams>()
    const navigation = useNavigation()
    const { search, searchBy } = navigation.query()
    const theme = useTheme()
    const searchRef = React.useRef(search)

    const columns = useMemo(() => {
        return [
            {
                accessor: 'code',
                Header: 'Code',
                Cell: ({ value }) => <span title={value}>{value}</span>,
            },
            {
                accessor: 'products',
                Header: 'Affects',
                disableSortBy: true,
                width: '6.25rem',
                Cell: ({ value }) => <AffectsCell productIds={value} />,
            },
            {
                accessor: 'status',
                Header: 'Redemption',
                disableSortBy: true,
                width: '7.5rem',
                Cell: ({ value }) => <CodeStatus status={value} />,
            },
            {
                accessor: 'usage',
                Header: 'Used count',
                disableSortBy: true,
                width: '7rem',
                Cell: ({ value }) => <UsageCount usage={value} />,
            },
            {
                accessor: 'validity',
                Header: 'Validity',
                disableSortBy: true,
                width: '14.5rem',
                Cell: ({ value }) => {
                    if (!value) {
                        return <>Unlimited</>
                    }

                    return (
                        <>
                            <DateFormatter value={value.fromDate} format={DateFormats.LONG_DATE} />
                            &nbsp;-&nbsp;
                            <DateFormatter value={value.toDate} format={DateFormats.LONG_DATE} />
                        </>
                    )
                },
            },
            {
                accessor: 'discount',
                Header: 'Value',
                disableSortBy: true,
                width: '6.25rem',
                Cell: ({ value }) => {
                    if (value.type === 'DiscountType.PERCENTAGE') {
                        return <>{value.amount}%</>
                    }

                    return <Money amount={value.amount} accountSlug={accountSlug} />
                },
            },
            {
                accessor: 'orders',
                Header: 'Order ID',
                disableSortBy: true,
                width: '8rem',
                Cell: ({ value, row: { original } }) => <AffectedOrders code={original.code} orders={value} />,
            },
            {
                accessor: 'discount_reservoir_name',
                Header: 'Pool name',
                width: '16rem',
                Cell: ({ row: { original } }) => (
                    <DiscountReservoirName discountReservoirName={original.discountReservoirName} />
                ),
            },
        ] as TableColumn<DiscountCode>[]
    }, [])

    const loadData = useCallback(
        (params: LoadDataParams) => {
            delete params.pagination.page
            const isSearchChanging = search !== searchRef.current
            const query = formatTableState.toQueryFromLoadData(params, {
                include: ['orders', 'products'],
                search,
                search_by: searchBy,
                offset: isSearchChanging ? '0' : params.token ?? '0',
            })

            fetchCodesCursorPaginated({ accountSlug, query })
            searchRef.current = search
        },
        [fetchCodesCursorPaginated, accountSlug, search, searchBy],
    )

    return (
        <ReactTable
            elevation={false}
            pagination={{
                next: fetchCodesCursorPaginatedResponse?.data?.next,
                previous: fetchCodesCursorPaginatedResponse?.data?.previous,
            }}
            sort={{ prop: 'code', direction: 'asc' }}
            loadData={loadData}
            rowProps={() => ({
                style: {
                    borderBottom: `1px solid ${theme.colors.aluminiumShades[5]}`,
                },
            })}
            loading={fetchCodesCursorPaginatedResponse.isFetching}
            columns={columns}
            totalCount={fetchCodesCursorPaginatedResponse.data?.results.length}
            data={fetchCodesCursorPaginatedResponse.data?.results || emptyArray}
        />
    )
}

export default Codes
