import React from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useUpdateTicketValidToDateMutation } from 'orders/reduxQueries'
import { getLowestValidToDay } from 'orders/utils/getLowestValidToDay'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { PermissionProps, withPermission } from 'admin/hocs'
import { IOrder, IOrderItems } from 'orders/schema'
import { LoaderWrapper } from 'uiComponents/loaders'
import { useHasFeature } from 'utils/useHasFeature'
import UpdateDialog from './updateDialog'
import { BaseRouteParams, ReplaceMessagesFunc } from 'hocs'
import { SerializedError } from '@reduxjs/toolkit'
import { DateFormats, parseDate } from 'utils/dates'

interface Props extends PermissionProps {
    orderItem: IOrderItems
    order: IOrder | null
    status: string
    replaceMessages: ReplaceMessagesFunc
    hideMessage: (id: string) => void
}

const ValidTo: React.FC<Props> = ({ orderItem, order, status, hasPermission, replaceMessages, hideMessage }) => {
    const extendOrderItemValidityFeatureFlag = useHasFeature({ featureName: 'extendOrderItemValidity' })
    const { accountSlug } = useParams<BaseRouteParams>()
    const [updateValidToDate, { isLoading, isError, error, isSuccess }] = useUpdateTicketValidToDateMutation()
    const [showUpdateDialog, setShowUpdateDialog] = React.useState(false)
    const [newDate, setNewDate] = React.useState<Date>()

    React.useEffect(() => {
        if (isError) {
            const responseError = error as SerializedError
            replaceMessages(
                'update_error',
                'error',
                responseError.message ? responseError.message : 'Error updating valid to date',
            )
            setTimeout(() => {
                hideMessage('update_error')
            }, 5000)
        } else if (isSuccess) {
            replaceMessages('successful_update', 'success', 'Valid to date updated successfully')
            setTimeout(() => {
                hideMessage('successful_update')
            }, 5000)
        }
    }, [isError, isSuccess])

    const onConfirm = async () => {
        if (newDate) {
            await updateValidToDate({
                accountSlug,
                orderItemUuid: orderItem.orderItemId,
                validTo: format(newDate, 'yyyy-MM-dd'),
            })
        }

        setShowUpdateDialog(false)
    }

    if (!orderItem.validTo) {
        return <>-</>
    }

    const validityCanBeEdited =
        extendOrderItemValidityFeatureFlag &&
        hasPermission('edit_orders', accountSlug) &&
        status !== 'refunded' &&
        status !== 'refunding' &&
        !orderItem.visitDate

    return (
        <LoaderWrapper loading={isLoading}>
            {showUpdateDialog && (
                <UpdateDialog
                    accountSlug={accountSlug}
                    orderItemUuid={orderItem.orderItemId}
                    order={order}
                    newDate={newDate!}
                    onCancel={() => {
                        setShowUpdateDialog(false)
                    }}
                    onConfirm={onConfirm}
                    replaceMessages={replaceMessages}
                />
            )}
            <DatePicker
                date={orderItem.validTo ? parseDate(orderItem.validTo) : null}
                inlineEdit
                modalDatePicker
                lowerBoundary={getLowestValidToDay({
                    validFrom: orderItem.validFrom,
                    validTo: orderItem.validTo,
                })}
                onInlineEditAccept={(newDate) => {
                    setNewDate(newDate)
                    setShowUpdateDialog(true)
                }}
                disabled={!validityCanBeEdited}
                inlineEditDateFormat={DateFormats.LONG_DATE}
            />
        </LoaderWrapper>
    )
}

export default withPermission(ValidTo)
