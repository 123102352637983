import * as React from 'react'
import { State } from 'store'
import { connect } from 'react-redux'
import { Account } from 'auth/state'
import { Sorting, Pagination } from 'uiComponents/table'
import { Messages } from 'uiComponents/messages'
import { withMessages, MessageProps } from 'hocs'
import { DateRange } from 'dateRanges'
import DetailsTable from './detailsTable'
import { OrdersService } from 'orders/ordersService'
import { cancelOutOffsetBeforeConvertionToUTC } from 'utils'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { OrderDetailsPage, defaultPageData } from 'orders/schema'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation, renderSearch } from 'navigation'
import { History } from 'history'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { PaginationSection } from 'uiComponents/table/pagination'
import { UserField, UpdateTicketValidToDetails } from 'orders/schema'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import PageActionsSection from './pageActionsSection'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import { UpdateDialog } from 'orders/details/updateDialog'
import { format } from 'date-fns'
import OrdersDetailPageHeading from './pageHeading'
import styled from 'styled-components'

interface OrdersDetailsPageProps {
    search?: string
    dateRange: DateRange
    onDateRangeChanged: (dr: DateRange) => void
    dateRangeType: DateRangeType
    onDateRangeTypeChange: (type: DateRangeType) => void
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    pagination: Pagination
    onPaginationChanged: (p: Pagination) => void
    accountSlug: string
    ordersService: OrdersService
    loginService: LoginService
    loggingService: LoggingService
    navigation: Navigation
    match: RouteMatch<{}>
    history: History
    accounts: Account[]
    backofficeEndpoint: string
}

interface OrdersDetailsPageState {
    ordersPageData: OrderDetailsPage
    selectedBarcodes: string[]
    allBarcodesSelected: boolean
    updateValidToDetails: UpdateTicketValidToDetails | null
    showRedeemDialog: boolean
    showUpdateDialog: boolean
    loading: boolean
}

const DatePickerWrapper = styled.div`
    position: relative;
    float: right;
    top: 4rem;
`

class OrdersDetailsPage extends React.Component<OrdersDetailsPageProps & MessageProps, OrdersDetailsPageState> {
    constructor(props: OrdersDetailsPageProps & MessageProps) {
        super(props)
        this.state = {
            ordersPageData: defaultPageData,
            selectedBarcodes: [],
            allBarcodesSelected: false,
            updateValidToDetails: null,
            showRedeemDialog: false,
            showUpdateDialog: false,
            loading: false,
        }
    }

    async componentDidMount() {
        await this.loadOrdersList()
    }

    async componentDidUpdate(prevProps: OrdersDetailsPageProps) {
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        if (
            !areDateRangeDatesEqual(prevProps.dateRange, this.props.dateRange) ||
            prevProps.accountSlug !== this.props.accountSlug ||
            query.page !== prevQuery.page ||
            query.pageSize !== prevQuery.pageSize ||
            query.sortBy !== prevQuery.sortBy ||
            query.sortDirection !== prevQuery.sortDirection ||
            query.dateRangeType !== prevQuery.dateRangeType ||
            query.q !== prevQuery.q ||
            (!!query.q && query.searchType !== prevQuery.searchType) ||
            query.filter !== prevQuery.filter
        ) {
            await this.loadOrdersList()
        }
    }

    loadOrdersList = async () => {
        this.setState({ loading: true })
        await this.getOrdersList()
    }

    getOrdersList = async () => {
        try {
            const { sort, pagination, dateRange, dateRangeType, search, accountSlug } = this.props
            const query = this.props.navigation.query()
            const data = await this.props.ordersService.getOrderDetailsList(
                accountSlug,
                dateRange.from,
                dateRange.to,
                dateRangeType,
                search,
                sort.prop,
                sort.direction,
                pagination.page,
                pagination.pageSize,
                query.searchType === 'extended' ? 'extended' : 'simple',
                query.filter,
            )
            this.setState({
                ordersPageData: data.barcodes,
                selectedBarcodes: [],
                allBarcodesSelected: false,
                loading: false,
            })
            this.updateSelectAllToggle()
        } catch {
            this.props.replaceMessages(
                'server_error',
                'error',
                'Oops! Orders table could not be loaded, please try again later.',
            )
            this.setState({ loading: false })
        }
    }

    updateSelectAllToggle = () => {
        const allBarcodesSelected = this.checkAllBarcodesSelected(this.state.selectedBarcodes)
        this.setState({ allBarcodesSelected })
    }

    toggleSelectAllBarcodes = () => {
        const updatedList = this.state.allBarcodesSelected ? [] : this.getAllBarcodesList()
        this.setState({
            allBarcodesSelected: !this.state.allBarcodesSelected,
            selectedBarcodes: updatedList,
        })
    }

    getAllBarcodesList = () => {
        return this.state.ordersPageData
            ? this.state.ordersPageData.entries
                  .filter((b) => !!b.barcode && b.status !== 'refunded' && b.status !== 'refunding')
                  .map((b) => b.barcode)
            : []
    }

    onSelectBarcode = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.hideMessage('invalid_visit-date')
        const barcode = event.target.name
        const updatedSelection = [...this.state.selectedBarcodes]
        if (updatedSelection.indexOf(barcode) > -1) {
            updatedSelection.splice(updatedSelection.indexOf(barcode), 1)
        } else {
            updatedSelection.push(barcode)
        }
        const allBarcodesSelected = this.checkAllBarcodesSelected(updatedSelection)
        this.setState({ selectedBarcodes: updatedSelection, allBarcodesSelected })
    }

    checkAllBarcodesSelected = (selectedBarcodes: string[]) => {
        return selectedBarcodes.length > 0 && selectedBarcodes.length === this.getAllBarcodesList().length
    }

    onRedeemComplete = async () => {
        await this.loadOrdersList()
        this.setState({ selectedBarcodes: [], allBarcodesSelected: false })
    }

    updateApfItem = async (barcode: string, userField: UserField, value: string) => {
        if (userField.isRequired && !value) {
            this.props.replaceMessages('required_user_field', 'error', `The field ${userField.label} is required`)
            return
        }
        this.setState({ loading: true })
        try {
            await this.props.ordersService.updateApfField(this.props.accountSlug, barcode, userField.name, value)
            await this.getOrdersList()
        } catch {
            this.props.replaceMessages(
                'user_field',
                'error',
                'Oops! There was a problem with saving new user field value. Please try again.',
            )
        }
        this.setState({ loading: false })
    }

    saveNewValidToDate = (orderId: string, orderItemId: string, date: Date, bundleOrderItemId: string) => {
        if (!this.state.showUpdateDialog) {
            this.setState({
                showUpdateDialog: true,
                updateValidToDetails: { bundleOrderItemId, orderId, orderItemId, date },
            })
        }
    }

    onConfirmUpdate = async () => {
        if (!this.state.updateValidToDetails) {
            return
        }
        try {
            this.setState({ loading: true })
            await this.props.ordersService.updateTicketValidToDate({
                account: this.props.accountSlug,
                orderItemId: this.state.updateValidToDetails.orderItemId,
                validTo: format(this.state.updateValidToDetails.date, 'yyyy-MM-dd'),
            })
            await this.getOrdersList()
        } catch {
            this.props.replaceMessages(
                'valid_to',
                'error',
                'Oops! There was a problem with saving new ticket valid to date. Please try again.',
            )
        } finally {
            this.setState({
                loading: false,
                showUpdateDialog: false,
                updateValidToDetails: null,
            })
        }
    }

    closeDialog = async () => {
        this.setState({ loading: true })
        await this.getOrdersList()
        this.setState({
            showUpdateDialog: false,
            updateValidToDetails: null,
            loading: false,
        })
    }

    backToTransactions = () => {
        const query = this.props.navigation.query()
        query.sortBy = query.prevSortBy
        query.sortDirection = query.prevSortDirection
        query.q = query.prevQ
        query.page = query.prevPage
        delete query.prevSortBy
        delete query.prevSortDirection
        delete query.prevQ
        delete query.prevPage
        delete query.backTo
        const search = renderSearch(query)
        this.props.history.push(`/account/${this.props.accountSlug}/orders/transactions${search}`)
    }

    render() {
        const {
            sort,
            onSortChanged,
            dateRange,
            search,
            accountSlug,
            ordersService,
            loginService,
            loggingService,
            replaceMessages,
            hideMessage,
            accounts,
            pagination,
            onPaginationChanged,
        } = this.props
        const {
            loading,
            ordersPageData,
            selectedBarcodes,
            allBarcodesSelected,
            showRedeemDialog,
            showUpdateDialog,
            updateValidToDetails,
        } = this.state

        const query = this.props.navigation.query()
        const showNavigationBack = query.backTo

        const dateFrom = cancelOutOffsetBeforeConvertionToUTC(dateRange.from).toISOString()
        const dateTo = cancelOutOffsetBeforeConvertionToUTC(dateRange.to).toISOString()

        const exportQuery = {
            search,
            widget_slug: accountSlug,
            date_from: dateFrom,
            date_to: dateTo,
            date_range_type: this.props.dateRangeType,
            sort_by: sort.prop,
            sort_direction: sort.direction,
            filters: query.filter,
        }

        const orderDetailsEntries = ordersPageData.entries
        const activeAccount = accounts.find((x) => x.slug === accountSlug)
        const withTimeSlots = activeAccount ? activeAccount.timeSlotsEnabled : false

        return (
            <>
                <Messages messages={this.props.messages} hideMessage={this.props.hideMessage} />
                {!!showUpdateDialog && !!updateValidToDetails && (
                    <UpdateDialog
                        accountSlug={accountSlug}
                        updateValidToDetails={updateValidToDetails}
                        ordersService={ordersService}
                        onCancel={this.closeDialog}
                        onConfirm={this.onConfirmUpdate}
                        replaceMessages={replaceMessages}
                        loading={loading}
                    />
                )}
                <DatePickerWrapper>
                    <DateRangePicker
                        range={dateRange}
                        onChange={this.props.onDateRangeChanged}
                        dateRangeType={this.props.dateRangeType}
                        onDateRangeTypeChange={this.props.onDateRangeTypeChange}
                        allowFutureDateSelection
                    />
                </DatePickerWrapper>
                <OrdersDetailPageHeading account_slug={accountSlug} />
                <PageActionsSection
                    search={search}
                    dateRange={dateRange}
                    accountSlug={accountSlug}
                    loginService={loginService}
                    loggingService={loggingService}
                    ordersService={ordersService}
                    replaceMessages={replaceMessages}
                    hideMessage={hideMessage}
                    onRedeemComplete={this.onRedeemComplete}
                    selectedBarcodes={selectedBarcodes}
                    ordersPageData={ordersPageData}
                    exportQuery={exportQuery}
                    showRedeemDialog={showRedeemDialog}
                    loading={loading}
                    refreshTableData={this.getOrdersList}
                    backofficeEndpoint={this.props.backofficeEndpoint}
                />
                {showNavigationBack && (
                    <NavigationBack onClick={this.backToTransactions} text="Back to transactions" topOffset="-2.5em" />
                )}
                <DetailsTable
                    orderDetails={orderDetailsEntries}
                    userFieldConfig={ordersPageData.userFieldConfig}
                    loading={loading}
                    sort={sort}
                    onSortChanged={onSortChanged}
                    ordersService={ordersService}
                    loggingService={loggingService}
                    accountSlug={accountSlug}
                    reloadOrderList={this.getOrdersList}
                    replaceMessages={this.props.replaceMessages}
                    removeAllMessages={this.props.removeAllMessages}
                    allBarcodesSelected={allBarcodesSelected}
                    toggleSelectAllBarcodes={this.toggleSelectAllBarcodes}
                    onSelectBarcode={this.onSelectBarcode}
                    selectedBarcodes={selectedBarcodes}
                    updateApfItem={this.updateApfItem}
                    saveNewValidToDate={this.saveNewValidToDate}
                    withTimeSlots={withTimeSlots}
                />
                <PaginationSection
                    pagination={pagination}
                    onPaginationChanged={onPaginationChanged}
                    totalItemsCount={ordersPageData.totalCount}
                />
            </>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default withNavigation(withMessages(connect(mapStateToProps)(OrdersDetailsPage)))
