import * as React from 'react'
import styled from 'styled-typed'
import { Col } from 'uiComponents/flex'

export const FormTogglerCol = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const FormToggler = styled(Toggle)`
    font-size: 0.875em;
    &.disabled {
        opacity: 0.5;
    }
`

interface ToggleProps {
    isOn: boolean
    disabled?: boolean
}

const ToggleSlider = styled.div<ToggleProps>`
    position: relative;
    top: 0.15em;
    left: ${(props) => (props.isOn ? '1.9em' : '.2em')};
    height: 1.3em;
    width: 1.3em;
    border-radius: 4.15em;
    background: #fff;
    transition: left 0.15s ease-out;
`

const ToggleWrapper = styled.div<ToggleProps>`
    position: relative;
    height: 1.6em;
    width: 3.4em;
    border-radius: 4.15em;
    background: ${(props) => (props.isOn ? props.theme.colors.boyBlue : props.theme.colors.textLight)};
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
    transition: background 0.15s ease-out;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    &.smallInlineToggle {
        display: inline-block;
        width: 1.75rem;
        height: 0.875rem;
        border-radius: 1.25rem;

        ${ToggleSlider} {
            width: 0.625rem;
            height: 0.625rem;
            border-radius: 1.25rem;
            top: 0.125rem;
            left: ${(props) => (props.isOn ? '1rem' : '0.125rem')};
        }
    }
`

export interface ToggleComponentProps {
    className?: string
    id?: string
    isOn: boolean
    onClick: (value: boolean, e?: React.ChangeEvent<any>) => void
    disabled?: boolean
    style?: React.CSSProperties
    title?: string
}

export function Toggle(props: ToggleComponentProps) {
    function onToggleClick(e: React.ChangeEvent<any>) {
        if (!props.disabled) {
            props.onClick(!props.isOn)
            e.stopPropagation()
        }
    }
    return (
        <ToggleWrapper
            className={props.className}
            id={props.id}
            isOn={props.isOn}
            onClick={onToggleClick}
            data-checked={props.isOn}
            disabled={props.disabled}
            style={props.style}
            title={props.title}
        >
            <ToggleSlider isOn={props.isOn} />
        </ToggleWrapper>
    )
}

const PeristentToggleWrapper = styled(ToggleWrapper)`
    background: ${(props) => props.theme.colors.boyBlue};
`

interface PersistentToggleProps {
    id?: string
    right: boolean
    onClick: (value: boolean) => void
    disabled?: boolean
    style?: React.CSSProperties
}

export function PersistentToggle(props: PersistentToggleProps) {
    function onToggleClick() {
        if (!props.disabled) {
            props.onClick(!props.right)
        }
    }

    return (
        <PeristentToggleWrapper
            style={props.style}
            id={props.id}
            isOn={props.right}
            onClick={onToggleClick}
            data-checked={props.right}
            disabled={props.disabled}
        >
            <ToggleSlider isOn={props.right} />
        </PeristentToggleWrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    height: 2.5rem;
    font-size: 0.875rem;
    flex-direction: row;
    width: fit-content;
`

interface SideProps {
    active: boolean
    side: 'left' | 'right'
}
const Side = styled.div<SideProps>`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => (p.active ? p.theme.colors.boyBlue : p.theme.colors.white)};
    color: ${(p) => (p.active ? p.theme.colors.white : p.theme.colors.boyBlue)};
    ${(p) => (!p.active ? 'cursor: pointer;' : '')}
    border: 2px solid ${(p) => p.theme.colors.boyBlue};
    ${(p) => (p.side === 'right' ? 'border-left: 0;' : 'border-right: 0;')}
    padding: 0 0.8em;
    border-radius: ${(p) => (p.side === 'right' ? '0 .7em .7em 0' : '.7em 0 0 .7em')};
`
interface ToggleOption {
    name: string
    value: string
}

interface ToggleWithValuesProps {
    id?: string
    options: ToggleOption[]
    onClick: (v: string) => void
    selected: string
    style?: React.CSSProperties
}

export function ToggleWithValues(props: ToggleWithValuesProps) {
    const { options, onClick, selected } = props
    return (
        <>
            {options.length > 1 && (
                <Wrapper style={props.style} id={props.id}>
                    <Side
                        onClick={() => {
                            onClick(options[0].value)
                        }}
                        active={options[0].value === selected}
                        side="left"
                    >
                        {options[0].name}
                    </Side>
                    <Side
                        onClick={() => {
                            onClick(options[1].value)
                        }}
                        active={options[1].value === selected}
                        side="right"
                    >
                        {options[1].name}
                    </Side>
                </Wrapper>
            )}
        </>
    )
}
