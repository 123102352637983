import * as React from 'react'
import styled from 'styled-typed'
import { ModalDialog } from 'uiComponents/popups/modal'
import { ActionButton } from 'uiComponents/buttons/'
import { BrowserBox, WonderBar, ModalWindow } from 'uiComponents/signUpContainer/productCard'
import { productTitles } from 'engageTools/studio/shared'
import { ProductName } from 'engageTools/studio/schema'

const ModalDialogBody = styled.div`
    text-align: center;
    font-weight: lighter;
    padding: 0 3em;
    width: 400px;
`

interface SuccessDialogProps {
    onButtonClick: (e: React.MouseEvent<any>) => void
    product: ProductName
    draftSaved?: boolean
}

export function SuccessDialog(props: SuccessDialogProps) {
    const cardComponent = props.product === 'wonderbar' ? <WonderBar /> : <ModalWindow />
    return (
        <ModalDialog>
            <ModalDialogBody>
                <BrowserBox>{cardComponent}</BrowserBox>
                <div style={{ marginBottom: '0.5em' }}>
                    <strong>Congratulations</strong>
                </div>
                <div style={{ marginBottom: '2.2em' }}>
                    {props.draftSaved ? (
                        'Your draft has been successfully saved.'
                    ) : (
                        `Your ${productTitles[props.product]} is live!`
                    )}
                </div>
                <ActionButton id="go-to-summary-button" size="large" onClick={props.onButtonClick}>
                    Go to summary
                </ActionButton>
            </ModalDialogBody>
        </ModalDialog>
    )
}
