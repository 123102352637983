import * as yup from 'yup'

export interface Tax {
    name: string
    taxPercentage: number
    proportionPercentage: number
    taxType: string
    uuid: string
}

export interface TaxConfiguration {
    accountSlug: string
    name: string
    taxes: Tax[]
    uuid: string
}

export interface TaxConfigurationsResponse {
    taxConfigurations: TaxConfiguration[]
    defaultTaxConfigurationUuid: string
}

export const taxConfigurationFormValidationSchema = yup.object().shape({
    name: yup.string().required(),
    taxes: yup
        .array()
        .of(
            yup.object().shape({
                name: yup.string().required(),
                taxPercentage: yup.number().required().min(0).max(100),
                proportionPercentage: yup.number().required().min(0).max(100),
            }),
        )
        .required(),
})
