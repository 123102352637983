import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLazyGetOrderDetailsByUuidQuery, usePatchOrderMutation } from 'orders/reduxQueries'
import { getActiveAccount } from 'preferences/selectors'
import { useAppSelector } from 'store/hooks'
import Comment from 'uiComponents/comment/comment'

type OrderCommentProps = {
    orderId: string
}

const OrderComment: FC<OrderCommentProps> = ({ orderId }) => {
    const accountSlug = useAppSelector(getActiveAccount)
    const [getOrderById, { data: order, isFetching }] = useLazyGetOrderDetailsByUuidQuery()
    const [patchOrder] = usePatchOrderMutation()
    const [comment, setComment] = useState<{ content: string; uuid?: string }>()

    useEffect(() => {
        getOrderById({ uuid: orderId, include: ['comment'] })
    }, [orderId, getOrderById])

    useEffect(() => {
        if (order?.comments?.length) {
            const { comments } = order
            const lastComment = comments[comments.length - 1]
            setComment(lastComment)
        }
    }, [order])

    const handleSetComment = useCallback(
        async (content: string) => {
            if (!accountSlug) throw new Error('No account slug found')

            setComment((prev) => ({ ...prev, content }))
            await patchOrder({
                orderId,
                overrides: {
                    comment: {
                        content,
                        ...(comment?.uuid ? { uuid: comment.uuid } : {}),
                    },
                },
            })
            getOrderById({ uuid: orderId, include: ['comment'] })
        },
        [comment],
    )

    return <Comment comment={comment} isFetching={isFetching} onHandleSetComment={handleSetComment} />
}

export default OrderComment
