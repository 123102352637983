import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLazyGetCartQuery, usePatchCartMutation } from 'orders/reduxQueries'
import { getActiveAccount } from 'preferences/selectors'
import { useAppSelector } from 'store/hooks'
import Comment from 'uiComponents/comment/comment'

type CartCommentProps = {
    cartId: string
}

const CartComment: FC<CartCommentProps> = ({ cartId }) => {
    const accountSlug = useAppSelector(getActiveAccount)
    const [getCart, { data, isFetching }] = useLazyGetCartQuery()
    const [patchCart] = usePatchCartMutation()
    const [comment, setComment] = useState<{ content: string; uuid?: string }>()

    useEffect(() => {
        if (!accountSlug) throw new Error('No account slug found')
        getCart({ accountSlug, id: cartId })
    }, [cartId, getCart, accountSlug])

    useEffect(() => {
        if (data?.comments?.length) {
            const { comments } = data
            const lastComment = comments[comments.length - 1]
            setComment(lastComment)
        }
    }, [data])

    const handleSetComment = useCallback(
        async (content: string) => {
            if (!accountSlug) throw new Error('No account slug found')

            setComment((prev) => ({ ...prev, content }))
            await patchCart({
                accountSlug,
                cartId,
                overrides: {
                    comment: {
                        content,
                        ...(comment?.uuid ? { uuid: comment.uuid } : {}),
                    },
                },
            })
            getCart({ accountSlug, id: cartId })
        },
        [comment],
    )

    return <Comment comment={comment} isFetching={isFetching} onHandleSetComment={handleSetComment} />
}

export default CartComment
