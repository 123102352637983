import * as React from 'react'
import styled from 'styled-typed'
import { DateRange } from 'dateRanges'
import { format, isEqual } from 'date-fns'
import Money from 'uiComponents/money'
import { DashboardTheme } from 'theme'
import { QueryData } from 'navigation'
import { dateRangeFromQuery } from 'dateRanges'
import { parseFilterQuery } from 'uiComponents/filter'
import { XAxisData } from 'uiComponents/charts/timeseriesBarChartSource'
import {
    DataPoint,
    TimeSeries,
    DataTotal,
    DownloadHeader,
    RawDataSeries,
    ExportsDataPoint,
    FilterQueryResult,
} from 'reports/schema'
import { Filters } from 'uiComponents/filter/schema'
import { roundNumber, slugifyName, DateFormats, parseISODate, addSeparators } from 'utils'
import { flatMap } from 'utils'
import { Body } from 'uiComponents/typography'
import { get } from 'lodash'

export const ReportsToolsWrapper = styled.div`
    position: relative;
    min-height: 2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
`

export const chartRowsGap = '1.9em'

export const revenuePageMetricMap = {
    revenue: 'Revenue',
    transactions: 'Transactions',
    products_sold: 'Products sold',
}

interface ReportUpdateInfoProps {
    metric: string
    text: string
    style?: React.CSSProperties
}

const UpdateInfoContainer = styled.div`
    color: ${(props) => props.theme.colors.textLight};
    display: flex;
    margin-bottom: 0.5em;
`

export function ReportUpdateInfo(props: ReportUpdateInfoProps) {
    return (
        <UpdateInfoContainer style={props.style}>
            <Body size={2} weight="medium">
                {props.metric}:
            </Body>
            <Body size={2}>&nbsp;{props.text}&nbsp;</Body>
        </UpdateInfoContainer>
    )
}

interface WrapperProps {
    children: React.ReactNode
    total: string
    discounts?: string | null
    collected?: string | null
    reseller?: string | null
    redeemed?: string
    notRedeemed?: string
    withCurrency?: boolean
    accountSlug?: string
    id?: string
    style?: React.CSSProperties
    title: string
}

const Total = styled.div`
    font-size: 1.25em;
    position: absolute;
    right: 1em;
    top: 0.3em;
    display: flex;
    align-items: baseline;
`

const TotalOptional = styled.div`
    margin-left: 0.5em;
    font-size: 0.85em;
    opacity: 0.6;
`

export function ChartWrapperWithTotals(props: WrapperProps) {
    return (
        <div style={{ position: 'relative' }}>
            {props.title && <h4 style={{ marginBottom: '0' }}>{props.title}</h4>}
            {props.withCurrency && props.accountSlug ? (
                <Total id={props.id} style={props.style}>
                    <div>
                        Total:
                        <span style={{ marginLeft: '.3em' }}>
                            <Money amount={props.total} accountSlug={props.accountSlug} />
                        </span>
                    </div>
                    {props.discounts && (
                        <TotalOptional>
                            (Discounts:
                            <span style={{ marginLeft: '.3em' }}>
                                <Money amount={props.discounts} accountSlug={props.accountSlug} />
                            </span>
                        </TotalOptional>
                    )}
                    {props.collected && (
                        <TotalOptional>
                            Collected:
                            <span style={{ marginLeft: '.3em' }}>
                                <Money amount={props.collected} accountSlug={props.accountSlug} />
                                {props.reseller ? '' : ')'}
                            </span>
                        </TotalOptional>
                    )}
                    {props.reseller && (
                        <TotalOptional>
                            Resellers:
                            <span style={{ marginLeft: '.3em' }}>
                                <Money amount={props.reseller} accountSlug={props.accountSlug} />)
                            </span>
                        </TotalOptional>
                    )}
                </Total>
            ) : (
                <Total id={props.id} style={props.style}>
                    <div>
                        Total:
                        <span style={{ marginLeft: '.3em' }}>{addSeparators(props.total)}</span>
                    </div>
                    {props.redeemed && (
                        <TotalOptional>
                            (Redeemed:
                            <span style={{ marginLeft: '.3em' }}>{addSeparators(props.redeemed)}</span>
                        </TotalOptional>
                    )}
                    {props.notRedeemed && (
                        <TotalOptional>
                            Not redeemed:
                            <span style={{ marginLeft: '.3em' }}>{addSeparators(props.notRedeemed)})</span>
                        </TotalOptional>
                    )}
                </Total>
            )}
            {props.children}
        </div>
    )
}

interface DateRangeWrapperProps {
    children: React.ReactNode
    dateRange: DateRange
    userpilot?: string
}

export function ChartWrapperWithDateRange(props: DateRangeWrapperProps) {
    return (
        <div data-userpilot={props.userpilot} style={{ position: 'relative' }}>
            {props.dateRange.from && props.dateRange.to && (
                <span className="text-tertiary">
                    {format(props.dateRange.from, DateFormats.LONG_DATE)} -{' '}
                    {format(props.dateRange.to, DateFormats.LONG_DATE)}
                </span>
            )}
            {props.children}
        </div>
    )
}

interface AxisTitleProps {
    title: string
    left?: string
    right?: string
    bottom?: string
    top?: string
    style?: React.CSSProperties
    className?: string
}

const AxisTitleContainer = styled.div<{
    left?: string
    right?: string
    bottom?: string
    top?: string
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.7em;
    font-weight: 500;
    color: ${(props) => props.theme.colors.textLight};
    position: absolute;
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};

    &.sankey-chart {
        color: ${(props) => props.theme.colors.textDark};
    }
`

export function AxisTitle(props: AxisTitleProps) {
    return (
        <AxisTitleContainer
            left={props.left}
            right={props.right}
            top={props.top}
            bottom={props.bottom}
            style={props.style}
            className={props.className}
        >
            {props.title}
        </AxisTitleContainer>
    )
}

export const comparisonCopyMap = {
    today: {
        comparisonText: 'WoW',
        infotipStart: 'Today',
        infotipEnd: 'same day last week',
    },
    yesterday: {
        comparisonText: 'WoW',
        infotipStart: 'Yesterday',
        infotipEnd: 'same day last week',
    },
    thisWeek: {
        comparisonText: 'WoW',
        infotipStart: 'This week',
        infotipEnd: 'previous week',
    },
    thisMonth: {
        comparisonText: 'MoM',
        infotipStart: 'This month',
        infotipEnd: 'previous month',
    },
    custom: {
        comparisonText: 'prec. period',
        infotipStart: 'This period',
        infotipEnd: 'preceding period',
    },
}

export function areDateRangeDatesEqual(dateRange1: DateRange | undefined, dateRange2: DateRange): boolean {
    if (!!dateRange1 && dateRange1.from && dateRange2.from && dateRange1.to && dateRange2.to) {
        const fromEqual = isEqual(dateRange1.from, dateRange2.from)
        const toEqual = isEqual(dateRange1.to, dateRange2.to)
        return fromEqual && toEqual
    }
    return false
}

export function sortWeekdays(data: DataTotal[]): DataTotal[] {
    const sorter = {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
        sun: 7,
    }
    if (data && data.length > 0) {
        const sorted = data.sort(function (a: DataTotal, b: DataTotal) {
            let day1 = a.name.toLowerCase()
            let day2 = b.name.toLowerCase()
            return sorter[day1] - sorter[day2]
        })
        return sorted
    }
    return []
}

export function formatWeekDay(data: DataTotal[]): DataTotal[] {
    if (data && data.length > 0) {
        const formated = data.map((d) => ({
            name: d.name.length > 3 ? d.name.slice(0, 3) : d.name,
            value: d.value,
        }))
        return formated
    }
    return []
}

export function formatWeekDayForTooltip(weekdayShort: string): string {
    const map = {
        Mon: 'Monday',
        Tue: 'Tuesday',
        Wed: 'Wednesday',
        Thu: 'Thursday',
        Fri: 'Friday',
        Sat: 'Saturday',
        Sun: 'Sunday',
    }
    return map[weekdayShort] || weekdayShort
}

export function format24HXAxis(index: number, seriesLength: number): string {
    if (index === 1) {
        return 'Midnight'
    } else if (index === 8) {
        return 'Morning'
    } else if (index === 15) {
        return 'Day'
    } else if (index === seriesLength - 2) {
        return 'Evening'
    }
    return ''
}

export function formatHour(data: DataTotal[]): DataTotal[] {
    return data.map((d) => {
        if (d.name.charAt(0) === '0') {
            d.name = d.name.substring(1)
        }
        return d
    })
}

function getThemeColor(index: number, theme: DashboardTheme) {
    return theme.colors.chartRotatingColors[index % theme.colors.chartRotatingColors.length]
}

export function mapChartDataColor(data: DataTotal[], theme: DashboardTheme): DataTotal[] {
    return data.map((d, i) => {
        const ob = Object.assign(d, {
            itemStyle: { color: getThemeColor(i, theme) },
        })
        return ob
    })
}

export const getDataFromQuery = (query: QueryData, extras?: string[]): FilterQueryResult => {
    const extraQueries = {}

    if (extras && extras.length > 0) {
        extras.forEach((extraQuery) => {
            const result = get(query, extraQuery)
            if (result) {
                const parsedResult = parseFilterQuery(result)
                extraQueries[extraQuery] = parsedResult
            }
        })
    }

    return {
        dateRange: dateRangeFromQuery(query),
        filters: query.filter ? parseFilterQuery(query.filter) : [],
        ...extraQueries,
    }
}
export const getGranularityFromQuery = (query: QueryData) => {
    return {
        granularity: query.granularity ? query.granularity : 'day',
    }
}

export const mapStringTimeseriesForTooltip = (
    xAxis: XAxisData[],
    data: ExportsDataPoint[],
    granularity: string,
    dateFormat: string = `yyyy-MM-dd ${DateFormats.LONG_TIME}`,
) => {
    return xAxis.map((p) => {
        const timestamp = format(p.category, dateFormat)
        const existing = data.find((d) => d.timestamp === timestamp)
        return {
            timestamp,
            value: existing ? existing.value : 0,
        }
    })
}

export const mapTimeseriesForTooltip = (
    xAxis: XAxisData[],
    data: DataPoint[],
    granularity: string,
    dateFormat: string = `yyyy-MM-dd ${DateFormats.LONG_TIME}`,
) => {
    if (granularity === 'customTimeseries') {
        return data.sort((a, b) => parseISODate(a.timestamp).getTime() - parseISODate(b.timestamp).getTime())
    } else {
        return xAxis.map((p) => {
            const timestamp = format(p.category, dateFormat)
            const existing = data.find((d) => format(d.timestamp, dateFormat) === timestamp)
            return {
                timestamp,
                value: existing ? existing.value : 0,
            }
        })
    }
}

export const mapGroupedTimeseriesForTooltip = (xAxis: XAxisData[], data: TimeSeries[], granularity: string = 'day') => {
    return data.map((d) => ({
        label: d.label,
        points: mapTimeseriesForTooltip(xAxis, d.points, granularity),
    }))
}

export const getTooltipXAxisLabel = (timestamp: string | Date, granularity: string) => {
    switch (granularity) {
        case 'hour':
        case 'customTimeseries':
            return format(new Date(timestamp), DateFormats.SHORT_TIME)
        case 'month':
            return format(new Date(timestamp), 'MMMM, yyyy')
        default:
            return format(new Date(timestamp), `${DateFormats.EXTRA_SHORT_DATE}, eeee`)
    }
}

export const getTooltipHeader = (label: string) => {
    return `<div style="background-color:rgba(0,0,0,0.2);position:absolute;top:0;left:0;right:0;height:30px;z-index:-1"></div>
          <div style="margin-bottom:7px;font-weight:bold;">${label}</div>
          <table style="width:100%">`
}
export const getTooltipFooter = () => {
    return '</table>'
}

export const getTooltipRow = (data: (string | number)[], bold: boolean = false) => {
    let row = `<tr ${bold ? 'style="font-weight:bold"' : ''}>`
    data.forEach((d, i) => {
        row += `<td ${
            i !== 0
                ? 'style="text-align:right;"'
                : 'style="max-width: 500px; overflow: hidden; text-overflow: ellipsis;"'
        }>${d}</td>`
    })
    row += '</tr>'
    return row
}

export const getMarker = (color: string) => {
    return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`
}

export const comparisonTextMap = {
    higher_than_usual: (
        <>
            <span className="green">Higher</span>&nbsp;than usual
        </>
    ),
    lower_than_usual: (
        <>
            <span className="red">Lower</span>&nbsp;than usual
        </>
    ),
    as_usual: <>Within usual ranges</>,
    not_enough_data: (
        <>
            <span className="gray">Unknown</span>
        </>
    ),
}

export const getComparisonColorFromPercentString = (valueString: string) => {
    const value = Number(valueString.split('%')[0])
    if (value > 5) {
        return 'green'
    } else if (value < -5) {
        return 'red'
    } else {
        return 'gray'
    }
}

export const checkRequiredCapacityFiltersPresent = (filters: Filters[]) => {
    const requiredAtributes = ['capacity_type', 'time_slots']
    const requiredFilters = filters.filter((f) => requiredAtributes.indexOf(f.attribute) > -1)
    return requiredFilters.length === 2
}

export const mapPieChartExportData = (chartName: string, data: DataTotal[], currencySymbol: string = '') => {
    const total = data.reduce((sum, d) => sum + d.value, 0)
    const valueLabel = currencySymbol ? `Value (${currencySymbol})` : 'Value'
    const headers: DownloadHeader[] = [
        { slug: 'chart_name', label: chartName, width: 30 },
        { slug: 'value', label: valueLabel, width: 12 },
        { slug: 'percent', label: 'Percent (%)', width: 12 },
    ]
    const values: (string | number)[][] = []
    data.forEach((d) => {
        const percentage = roundNumber((d.value / total) * 100, 2)
        const row = [d.name, d.value, percentage]
        values.push(row)
    })
    const name = slugifyName(chartName)
    return { name, headers, values }
}

export const mapTableData = (data: Object[]) => data.map((d) => Object.values(d))

export const mapTimeseriesData = (
    dataSeries: {
        timestamp: string
        value: number | string
    }[][],
) => {
    const values: (string | number)[][] = []
    dataSeries[0].forEach((d, i) => {
        const row: (string | number)[] = []
        const date = format(d.timestamp, 'yyyy-MM-dd')
        row.push(date)
        dataSeries.forEach((series) => row.push(series[i].value))
        values.push(row)
    })
    return values
}

export const mapGroupedTimeseriesData = (timeseries: TimeSeries[][] | RawDataSeries[][], xAxisData: XAxisData[]) => {
    const mappedTimeseries: TimeSeries[][] = []
    const allLabels: string[] = []
    timeseries.forEach((t) => {
        const labels: string[] = t.filter((x) => !!x.label).map((x) => x.label as string)
        allLabels.push(...labels)
    })
    const uniqueLabels = Array.from(new Set(allLabels))
    timeseries.forEach((t) => {
        const updatedSeries = t as unknown as TimeSeries[]
        uniqueLabels.map((label) => {
            if (!t.find((entry) => entry.label === label)) {
                const pointsWithZeros = t[0].points.map((p) => ({
                    timestamp: p.timestamp,
                    value: 0,
                }))
                updatedSeries.push({
                    label,
                    points: pointsWithZeros as DataPoint[],
                })
            }
        })
        const mapped = mapGroupedTimeseriesForTooltip(xAxisData, updatedSeries)
        mappedTimeseries.push(mapped)
    })
    const values: (string | number)[][] = []
    uniqueLabels.forEach((label) => {
        const labelTimeseries = flatMap(
            mappedTimeseries.map((mt) => mt.filter((t) => t.label === label)),
            (x) => x,
        )
        const labelDataPoints = labelTimeseries.map((lt) => lt.points)
        xAxisData.forEach((d, i) => {
            const row: (string | number)[] = []
            const date = format(d.category, 'yyyy-MM-dd')
            row.push(date, label)
            labelDataPoints.forEach((p) => row.push(p[i].value))
            values.push(row)
        })
    })
    return values
}
