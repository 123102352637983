import React, { FC, useEffect } from 'react'
import { withNavigation, WithNavigationProps } from 'hocs'
import { useLazyGetSentEmailsQuery } from 'orders/reduxQueries'
import { PageContent } from 'uiComponents/appLayouts/pageContent'
import { PageTitle } from 'uiComponents/typography'
import { useHasFeature } from 'features'
import './sentEmailsPage.scss'
import { PageLoader } from 'uiComponents/loaders'
import { Alert } from '@mui/material'
import { SerializedError } from '@reduxjs/toolkit'

interface SentEmailsPageProps extends WithNavigationProps<{ id: string; accountSlug: string }> {
    type: 'cart' | 'order'
}

const SentEmailsPage: FC<SentEmailsPageProps> = ({ match, type }) => {
    const { accountSlug, id } = match.params
    const [getSentEmails, { data, isFetching, isError, error }] = useLazyGetSentEmailsQuery()
    const canSeeSentEmails = useHasFeature('show_sent_emails')

    useEffect(() => {
        canSeeSentEmails && getSentEmails({ id, accountSlug, type })
    }, [accountSlug, id, type, getSentEmails])

    if (isFetching) return <PageLoader />

    if (!canSeeSentEmails) {
        return null
    }

    return (
        <PageContent className="sent-emails-page">
            <PageTitle>Sent Emails</PageTitle>
            {isError ? (
                <Alert severity="error" className="sent-emails-page-error">
                    {(error as SerializedError)?.message || 'Unable to fetch sent emails'}
                </Alert>
            ) : (
                <pre>{JSON.stringify(data, null, 2)}</pre>
            )}
        </PageContent>
    )
}

export default withNavigation(SentEmailsPage)
