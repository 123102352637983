import * as React from 'react'
import styled from 'styled-typed'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { createDateRange, DateRange, DateRangeName, DateRangePeriod, dateRangeWithName, custom } from 'dateRanges'
import { format, startOfMonth, isBefore, isValid, isEqual, addMonths, addYears, parse } from 'date-fns'
import { ActionButton } from 'uiComponents/buttons'
import { ModalDialog } from 'uiComponents/popups/modal'
import { ComparisonGuideline } from './comparisonGuideline'
import DateRangePicker from './dateRangePicker'
import { DateRangeSection } from './dateRangeSection'
import { ComparisonSection } from './comparisonSection'
import { PickerToggle } from './pickerToggle'
import { CompareToType, Option, CompareRangeQuery, DateRangeType, DateRangeOption } from './schema'
import { withTheme } from 'styled-typed'
import { DashboardTheme } from 'theme'
import { areDateRangeDatesEqual } from 'reports/helpers'
import { DateRangeTypeSelect, DateRangeToggler } from './dateRangeTypeSelect'
import { DateFormats, parseISODate } from 'utils/dates'

const Container = styled.div`
    position: relative;
    display: inline-block;
`
const PickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 15;
    min-width: max-content;
`
const DatePickerContainer = styled.div`
    display: none;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);

    &.visible {
        display: flex;
        flex-direction: column;
        width: max-content;
    }

    position: absolute;
    top: 2em;
    right: 0;
    z-index: 1;
    background: ${(props) => props.theme.colors.white};
    width: fit-content;
    font-weight: normal;
    border-radius: 6px;
    font-size: 0.875em;
`
const DatePickersBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    max-height: 19em;
    transition: max-height 0.2s;

    &.allow-comparison {
        max-height: 21.5em;
    }
    &.compare {
        max-height: 37.7em;
    }
`
const CompareDateRangePicker = styled.div`
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s ease-in;

    &.visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in;
    }
`
const PickerControlsBox = styled.div`
    padding: 0 2.5em 1.5em 2.5em;
    border-radius: 6px;
    font-size: 0.875em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const InfoLink = styled.div`
    color: ${(props) => props.theme.colors.textLight};
    font-size: 0.85em;
    margin-top: 2.5em;
    cursor: pointer;
`
const ActionSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1em;

    &.with-comparison {
        margin-top: 3.25em;
    }
`

export interface DateRangePickerProps {
    range: DateRange
    onChange: (range: DateRange, compareRange: CompareRangeQuery) => void
    allowComparison?: boolean
    navigation: Navigation
    match: RouteMatch<any>
    theme: DashboardTheme
    userpilot?: string
    allowFutureDateSelection?: boolean
    futureDateRange?: boolean
    firstAvailableDay?: Date
    lastAvailableDay?: Date
    dateRangeTypeDisabled?: boolean
    dateRangeType?: DateRangeType
    onDateRangeTypeChange?: (type: DateRangeType) => void
    style?: React.CSSProperties
    dateRangeToggler?: boolean
    onEnableDateRange?: (v: boolean) => void
    dateRangeOn?: boolean
    dateRangeOptions?: DateRangeOption[]
}

interface DateRangePickerState {
    dateRange: DateRange
    compareRange: DateRange
    inputFrom: string
    inputTo: string
    inputFromError: string
    inputToError: string
    inputCompareFrom: string
    inputCompareTo: string
    inputCompareFromError: string
    inputCompareToError: string
    compare: boolean
    compareTo: CompareToType
    initialCompareRange: DateRange
    initialCompareTo: CompareToType
    initialCompare: boolean
    showPickerContainer: boolean
    showCompareGuideline: boolean
    ignoreOutsideClick: boolean
}

class ComparisonDateRangePicker extends React.Component<DateRangePickerProps, DateRangePickerState> {
    container: HTMLDivElement | null = null
    dateFormat = DateFormats.LONG_DATE
    firstAvailableDay = this.props.firstAvailableDay ? this.props.firstAvailableDay : new Date(2017, 0, 1)
    lastAvailableDay =
        this.props.lastAvailableDay ??
        (this.props.futureDateRange || this.props.allowFutureDateSelection ? addMonths(new Date(), 24) : new Date())
    compareOptions: Option[] = [
        { name: 'Custom', value: 'custom' },
        { name: 'Previous period', value: 'prevPeriod' },
        { name: 'Previous year', value: 'prevYear' },
    ]
    presetDateRangeOptions: Option[] = this.props.futureDateRange
        ? [
              { name: 'Custom', value: 'custom' },
              { name: 'Today', value: 'today' },
              { name: 'Tomorrow', value: 'tomorrow' },
              { name: 'This week', value: 'thisWeek' },
              { name: 'Next week', value: 'nextWeek' },
              { name: 'This month', value: 'thisMonth' },
              { name: 'Next month', value: 'nextMonth' },
          ]
        : [
              { name: 'Custom', value: 'custom' },
              { name: 'Today', value: 'today' },
              { name: 'Yesterday', value: 'yesterday' },
              { name: 'This week', value: 'thisWeek' },
              { name: 'This month', value: 'thisMonth' },
              { name: 'This quarter', value: 'thisQuarter' },
              { name: 'This year', value: 'thisYear' },
          ]
    defaultDateRange = {
        name: 'custom' as DateRangeName,
        period: 'day' as DateRangePeriod,
        from: startOfMonth(new Date()),
        to: new Date(),
    }

    constructor(props: DateRangePickerProps) {
        super(props)
        this.state = { ...this.getInitialState() }
    }

    getInitialState = () => {
        const { from, to } = this.props.range
        const compareToDetails = this.getCompareDetails()
        return {
            dateRange: this.props.range,
            compareRange: compareToDetails.range,
            inputFrom: format(from, this.dateFormat),
            inputTo: format(to, this.dateFormat),
            inputFromError: '',
            inputToError: '',
            inputCompareFrom: format(compareToDetails.range.from, this.dateFormat),
            inputCompareTo: format(compareToDetails.range.to, this.dateFormat),
            inputCompareFromError: '',
            inputCompareToError: '',
            compare: !!compareToDetails.compareTo,
            compareTo: (compareToDetails.compareTo as CompareToType) || 'prevPeriod',
            initialCompareRange: compareToDetails.range,
            initialCompareTo: (compareToDetails.compareTo as CompareToType) || 'prevPeriod',
            initialCompare: !!compareToDetails.compareTo,
            showPickerContainer: false,
            showCompareGuideline: false,
            ignoreOutsideClick: false,
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
    }

    componentDidUpdate(prevProps: DateRangePickerProps) {
        if (!areDateRangeDatesEqual(prevProps.range, this.props.range)) {
            this.setState({ dateRange: this.props.range })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
    }

    outsideClick = (ev: MouseEvent) => {
        if (this.state.ignoreOutsideClick) {
            this.setState({ ignoreOutsideClick: false })
            return
        }
        const clickOutsidePicker =
            this.container && this.container.contains && !this.container.contains(ev.target as Node)
        if (this.state.showPickerContainer && clickOutsidePicker && !this.state.showCompareGuideline) {
            this.closeDateRangePicker()
        }
    }

    closeDateRangePicker = () => {
        this.setState({ ...this.getInitialState() })
    }

    closeComparisonGuideline = () => {
        this.setState({ showCompareGuideline: false, ignoreOutsideClick: true })
    }

    setContainerRef = (node: HTMLDivElement) => {
        this.container = node
    }

    getCompareDetails = () => {
        const query = this.props.navigation.query()
        return {
            range: {
                ...this.defaultDateRange,
                from: query.compareRangeFrom ? parseISODate(query.compareRangeFrom) : this.defaultDateRange.from,
                to: query.compareRangeTo ? parseISODate(query.compareRangeTo) : this.defaultDateRange.to,
            },
            compareTo: query.compareToPeriod,
        }
    }

    toggleContainer = () => {
        if (typeof this.props.dateRangeOn !== 'undefined' && !this.props.dateRangeOn) {
            return
        }
        this.setState({ showPickerContainer: !this.state.showPickerContainer })
    }

    toggleCompare = () => {
        this.setPreviousRange()
        this.setState({ compare: !this.state.compare })
    }

    applyDateRangeChange = () => {
        const { compare, compareRange, compareTo } = this.state
        const compareRangeQuery = {
            compareToPeriod: compare ? compareTo : null,
            compareRangeFrom: compare ? compareRange.from.toISOString() : null,
            compareRangeTo: compare ? compareRange.to.toISOString() : null,
        }
        this.props.onChange(this.state.dateRange, compareRangeQuery)
        this.setState({
            showPickerContainer: false,
            initialCompare: compare,
            initialCompareTo: compareTo,
        })
    }

    handleDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const validity = `${e.target.name}Error`
        this.setState({ [e.target.name]: e.target.value, [validity]: '' } as any)
    }

    handleFinishDateEditing = () => {
        this.setState({
            inputFrom: this.state.inputFrom,
            inputTo: this.state.inputTo,
            inputCompareFrom: this.state.inputCompareFrom,
            inputCompareTo: this.state.inputCompareTo,
        })
    }

    handleCompareToChange = (value: CompareToType) => {
        this.setState({ compareTo: value }, () => this.setPreviousRange())
    }

    handlePresetRangeChange = (value: DateRangeName) => {
        const dateRange = dateRangeWithName(value)
        if (dateRange && dateRange.from && dateRange.to) {
            this.setState(
                {
                    dateRange,
                    inputFrom: format(dateRange.from, this.dateFormat),
                    inputTo: format(dateRange.to, this.dateFormat),
                },
                () => this.setPreviousRange(),
            )
        }
    }

    validateInputDate = (inputValue: string, input: string) => {
        const date = parse(inputValue, this.dateFormat, new Date())
        const validState = `${input}Error`
        if (!isValid(date)) {
            this.setState({ [validState]: `Please enter ${this.dateFormat}` } as any)
        } else if (isBefore(date, this.firstAvailableDay)) {
            this.setState({ [validState]: 'Please enter a later date' } as any)
        } else if (isBefore(this.lastAvailableDay, date)) {
            this.setState({ [validState]: 'Please enter an earlier date' } as any)
        } else {
            const from = input === 'inputFrom' ? date : this.state.dateRange.from
            const to = input === 'inputTo' ? date : this.state.dateRange.to
            const dateRange = isBefore(from, to) ? createDateRange(from, to) : createDateRange(to, from)
            this.setState({ [validState]: '', dateRange } as any, () => this.setPreviousRange())
        }
    }

    setPreviousRange = () => {
        let compareRange = { ...this.state.compareRange }
        switch (this.state.compareTo) {
            case 'prevPeriod':
                compareRange = this.getPreviousPeriod()
                break
            case 'prevYear':
                compareRange = this.getPreviousYear()
                break
            default:
                break
        }
        this.setState({
            compareRange,
            inputCompareFrom: format(compareRange.from, this.dateFormat),
            inputCompareTo: format(compareRange.to, this.dateFormat),
        })
    }

    getPreviousPeriod = () => {
        if (this.state.dateRange.prev) {
            return this.state.dateRange.prev()
        } else {
            const range = custom(this.state.dateRange.from, this.state.dateRange.to, 'day')
            if (!range.prev) {
                throw new Error('No default date range is set')
            }
            return range.prev()
        }
    }

    getPreviousYear = () => {
        const from = addYears(this.state.dateRange.from, -1)
        const to = addYears(this.state.dateRange.to, -1)
        return { ...this.state.dateRange, from, to }
    }

    onDateRangeChange = (dateRange: DateRange) => {
        this.setState(
            {
                dateRange,
                inputFrom: format(dateRange.from, this.dateFormat),
                inputTo: format(dateRange.to, this.dateFormat),
                inputFromError: '',
                inputToError: '',
            },
            () => {
                if (this.state.compareTo !== 'custom') {
                    this.setPreviousRange()
                }
            },
        )
    }

    onCompareRangeChange = (dateRange: DateRange) => {
        let compareTo: CompareToType = 'custom'
        if (areDateRangeDatesEqual(dateRange, this.getPreviousPeriod())) {
            compareTo = 'prevPeriod'
        }
        if (areDateRangeDatesEqual(dateRange, this.getPreviousYear())) {
            compareTo = 'prevYear'
        }
        this.setState({
            compareRange: dateRange,
            inputCompareFrom: format(dateRange.from, this.dateFormat),
            inputCompareTo: format(dateRange.to, this.dateFormat),
            inputCompareFromError: '',
            inputCompareToError: '',
            compareTo,
        })
    }

    enableDateRange = (v: boolean) => {
        if (v) {
            this.applyDateRangeChange()
        }
        if (!!this.props.onEnableDateRange) {
            this.props.onEnableDateRange(v)
        }
    }

    render() {
        const {
            showPickerContainer,
            dateRange,
            compare,
            compareTo,
            compareRange,
            inputFrom,
            inputTo,
            inputFromError,
            inputToError,
            showCompareGuideline,
            initialCompareTo,
            initialCompare,
            inputCompareFrom,
            inputCompareTo,
            inputCompareFromError,
            inputCompareToError,
            initialCompareRange,
        } = this.state

        const disabledDays = {
            after: this.lastAvailableDay,
            before: this.firstAvailableDay,
        }
        const compareModifiers = {
            compare: { from: this.firstAvailableDay, to: this.lastAvailableDay },
        }

        const dateRangeUpdated =
            !isEqual(this.props.range.from, dateRange.from) ||
            !isEqual(this.props.range.to, dateRange.to) ||
            !isEqual(initialCompareRange.from, compareRange.from) ||
            !isEqual(initialCompareRange.to, compareRange.to) ||
            compare !== initialCompare ||
            compareTo !== initialCompareTo

        const showToggleCompareRange =
            !!this.props.allowComparison && compareRange.from && compareRange.to && compare && !showPickerContainer

        return (
            <Container style={this.props.style} id="date-picker-container">
                <PickerWrapper
                    className="picker-wrapper"
                    ref={this.setContainerRef}
                    data-userpilot={this.props.userpilot}
                >
                    <PickerToggle
                        range={this.props.range}
                        compareRange={compareRange}
                        showPickerContainer={showPickerContainer}
                        showToggleCompareRange={showToggleCompareRange}
                        toggleContainer={this.toggleContainer}
                        disabled={typeof this.props.dateRangeOn !== 'undefined' && !this.props.dateRangeOn}
                    />
                    <DatePickerContainer className={showPickerContainer ? 'visible' : ''}>
                        <DatePickersBox
                            className={!this.props.allowComparison ? '' : compare ? 'compare' : 'allow-comparison'}
                        >
                            <DateRangePicker
                                month={addMonths(startOfMonth(this.props.range.from), -1)}
                                fromMonth={addMonths(startOfMonth(this.firstAvailableDay), -1)}
                                toMonth={addMonths(startOfMonth(this.lastAvailableDay), 1)}
                                rangeColor="--boy-blue"
                                dateRange={dateRange}
                                disabledDays={disabledDays}
                                onDateRangeChange={this.onDateRangeChange}
                            />
                            <CompareDateRangePicker className={compare ? 'visible' : ''}>
                                <DateRangePicker
                                    month={addMonths(startOfMonth(initialCompareRange.from), -1)}
                                    fromMonth={addMonths(startOfMonth(this.firstAvailableDay), -1)}
                                    toMonth={addMonths(startOfMonth(this.lastAvailableDay), 1)}
                                    rangeColor="--sun"
                                    dateRange={compareRange}
                                    disabledDays={disabledDays}
                                    modifiers={compareModifiers}
                                    onDateRangeChange={this.onCompareRangeChange}
                                />
                            </CompareDateRangePicker>
                        </DatePickersBox>
                        <PickerControlsBox>
                            <div>
                                <DateRangeSection
                                    dateRange={dateRange}
                                    defaultDateRange={this.defaultDateRange}
                                    presetDateRangeOptions={this.presetDateRangeOptions}
                                    inputFrom={inputFrom}
                                    inputTo={inputTo}
                                    inputFromError={inputFromError}
                                    inputToError={inputToError}
                                    dateFormat={this.dateFormat}
                                    handlePresetRangeChange={this.handlePresetRangeChange}
                                    handleDateInputChange={this.handleDateInputChange}
                                    handleFinishDateEditing={this.handleFinishDateEditing}
                                    validateInputDate={this.validateInputDate}
                                />
                                {this.props.allowComparison && (
                                    <ComparisonSection
                                        inputCompareFrom={inputCompareFrom}
                                        inputCompareTo={inputCompareTo}
                                        inputCompareFromError={inputCompareFromError}
                                        inputCompareToError={inputCompareToError}
                                        compare={compare}
                                        compareTo={compareTo}
                                        compareOptions={this.compareOptions}
                                        toggleCompare={this.toggleCompare}
                                        handleCompareToChange={this.handleCompareToChange}
                                        handleDateInputChange={this.handleDateInputChange}
                                        handleFinishDateEditing={this.handleFinishDateEditing}
                                        validateInputDate={this.validateInputDate}
                                    />
                                )}
                                <ActionSection className={this.props.allowComparison ? 'with-comparison' : ''}>
                                    <ActionButton size="large" secondary onClick={this.closeDateRangePicker}>
                                        Cancel
                                    </ActionButton>
                                    <ActionButton
                                        size="large"
                                        style={{ marginLeft: '1.3em' }}
                                        onClick={this.applyDateRangeChange}
                                        disabled={!dateRangeUpdated}
                                    >
                                        Apply
                                    </ActionButton>
                                </ActionSection>
                            </div>
                            <div>
                                {this.props.allowComparison && (
                                    <InfoLink onClick={() => this.setState({ showCompareGuideline: true })}>
                                        Read guideline: “What time ranges should I compare”?
                                    </InfoLink>
                                )}
                                {showCompareGuideline && (
                                    <ModalDialog onDismiss={this.closeComparisonGuideline} interactive fromTop="15%">
                                        <ComparisonGuideline onClose={this.closeComparisonGuideline} />
                                    </ModalDialog>
                                )}
                            </div>
                        </PickerControlsBox>
                    </DatePickerContainer>
                    {!this.props.dateRangeOptions && this.props.dateRangeType && this.props.onDateRangeTypeChange && (
                        <DateRangeTypeSelect
                            disabled={this.props.dateRangeTypeDisabled}
                            dateRangeType={this.props.dateRangeType}
                            onChange={this.props.onDateRangeTypeChange}
                        />
                    )}
                    {this.props.dateRangeToggler && (
                        <DateRangeToggler dateRangeOn={this.props.dateRangeOn} enableDateRange={this.enableDateRange} />
                    )}
                </PickerWrapper>
            </Container>
        )
    }
}

export default withNavigation(withTheme(ComparisonDateRangePicker))
