import ConviousMetricsPage from 'admin/conviousMetrics'
import FundTransfer from 'admin/fundTransfer'
import { PermissionProps, withPermission } from 'admin/hocs'
import { OnboardingPage } from 'admin/onboardingPage'
import ImpersonationBanner from 'auth/impersonationBanner'
import { Account } from 'auth/state'
import * as config from 'config'
import CopyEditorPage from 'copyEditor'
import CRMHome from 'crm'
import DashboardHomePage from 'dashboard/index'
import EmailTemplatesHome from 'emailTemplates'
import { TemplateType } from 'emailTemplates/schema'
import EmailTemplatesEditStudio from 'emailTemplates/studio'
import { BaseRouteParams } from 'hocs'
import { AppServices, history } from 'middleware'
import { NotificationCampaignsOverview, NotificationCampaignsStudio } from 'notificationCampaigns'
import OrdersPage from 'orders'
import ProductsPage from 'products'
import DiscountRulesContainer from 'products/discountRules'
import React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps } from 'react-router-dom'
import ReportsHome from 'reports'
import ResellersHomePage from 'resellers'
import { redirectUser, withRoutePermission } from 'routesUtils'
import MollieAuthCallback from 'settings/accountSettings/integrations/mollie/callback'
import NotificationsPage from 'settings/notifications'
import PersonalSettingsPage from 'settings/personalSettings'
import RequiresCompleteSignup from 'signUp/requiresCompleteSignup'
import RequiresWelcomeSeen from 'signUp/requiresWelcomeSeen'
import WelcomePopup from 'signUp/welcomePopup'
import AddSnippetPage from 'snippet'
import { State } from 'store'
import APFStudio from 'studioAPF'
import TapPlacementEditStudio from 'tapPlacements/studio'
import TicketTemplatesOverviewPage from 'ticketTemplates'
import GiftCardTemplatesStudioPage from 'ticketTemplates/studio/giftCards/giftCardStudio'
import TicketTemplatesStudioPage from 'ticketTemplates/studio/regularTickets/ticketsStudio'
import SidebarMenuAndHeader, { withSidebarMenuAndHeader } from 'uiComponents/appLayouts/headerSidebar'
import { withHeaderOnly } from 'uiComponents/appLayouts/withHeaderOnly'
import { ConfigReader } from 'utils/confiReader'
import WithRoutePermission from '../guards/withRoutePermission'
import EngageToolsRoutes from './engageToolsRoutes'
import PushNotificationsRoutes from './pushNotificationsRoutes'
import SettingsRoutes from './settings'
import VenueRoutes from './venueRoutes'
import BackgroundFetcher from '../../utils/backgroundFetcher'
import GPSStudio from '../../globalPricingSettings/studio'
import ReleaseNotes from 'releaseNotes'
import { RELEASED_WIDGET } from 'releaseNotes/htmlComponents'
import { Feature } from 'features'

const backofficeEndpoint = config.getRequired('backoffice-endpoint')
const crmApiEndpoint = config.getRequired('customers-endpoint')

interface AppRoutesProps extends PermissionProps {
    accounts: Account[]
    activeAccount: string
    requiresPasswordChange: boolean
    isReseller: boolean
    selfSignUp: string | null
}

interface EmailTemplateRouteParams extends BaseRouteParams {
    templateType: TemplateType
}

const AppRoutes = (pageProps: AppRoutesProps) => {
    const { selfSignUp, activeAccount } = pageProps
    const routePermissionCheckData = {
        accounts: pageProps.accounts,
        history: history,
        hasPermission: pageProps.hasPermission,
    }

    return (
        <>
            <BackgroundFetcher />
            <Route
                path="/account/:accountSlug/welcome_popup"
                render={(props) =>
                    withHeaderOnly(
                        pageProps,
                        props.match.params.accountSlug,
                        true,
                        <WelcomePopup
                            history={history}
                            accountSlug={props.match.params.accountSlug}
                            accountSettingsService={AppServices.accountSettingsService}
                        />,
                    )
                }
            />
            <RequiresCompleteSignup>
                {pageProps.requiresPasswordChange && (
                    <Route
                        path="/new_user/welcome"
                        render={(props) =>
                            withHeaderOnly(
                                pageProps,
                                activeAccount,
                                true,
                                <PersonalSettingsPage
                                    accountSlug={activeAccount}
                                    history={history}
                                    accountSettingsService={AppServices.accountSettingsService}
                                    userService={AppServices.userService}
                                    loginService={AppServices.loginService}
                                    passwordResetService={AppServices.passwordResetService}
                                    location={props.location}
                                    welcomePage={true}
                                />,
                            )
                        }
                    />
                )}
                <RequiresWelcomeSeen>
                    <ImpersonationBanner>
                        <Route
                            path="/account/:accountSlug/settings/add_snippet"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    <AddSnippetPage
                                        accountSlug={props.match.params.accountSlug}
                                        history={history}
                                        snippetService={AppServices.snippetService}
                                    />,
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/new_account/add_snippet"
                            render={(props) =>
                                withHeaderOnly(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    true,
                                    <AddSnippetPage
                                        accountSlug={props.match.params.accountSlug}
                                        history={history}
                                        snippetService={AppServices.snippetService}
                                    />,
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/tools/add_snippet"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['edit_wonderbar'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <AddSnippetPage
                                                accountSlug={props.match.params.accountSlug}
                                                history={history}
                                                snippetService={AppServices.snippetService}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        {!pageProps.isReseller && (
                            <Route
                                path="/account/:accountSlug/dashboard/overview"
                                render={(props) =>
                                    withSidebarMenuAndHeader(
                                        pageProps,
                                        props.match.params.accountSlug,
                                        withRoutePermission({
                                            ...routePermissionCheckData,
                                            permissions: ['access_account'],
                                            accountSlug: props.match.params.accountSlug,
                                            component: (
                                                <DashboardHomePage
                                                    statsService={AppServices.statsService}
                                                    loggingService={AppServices.loggingService}
                                                    accountSlug={props.match.params.accountSlug}
                                                    history={history}
                                                />
                                            ),
                                        }),
                                    )
                                }
                            />
                        )}
                        <Route
                            path="/account/:accountSlug/engage/tools"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['view_wonderbar']}>
                                    <EngageToolsRoutes />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/email_templates/home"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['partner_admin'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <EmailTemplatesHome
                                                accountSlug={props.match.params.accountSlug}
                                                emailTemplatesService={AppServices.emailTemplatesService}
                                                history={history}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/email_templates/edit/:templateType/:step?"
                            render={(props: RouteComponentProps<EmailTemplateRouteParams, any, unknown>) =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    accountSlug: props.match.params.accountSlug,
                                    component: (
                                        <EmailTemplatesEditStudio
                                            templateType={props.match.params.templateType}
                                            accountSlug={props.match.params.accountSlug}
                                            history={history}
                                            articleService={AppServices.adminArticleService}
                                            emailTemplatesService={AppServices.emailTemplatesService}
                                            bookingCodesService={AppServices.bookingCodesService}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/copy_editor"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['partner_admin'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <CopyEditorPage
                                                accountSlug={props.match.params.accountSlug}
                                                history={history}
                                                location={props.location}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/push_notifications"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <PushNotificationsRoutes />
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/crm"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['partner_admin'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <CRMHome
                                                accountSlug={props.match.params.accountSlug}
                                                history={history}
                                                location={props.location}
                                                crmService={AppServices.crmService}
                                                loginService={AppServices.loginService}
                                                loggingService={AppServices.loggingService}
                                                crmEndpoint={crmApiEndpoint}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/tap/placements/studio/:step/:id?"
                            render={(props) =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    accountSlug: props.match.params.accountSlug,
                                    component: (
                                        <TapPlacementEditStudio
                                            accountSlug={props.match.params.accountSlug}
                                            tapPlacementService={AppServices.tapPLacementService}
                                            history={history}
                                            componentsService={AppServices.componentsService}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/notification-campaigns/overview"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['partner_admin'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <NotificationCampaignsOverview
                                                accountSlug={props.match.params.accountSlug}
                                                notificationCampaignsService={AppServices.notificationCampaignsService}
                                                history={history}
                                                location={props.location}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/notification-campaigns/studio/:step/:id?"
                            render={(props) =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    accountSlug: props.match.params.accountSlug,
                                    component: (
                                        <NotificationCampaignsStudio
                                            accountSlug={props.match.params.accountSlug}
                                            history={history}
                                            notificationCampaignsService={AppServices.notificationCampaignsService}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/ticket_templates/:id"
                            render={(props) =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    accountSlug: props.match.params.accountSlug,
                                    component: (
                                        <TicketTemplatesStudioPage
                                            accountSlug={props.match.params.accountSlug}
                                            history={history}
                                            ticketTemplatesService={AppServices.ticketTemplatesService}
                                            backofficeEndpoint={backofficeEndpoint}
                                            loginService={AppServices.loginService}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/ticket_templates"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['partner_admin'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <TicketTemplatesOverviewPage
                                                accountSlug={props.match.params.accountSlug}
                                                history={history}
                                                ticketTemplatesService={AppServices.ticketTemplatesService}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/gift_card_templates/:id"
                            render={(props) =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    accountSlug: props.match.params.accountSlug,
                                    component: (
                                        <GiftCardTemplatesStudioPage
                                            accountSlug={props.match.params.accountSlug}
                                            history={history}
                                            ticketTemplatesService={AppServices.ticketTemplatesService}
                                            backofficeEndpoint={backofficeEndpoint}
                                            loginService={AppServices.loginService}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/engage/gift_card_templates"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['partner_admin'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <TicketTemplatesOverviewPage
                                                accountSlug={props.match.params.accountSlug}
                                                history={history}
                                                ticketTemplatesService={AppServices.ticketTemplatesService}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/reports"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['access_analytics'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <ReportsHome
                                                statsService={AppServices.statsService}
                                                engageToolsService={AppServices.engageToolsService}
                                                requestService={AppServices.requestService}
                                                backofficeEndpoint={backofficeEndpoint}
                                                loggingService={AppServices.loggingService}
                                                loginService={AppServices.loginService}
                                                accountSlug={props.match.params.accountSlug}
                                                history={history}
                                                location={props.location}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/orders"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['access_orders'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <OrdersPage
                                                {...props}
                                                history={history}
                                                ordersService={AppServices.ordersService}
                                                loginService={AppServices.loginService}
                                                loggingService={AppServices.loggingService}
                                                paypalService={AppServices.paypalService}
                                                backofficeEndpoint={backofficeEndpoint}
                                                bookingCodesService={AppServices.bookingCodesService}
                                                articleService={AppServices.adminArticleService}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />

                        {pageProps.isReseller && (
                            <Route
                                path="/resellers"
                                render={(props) =>
                                    withHeaderOnly(
                                        pageProps,
                                        activeAccount,
                                        false,
                                        <ResellersHomePage
                                            {...props}
                                            accountSlug={activeAccount}
                                            history={history}
                                            ordersService={AppServices.ordersService}
                                            loginService={AppServices.loginService}
                                            loggingService={AppServices.loggingService}
                                            paypalService={AppServices.paypalService}
                                            accountSettingsService={AppServices.accountSettingsService}
                                            userService={AppServices.userService}
                                            passwordResetService={AppServices.passwordResetService}
                                            backofficeEndpoint={backofficeEndpoint}
                                        />,
                                    )
                                }
                            />
                        )}
                        <Route
                            path="/account/:accountSlug/products/apf_studio/:step?"
                            render={(props) =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['partner_admin'],
                                    accountSlug: props.match.params.accountSlug,
                                    component: (
                                        <APFStudio
                                            accountSlug={props.match.params.accountSlug}
                                            articleService={AppServices.adminArticleService}
                                            history={history}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products/discount_rules"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['partner_admin'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <DiscountRulesContainer
                                                accountSlug={props.match.params.accountSlug}
                                                {...props}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/account/:accountSlug/products"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    props.match.params.accountSlug,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['view_pricing_settings', 'view_product_validity'],
                                        accountSlug: props.match.params.accountSlug,
                                        component: (
                                            <ProductsPage
                                                accountSlug={props.match.params.accountSlug}
                                                accounts={pageProps.accounts}
                                                articleConfigurationService={AppServices.articleConfigurationService}
                                                articleService={AppServices.adminArticleService}
                                                pricingService={AppServices.pricingService}
                                                channelsService={AppServices.channelsService}
                                                managementService={AppServices.managementService}
                                                statsService={AppServices.statsService}
                                                optionsService={AppServices.optionsService}
                                                ticketTemplatesService={AppServices.ticketTemplatesService}
                                                {...props}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route path="/account/:accountSlug/settings" component={SettingsRoutes} />
                        <Route
                            path="/personal_settings"
                            render={(props) =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    activeAccount,
                                    <PersonalSettingsPage
                                        accountSlug={activeAccount}
                                        history={history}
                                        accountSettingsService={AppServices.accountSettingsService}
                                        userService={AppServices.userService}
                                        loginService={AppServices.loginService}
                                        passwordResetService={AppServices.passwordResetService}
                                        location={props.location}
                                        welcomePage={false}
                                    />,
                                )
                            }
                        />
                        {!pageProps.isReseller && (
                            <Route
                                path="/account/:accountSlug/notifications"
                                render={(props) =>
                                    withSidebarMenuAndHeader(
                                        pageProps,
                                        props.match.params.accountSlug,
                                        withRoutePermission({
                                            ...routePermissionCheckData,
                                            permissions: ['access_account'],
                                            accountSlug: props.match.params.accountSlug,
                                            component: (
                                                <NotificationsPage
                                                    accountSlug={props.match.params.accountSlug}
                                                    accountSettingsService={AppServices.accountSettingsService}
                                                    articleService={AppServices.adminArticleService}
                                                    history={history}
                                                    location={props.location}
                                                />
                                            ),
                                        }),
                                    )
                                }
                            />
                        )}
                        <Route
                            path="/account/:accountSlug/venue"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <VenueRoutes />
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/release_notes"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <ReleaseNotes />
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/global_pricing_settings"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['partner_admin']}>
                                    <GPSStudio />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/admin/onboarding"
                            render={() =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    activeAccount,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['convious_admin'],
                                        accountSlug: '',
                                        component: (
                                            <OnboardingPage
                                                checkoutDemoEndpoint={ConfigReader.getCheckoutDemoEndpoint()}
                                            />
                                        ),
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/admin/fund_transfer"
                            render={() =>
                                withSidebarMenuAndHeader(
                                    pageProps,
                                    activeAccount,
                                    withRoutePermission({
                                        ...routePermissionCheckData,
                                        permissions: ['convious_admin', 'fund_transfers'],
                                        accountSlug: '',
                                        component: <FundTransfer />,
                                    }),
                                )
                            }
                        />
                        <Route
                            path="/mollie/callback"
                            render={() => withSidebarMenuAndHeader(pageProps, activeAccount, <MollieAuthCallback />)}
                        />
                        <Route
                            path="/kpis"
                            render={(props: RouteComponentProps<EmailTemplateRouteParams, any, unknown>) =>
                                withRoutePermission({
                                    ...routePermissionCheckData,
                                    permissions: ['convious_admin'],
                                    accountSlug: props.match.params.accountSlug,
                                    component: (
                                        <ConviousMetricsPage
                                            statsService={AppServices.statsService}
                                            history={history}
                                        />
                                    ),
                                })
                            }
                        />
                        <Route
                            render={() =>
                                redirectUser(!!selfSignUp, activeAccount, pageProps.isReseller, pageProps.accounts)
                            }
                        />
                    </ImpersonationBanner>
                </RequiresWelcomeSeen>
            </RequiresCompleteSignup>
            {!pageProps.isReseller && (
                <Feature name="release_notes_widget" accountSlug={activeAccount}>
                    <div dangerouslySetInnerHTML={{ __html: RELEASED_WIDGET }} />
                </Feature>
            )}
        </>
    )
}

const mapStateToProps = (state: State) => {
    return {
        selfSignUp: state.profile?.profile?.signUp ?? null,
        accounts: state.auth?.user?.accounts ?? [],
        isReseller: !!state.auth.user?.resellerId,
        requiresPasswordChange: !!state.profile?.profile?.requiresPasswordChange || false,
        activeAccount: state.preferences.activeAccount,
    }
}

export default withPermission(connect(mapStateToProps)(AppRoutes))
