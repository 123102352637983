import { CancelRefundFooterProps, CancelRefundHeaderProps, OrderRefundDetails } from '../types'
import { CancelRefundTableData, ICancelRefundTableRow } from '../components/cancelRefundTable/types'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'

export const mapHeaderProps = (isCancellation: boolean, onClose: () => void): CancelRefundHeaderProps => {
    return {
        title: isCancellation ? 'Cancel orders' : 'Refund orders',
        subtitle: `You are about to ${isCancellation ? 'cancel' : 'refund'} the following orders:`,
        onClose,
    }
}

interface TableDataMapperOptions {
    orders: OrderRefundDetails[]
    selectedOrders: string[]
    totalAmount: number
    partnerRefundFeeAmount: number
    partnerRefundFeeOff: boolean
    partnerRefundFeeSelected: boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    partnerRefundFee?: string
    partnerRefundFeeType?: RefundFeeType | null
}

export const prepareTableData = ({
    orders,
    selectedOrders,
    totalAmount,
    partnerRefundFeeAmount,
    partnerRefundFeeOff,
    partnerRefundFeeSelected,
    formatCurrencyString,
    partnerRefundFee,
    partnerRefundFeeType,
}: TableDataMapperOptions): CancelRefundTableData => {
    const sections = []
    const orderRows: ICancelRefundTableRow[] = []
    for (const order of orders) {
        orderRows.push({
            id: order.id,
            name: `Order ${order.number}`,
            description: `${formatCurrencyString(
                Number(order.totalDiscountedPriceExclTax).toFixed(2),
                order.account,
            )} subtotal`,
            amount: Number(order.totalDiscountedPriceInclTax),
            selected: selectedOrders.includes(order.id),
            orderItemId: '',
        })
    }

    const ticketsSection = {
        id: 'itemsSection',
        rows: orderRows,
        borderBottom: true,
    }

    sections.push(ticketsSection)

    if (!partnerRefundFeeOff) {
        const refundFeeSection = {
            id: 'refundFeeSection',
            rows: [
                {
                    id: 'refundFee',
                    name: 'Visitor refund fee',
                    description:
                        partnerRefundFeeType === 'percentage' ? `${partnerRefundFee}% on products total` : 'Flat rate',
                    amount: partnerRefundFeeAmount,
                    selected: partnerRefundFeeSelected,
                    orderItemId: '',
                    infoTip: 'To change this, go to Settings > Account.',
                    disabled: true,
                },
            ],
        }

        sections.push(refundFeeSection)
    }

    const totalAmountSection = {
        id: 'totalSection',
        rows: [
            {
                id: 'total',
                name: 'Total',
                description: '',
                amount: totalAmount,
                selected: false,
                orderItemId: '',
            },
        ],
    }
    sections.push(totalAmountSection)

    return {
        sections,
    }
}

interface FooterPropsMapperOptions {
    accountSlug: string
    confirmMode: boolean
    isCancellation: boolean
    selectedOrders: string[]
    refunding: boolean
    onCancel: () => void
    onNext: () => void
    totalAmount: number
    refundDisallowed: boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
}

export const mapFooterProps = ({
    accountSlug,
    confirmMode,
    isCancellation,
    selectedOrders,
    refunding,
    onCancel,
    onNext,
    totalAmount,
    refundDisallowed,
    formatCurrencyString,
}: FooterPropsMapperOptions): CancelRefundFooterProps => {
    const confirmMessage = (() => {
        if (confirmMode) {
            if (isCancellation) {
                return `You are about to cancel "${selectedOrders.length}" orders. All selected orders will be cancelled this cannot be undone!`
            }
            return `You are about to refund "${selectedOrders.length}" orders for  "${formatCurrencyString(
                Number(totalAmount).toFixed(2),
                accountSlug,
            )}". All selected orders will be refunded this cannot be undone!`
        }
        
        if (selectedOrders.length >= 20) {
            return 'A maximum of 20 orders can be processed at one time'
        }
        return
    })()

    const nextButtonCaption = confirmMode ? (isCancellation ? 'Cancel orders' : 'Refund orders') : 'Review'

    return {
        confirmMessage,
        cancelButtonCaption: confirmMode ? 'Back to editing' : 'Cancel',
        onCancel,
        nextButtonCaption,
        onNext,
        nextButtonDisabled: refundDisallowed || selectedOrders.length === 0 || selectedOrders.length > 20 || refunding,
    }
}
