const isString = (value: any): value is string => typeof value === 'string'

export const camelToSnakeCase = (str?: string): string | undefined =>
    isString(str) ? str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`) : undefined

type SnakeCaseKey<T extends string> = string extends T
    ? string
    : T extends `${infer F}${infer R}`
    ? `${F extends Uppercase<F> ? '_' : ''}${Lowercase<F>}${SnakeCaseKey<R>}`
    : T

type SnakeCaseObject<T> = {
    [K in keyof T as SnakeCaseKey<string & K>]: T[K] extends object ? SnakeCaseObject<T[K]> : T[K]
}

const isRecord = (value: unknown): value is Record<string, unknown> => {
    return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export const convertToSnakeCase = <T extends Record<string, unknown>>(obj: T): SnakeCaseObject<T> => {
    if (Array.isArray(obj)) {
        return obj.map((item) => (isRecord(item) ? convertToSnakeCase(item) : item)) as SnakeCaseObject<T>
    }

    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
            camelToSnakeCase(key),
            isRecord(value) ? convertToSnakeCase(value) : value,
        ]),
    ) as SnakeCaseObject<T>
}
