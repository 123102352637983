import * as React from 'react'
import { State } from 'store'
import { connect } from 'react-redux'
import { Account } from 'auth/state'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Location, History } from 'history'
import TransactionsPage from 'orders/transactions'
import OrdersDetailsPage from 'orders/details'
import PaypalDisputesPage from 'orders/paypalDisputes'
import ChannelsPage from 'channels'
import { Navigation } from 'navigation'
import { dateRangeFromQuery, dateRangeToQuery, DateRange } from 'dateRanges'
import { Sorting, Pagination } from 'uiComponents/table'
import { OrdersService } from 'orders/ordersService'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { PaypalService } from 'orders/paypalService'
import { BookingCodesService } from 'venue/bookingCodes/bookingCodesService'
import InlineCheckout from 'orders/inlineCheckout'
import { defaultPageData } from './schema'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { withPermission } from 'admin/hocs'
import { User } from 'auth/state'
import { ArticleService } from 'admin/articleService'
import Orders from './orders'
import { RedeemModeDialog } from './redeemModeDialog'
import Redeem from './redeem'
import ExportOrders from './orders/export'
import { ORDER_PATHS } from './paths'
import Cart_OLD from './cart_old'
import Cart from './cart'
import EditCart from './cart/editCart'
import OrderDetails from './orders/orderDetails'
import { FeatureProps, withFeatures } from 'features'
import SentEmailsPage from 'sentEmails/sentEmailsPage'

interface OrdersPageParams {
    accountSlug: string
}

interface OrdersListProps extends FeatureProps {
    user: User
    accounts: Account[]
    history: History
    location: Location
    navigation: Navigation
    ordersService: OrdersService
    loginService: LoginService
    loggingService: LoggingService
    paypalService: PaypalService
    bookingCodesService: BookingCodesService
    match: RouteMatch<OrdersPageParams>
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, accountSlug: string) => boolean
    backofficeEndpoint: string
    articleService: ArticleService
}

class OrdersListPage extends React.Component<OrdersListProps> {
    onSortChanged = (sorting: Sorting) => {
        this.props.navigation.addQueryWithReplace({
            sortBy: sorting.prop,
            sortDirection: sorting.direction,
        })
    }

    onPaginationChanged = (pagination: Pagination) => {
        this.props.navigation.addQueryWithReplace({
            page: pagination.page.toString(),
            pageSize: pagination.pageSize.toString(),
        })
    }

    onDateRangeChanged = (range: DateRange) => {
        this.props.navigation.addQueryWithReplace({
            ...dateRangeToQuery(range),
            page: '1',
        })
    }

    onDateRangeTypeChanged = (rangeType: DateRangeType) => {
        this.props.navigation.addQueryWithReplace({
            dateRangeType: rangeType,
            page: '1',
        })
    }

    render() {
        const accountSlug = this.props.match.params.accountSlug
        const query = this.props.navigation.query()
        const sortDirection = query.sortDirection === 'asc' ? 'asc' : 'desc'
        const sort: Sorting = {
            prop: query.sortBy,
            direction: sortDirection,
        }
        const pagination: Pagination = {
            page: isNaN(Number(query.page)) ? defaultPageData.currentPage : Number(query.page),
            pageSize: isNaN(Number(query.pageSize)) ? defaultPageData.pageSize : Number(query.pageSize),
        }
        const dateRange = dateRangeFromQuery(query, 'today')
        const dateRangeType = query.dateRangeType === 'visit' ? 'visit' : 'sale'

        const activeAccount = this.props.accounts.find((x) => x.slug === accountSlug) || this.props.accounts[0]
        const hideCheckoutRoute = !!this.props.user?.resellerId && !activeAccount.activeResellerContract

        const CartComponent = this.props.hasFeature('show_new_cart_page', accountSlug) ? Cart : Cart_OLD

        return (
            <Switch location={this.props.location}>
                {this.props.hasFeature('show_sent_emails', accountSlug) && (
                    <Route
                        path={ORDER_PATHS.fullPaths.cartSentEmailsPage}
                        render={() => <SentEmailsPage type="cart" />}
                    />
                )}
                <Route path={ORDER_PATHS.fullPaths.cartPage}>
                    <CartComponent>
                        <Switch>
                            <Route path={ORDER_PATHS.fullPaths.cartEditPage} component={EditCart} />
                        </Switch>
                    </CartComponent>
                </Route>
                <Route path={ORDER_PATHS.fullPaths.ordersPage}>
                    <Orders>
                        <Switch>
                            <Route
                                path={ORDER_PATHS.fullPaths.redeemMode}
                                render={({ location }) => (
                                    <RedeemModeDialog
                                        onCancel={() => {
                                            const pathname = location.pathname.replace(
                                                `/${ORDER_PATHS.internalPaths.orderPath.redeemMode}`,
                                                '',
                                            )
                                            this.props.history.push(`${pathname}${location.search}`)
                                        }}
                                    />
                                )}
                            />
                            <Route path={ORDER_PATHS.fullPaths.orderRedeem} component={Redeem} />
                            <Route path={ORDER_PATHS.fullPaths.ordersExport} component={ExportOrders} />
                        </Switch>
                    </Orders>
                </Route>
                <Route path="/account/:accountSlug/orders/:id/details" render={() => <OrderDetails />} />
                {this.props.hasFeature('oldOrdersPages', accountSlug) && (
                    <Route
                        path="/account/:accountSlug/orders/transactions"
                        render={() => (
                            <TransactionsPage
                                history={this.props.history}
                                accountSlug={accountSlug}
                                search={query.q}
                                sort={sort}
                                onSortChanged={this.onSortChanged}
                                pagination={pagination}
                                onPaginationChanged={this.onPaginationChanged}
                                dateRange={dateRange}
                                onDateRangeChanged={this.onDateRangeChanged}
                                dateRangeType={dateRangeType}
                                onDateRangeTypeChange={this.onDateRangeTypeChanged}
                                ordersService={this.props.ordersService}
                                loginService={this.props.loginService}
                                loggingService={this.props.loggingService}
                                backofficeEndpoint={this.props.backofficeEndpoint}
                            />
                        )}
                    />
                )}
                {this.props.hasFeature('oldOrdersPages', accountSlug) && (
                    <Route
                        path={ORDER_PATHS.pagePaths.orderDetailsPath}
                        render={() => (
                            <OrdersDetailsPage
                                history={this.props.history}
                                accountSlug={accountSlug}
                                search={query.q}
                                sort={sort}
                                onSortChanged={this.onSortChanged}
                                pagination={pagination}
                                onPaginationChanged={this.onPaginationChanged}
                                dateRange={dateRange}
                                onDateRangeChanged={this.onDateRangeChanged}
                                dateRangeType={dateRangeType}
                                onDateRangeTypeChange={this.onDateRangeTypeChanged}
                                ordersService={this.props.ordersService}
                                loginService={this.props.loginService}
                                loggingService={this.props.loggingService}
                                backofficeEndpoint={this.props.backofficeEndpoint}
                            />
                        )}
                    />
                )}
                {this.props.hasFeature('show_sent_emails', accountSlug) && (
                    <Route
                        path="/account/:accountSlug/orders/:id/sent_emails"
                        render={() => <SentEmailsPage type="order" />}
                    />
                )}
                {this.props.hasPermission('partner_admin', accountSlug) && (
                    <Route
                        path={ORDER_PATHS.pagePaths.channelsPath}
                        render={() => (
                            <ChannelsPage
                                history={this.props.history}
                                accountSlug={accountSlug}
                                location={this.props.location}
                                bookingCodesService={this.props.bookingCodesService}
                                articleService={this.props.articleService}
                            />
                        )}
                    />
                )}
                {this.props.hasPermission('partner_admin', accountSlug) && (
                    <Route
                        path={ORDER_PATHS.pagePaths.paypalDisputesPath}
                        render={() => (
                            <PaypalDisputesPage
                                history={this.props.history}
                                location={this.props.location}
                                accountSlug={accountSlug}
                                sort={sort}
                                onSortChanged={this.onSortChanged}
                                pagination={pagination}
                                onPaginationChanged={this.onPaginationChanged}
                                dateRange={dateRange}
                                onDateRangeChanged={this.onDateRangeChanged}
                                paypalService={this.props.paypalService}
                                ordersService={this.props.ordersService}
                            />
                        )}
                    />
                )}
                {this.props.hasPermission('edit_orders', accountSlug) && !hideCheckoutRoute && (
                    <Route
                        path={ORDER_PATHS.pagePaths.newOrderPath}
                        render={() => (
                            <InlineCheckout
                                history={this.props.history}
                                accountSlug={accountSlug}
                                loginService={this.props.loginService}
                            />
                        )}
                    />
                )}
                <Route render={() => <Redirect to={`/account/${accountSlug}/orders/transactions`} />} />
            </Switch>
        )
    }
}

function mapStateToProps(state: State) {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        user: state.auth.user,
    }
}

export default withFeatures(withPermission(withNavigation(connect(mapStateToProps)(OrdersListPage))))
