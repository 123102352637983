import React, { FC } from 'react'
import { ChevronUpSolid } from '@convious/icons'
import { InlineEdit } from 'uiComponents/input'
import { PageLoader } from 'uiComponents/loaders'
import './comment.scss'

type CommentProps = {
    comment?: { content: string; uuid?: string }
    onHandleSetComment: (content: string) => void
    isFetching: boolean
}

const Comment: FC<CommentProps> = ({ comment, isFetching, onHandleSetComment }) => {
    return (
        <details className="comment" open>
            <summary>
                <ChevronUpSolid />
                Notes
            </summary>
            {isFetching && <PageLoader />}
            {!isFetching && (
                <InlineEdit
                    className="comment__text"
                    placeholder="Add a note"
                    value={comment?.content || ''}
                    onEditAccept={onHandleSetComment}
                    maxLength={1000}
                    multiline
                    responsiveWidth
                    allowNull
                />
            )}
        </details>
    )
}

export default Comment
