import * as React from 'react'
import { TimesRegular } from '@convious/icons'
import { CancelRefundHeaderProps } from '../types'
import './styles.scss'

const CancelRefundHeader = (props: CancelRefundHeaderProps) => {
    const { title, subtitle, orderNumber, isAdminUser, customerEmail, visitDate, paypalRefundData, onClose } = props
    return (
        <div className="cancel-refund-header">
            <div className="title">
                <p className="title bold noMargin">{title}</p>
                <TimesRegular width={32} heigh={32} className="close-button" onClick={onClose} />
            </div>
            <div>
                {subtitle && <p className="body1 noMargin">{subtitle}</p>}
                {paypalRefundData && (
                    <>
                        <p className="body1 noMargin">
                            Case ID: <strong>{paypalRefundData.id}</strong>
                        </p>
                        {isAdminUser && (
                            <p className="body1 bold noMargin">
                                Partner: <span>{paypalRefundData.accountName}</span>
                                <span>{paypalRefundData.location}</span>
                            </p>
                        )}
                        <p className="body1 bold noMargin">
                            Reason: <strong>{paypalRefundData.reason}</strong>
                        </p>
                        <p className="body1 bold noMargin">
                            Requested refund: <strong>{paypalRefundData.refundAmount}</strong>
                        </p>
                    </>
                )}
                {orderNumber && <p className="body1 bold noMargin">Order ID: {orderNumber}</p>}
                {customerEmail && <p className="body1 noMargin customer-email" title={customerEmail}>{customerEmail}</p>}
                {visitDate && <p className="body1 noMargin">Visiting on {visitDate}</p>}
            </div>
        </div>
    )
}

export default CancelRefundHeader
