import { BaseRouteParams } from 'hocs'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { DiscountCode } from 'venue/bookingCodes/bookingCodesService'

interface DiscountReservoirNameCountProps {
    discountReservoirName?: DiscountCode['discountReservoirName']
}

const StyledLink = styled(UnstyledLink)`
    color: ${(props) => props.theme.colors.boyBlue};
`

const DiscountReservoirName: React.FC<DiscountReservoirNameCountProps> = ({ discountReservoirName }) => {
    const { accountSlug } = useParams<BaseRouteParams>()

    const path = `/account/${accountSlug}/venue/codes/discount/pools?search=${discountReservoirName}&searchBy=discount_reservoir_name`

    if (!discountReservoirName) {
        return <>-</>
    }

    return (
        <span>
            <StyledLink to={path} title={discountReservoirName}>
                {discountReservoirName}
            </StyledLink>
        </span>
    )
}

export default DiscountReservoirName
