import * as React from 'react'
import { Navigation } from 'navigation'
import { MessageProps, withMessages, withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { MessageKind, Messages } from 'uiComponents/messages'
import { StatsService } from 'http/statsService'
import MarketingEmailsSection from './marketingEmailsSection'
import { PageHeading, PageTitle } from 'uiComponents/typography'
import { DateRange, dateRangeFromQuery, dateRangeToQuery } from 'dateRanges'
import { ChartWrapperWithDateRange as WithDateRangeNotice, ReportsToolsWrapper, ReportUpdateInfo } from '../helpers'
import TransactionEmailsTable from './transactionEmailsTable'
import { EmailsData } from 'reports/schema'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { emailNameMap } from 'emailTemplates/schema'
import Disclaimer from 'reports/disclaimer'

interface EmailsReportParams {
    accountSlug: string
}

interface EmailsReportProps {
    accountSlug: string
    statsService: StatsService
    navigation: Navigation
    match: RouteMatch<EmailsReportParams>
    replaceMessages: (id: string, status: MessageKind, text: string) => void
}

class EmailsReport extends React.Component<EmailsReportProps & MessageProps> {
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    onDateRangeChanged = (range: DateRange) => {
        this.props.navigation.addQueryWithReplace(dateRangeToQuery(range))
    }

    mapEmailName = (dataSeries: EmailsData[]) => {
        const emailNames = {
            '{CART_PAID}': emailNameMap['CART_PAID'],
            '{CART_PAID_INPUT_REQUIRED}': emailNameMap['CART_PAID_INPUT_REQUIRED'],
            '{CART_RESERVATION}': emailNameMap['CART_RESERVATION'],
            '{CART_RESERVATION_INPUT_REQUIRED}': emailNameMap['CART_RESERVATION_INPUT_REQUIRED'],
            '{CART_REFUND}': emailNameMap['CART_REFUND'],
            '{CART_REMINDER}': emailNameMap['CART_REMINDER'],
            'Cart Reminder': emailNameMap['CART_REMINDER'],
            '{CART_REMINDER_II}': emailNameMap['CART_REMINDER_II'],
            '{CART_REMINDER_RTP}': emailNameMap['CART_REMINDER_RTP'],
            '{CART_PAID_INPUT_REQUIRED_REMINDER}': emailNameMap['CART_PAID_INPUT_REQUIRED_REMINDER'],
            '{CART_PRE_EVEN_NOTIFICATION}': emailNameMap['CART_PRE_EVEN_NOTIFICATION'],
            '{CART_POST_EVENT_NOTIFICATION}': emailNameMap['CART_POST_EVENT_NOTIFICATION'],
        }
        return dataSeries.map((ds) => {
            const mappedName = emailNames[ds.emailName]
            const emailName =
                mappedName ??
                ds.emailName
                    .replace(/[{}]/g, '')
                    .split(/[_ ]/g)
                    .map((w) => w[0] + w.toLowerCase().slice(1))
                    .join(' ')
            return { ...ds, emailName }
        })
    }

    render() {
        const { accountSlug } = this.props
        const query = this.props.navigation.query()
        const dateRange = dateRangeFromQuery(query)

        return (
            <div id="reports-emails-page">
                <Messages messages={this.props.messages} hideMessage={this.props.hideMessage} />
                <PageTitle data-userpilot="emails-header-tooltip">Emails Report</PageTitle>
                <PageHeading fullWidth>
                    <div>
                        View all insights delivered by the emails sent to your visitors. From transactional to marketing
                        emails, you can find a summary of how all user interactions. Use the date picker to explore
                        specific date ranges.
                    </div>
                    <div>
                        Find more information in our{' '}
                        <a target="_blank" href="https://support.convious.com/help/email-report" rel="noreferrer">
                            Knowledge Base
                        </a>{' '}
                        for more information on this report.
                    </div>
                </PageHeading>
                <ReportsToolsWrapper style={{ marginBottom: '0rem' }}>
                    <ReportUpdateInfo metric="Data" text="updated every 1 hour." />
                    <DateRangePicker
                        range={dateRange}
                        onChange={this.onDateRangeChanged}
                        userpilot="emails-date-range"
                        firstAvailableDay={new Date(2019, 0, 1)}
                    />
                </ReportsToolsWrapper>
                <MarketingEmailsSection
                    statsService={this.props.statsService}
                    accountSlug={accountSlug}
                    dateRange={dateRange}
                    replaceTopMessages={this.props.replaceMessages}
                    mapEmailName={this.mapEmailName}
                />
                <div style={{ marginTop: '3em' }}>
                    <WithDateRangeNotice dateRange={dateRange} userpilot="emails-transactional-table">
                        <TransactionEmailsTable
                            title="Transactional Emails"
                            statsService={this.props.statsService}
                            accountSlug={accountSlug}
                            dateRange={dateRange}
                            replaceTopMessages={this.props.replaceMessages}
                            mapEmailName={this.mapEmailName}
                        />
                    </WithDateRangeNotice>
                </div>
                <Disclaimer />
            </div>
        )
    }
}

export default withMessages(withNavigation(EmailsReport))
