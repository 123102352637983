import React, { FC, useCallback } from 'react'
import { Button } from '@mui/material'
import { getSelectedOrdersDetails } from 'orders/orders/redux'
import { useAppSelector } from 'store/hooks'
import { useHistory } from 'react-router-dom'
import { getActiveAccount } from 'preferences/selectors'
import { useHasFeature } from 'features'
import { EmailSentSolid } from '@convious/icons'

export const SentEmailsButton: FC = () => {
    const history = useHistory()
    const accountSlug = useAppSelector(getActiveAccount)
    const selectedOrders = useAppSelector(getSelectedOrdersDetails)
    const canSeeSentEmails = useHasFeature('show_sent_emails')

    const openSentEmails = useCallback(
        (id: string) => {
            if (!accountSlug) throw new Error('No account selected')

            history.push(`/account/${accountSlug}/orders/${id}/sent_emails`)
        },
        [history, selectedOrders],
    )

    if (!canSeeSentEmails || selectedOrders.length !== 1 || !selectedOrders[0]) {
        return null
    }

    return (
        <Button
            size="medium"
            color="primary"
            variant="outlined"
            className="action-button"
            onClick={() => openSentEmails(selectedOrders[0].id)}
            startIcon={<EmailSentSolid />}
        >
            Sent emails
        </Button>
    )
}
