export enum OperatingSystem {
    MacOS = 'MacOS',
    Windows = 'Windows',
    Linux = 'Linux',
    Mobile = 'Mobile',
    Unknown = 'Unknown',
}

export const getOperatingSystem = (): OperatingSystem => {
    const platform = navigator.platform || 'unknown'
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera || 'unknown'

    if (/Win/.test(platform)) {
        return OperatingSystem.Windows
    }

    if (/Mac/.test(platform)) {
        return OperatingSystem.MacOS
    }

    if (/Linux/.test(platform)) {
        return OperatingSystem.Linux
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return OperatingSystem.Mobile
    }

    if (/Android/i.test(userAgent)) {
        return OperatingSystem.Mobile
    }

    if (/Mobile|mobile/i.test(userAgent)) {
        return OperatingSystem.Mobile
    }

    return OperatingSystem.Unknown
}

export const isMacOS = () => getOperatingSystem() === OperatingSystem.MacOS
