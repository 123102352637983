import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormWrapper } from 'uiComponents/form/form'
import FieldRow from './fieldRow'
import { InputCard } from 'uiComponents/cards'
import { CheckboxFieldFormik, SingleSelectFieldFormik, SliderFieldFormik } from 'uiComponents/input'
import GPSStudioLayout from './layout'
import { BaseRouteParams } from 'hocs'
import {
    useGetDefaultIndustrySettingsQuery,
    useGetGlobalAccountSettingsQuery,
    useSaveGlobalAccountSettingsMutation,
} from 'products/pricing/reduxQueries'
import { LoaderWrapper } from 'uiComponents/loaders'
import { Col, Row } from 'uiComponents/flex'
import { Body, Caption, Headline } from 'uiComponents/typography'
import NumberInputFieldFormik from 'uiComponents/input/numberInput/numberInputFieldFormik'
import { GlobalAccountSettings } from 'products/pricing/pricingService'
import ToggleFieldFormik from 'uiComponents/input/toggle/toggleFieldFormik'
import { useDispatch } from 'react-redux'
import { replaceMessage } from 'uiComponents/messages/actions'
import { useGetPricingModels } from './pricingModels'
import { pricerFormValidationSchema } from './formValidation'
import { FormikProps, FormikValues, useField, useFormikContext } from 'formik'
import PricingGoalSelectOption from './pricingGoalSelectOption'
import StudioProvider from './provider/provider'
import { convertTimeFormat } from 'utils'
import { useAppSelector } from 'store/hooks'
import { getIsUSPartner } from 'auth/selectors'

const venueTypeOptions = [
    { value: 3, name: 'Default' },
    { value: 1, name: 'Indoor' },
    { value: 5, name: 'Outdoor' },
] as any[]

const priceChangeLabels = {
    1: 'Big increase',
    2: 'Increase',
    3: 'Default',
    4: 'Decrease',
    5: 'Big decrease',
}

interface VisitHoursLabelsProps {
    quarterName: string
    quarterTime: string
}

const VisitHoursLabels: React.FC<VisitHoursLabelsProps> = ({ quarterName, quarterTime }) => {
    return (
        <div style={{ width: '100%' }}>
            <Caption style={{ fontSize: '.625rem', lineHeight: '0.75rem', fontWeight: 500 }} color="light">
                {quarterName}
            </Caption>
            <Caption style={{ fontSize: '.625rem', lineHeight: '0.75rem' }} color="light">
                {quarterTime}
            </Caption>
        </div>
    )
}

const emptyObject = {}

const getInitialValues = (
    accountSettings?: GlobalAccountSettings,
    defaultSettings?: GlobalAccountSettings,
): GlobalAccountSettings | null => {
    if (accountSettings) {
        return accountSettings
    }

    if (defaultSettings) {
        return defaultSettings
    }

    return null
}

const weekdays = [
    { index: 0, name: 'Mon' },
    { index: 1, name: 'Tue' },
    { index: 2, name: 'Wed' },
    { index: 3, name: 'Thu' },
    { index: 4, name: 'Fri' },
    { index: 5, name: 'Sat' },
    { index: 6, name: 'Sun' },
]

const us_weekdays = [
    { index: 6, name: 'Sun' },
    { index: 0, name: 'Mon' },
    { index: 1, name: 'Tue' },
    { index: 2, name: 'Wed' },
    { index: 3, name: 'Thu' },
    { index: 4, name: 'Fri' },
    { index: 5, name: 'Sat' },
]

interface UpdateIndustryDefaultsProps {
    setIndustryDefaults: Dispatch<SetStateAction<GlobalAccountSettings | undefined>>
}

const UpdateIndustryDefaults = ({ setIndustryDefaults }: UpdateIndustryDefaultsProps) => {
    const [{ value: pricingGoal }] = useField('pricingGoal')
    const { values, setFieldValue } = useFormikContext<GlobalAccountSettings>()
    const pricingGoalOptions = useGetPricingModels()
    const selectedPricingGoal = pricingGoal || pricingGoalOptions?.[0]?.value

    const { data: industryDefaults } = useGetDefaultIndustrySettingsQuery({
        modelId: selectedPricingGoal,
        version: 'V2',
    })

    useEffect(() => {
        if (industryDefaults) {
            setIndustryDefaults(() => {
                const updatedDefaults: GlobalAccountSettings = {
                    ...industryDefaults,
                    pricingGoal: selectedPricingGoal,
                }

                Object.keys(updatedDefaults).forEach((key: keyof GlobalAccountSettings) => {
                    if (values[key] === undefined) {
                        setFieldValue(key, updatedDefaults[key])
                    }
                })

                return updatedDefaults
            })
        }
    }, [industryDefaults, selectedPricingGoal, setFieldValue, values])

    return null
}

const GPSStudio = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { data, isFetching } = useGetGlobalAccountSettingsQuery({ accountSlug })
    const [industryDefaults, setIndustryDefaults] = useState<GlobalAccountSettings>()
    const [saveAccountSettings] = useSaveGlobalAccountSettingsMutation()
    const dispatch = useDispatch()
    const pricingGoalOptions = useGetPricingModels()
    const isUSPartner = useAppSelector(getIsUSPartner)
    const formikRef = useRef<FormikProps<FormikValues>>(null)

    const initialValues: Partial<GlobalAccountSettings> = useMemo(() => {
        return getInitialValues(data?.settings, industryDefaults) ?? emptyObject
    }, [data, industryDefaults])

    const submitValues = ({ features, ...values }: GlobalAccountSettings) => {
        saveAccountSettings({
            accountSlug,
            accountSettings: {
                ...values,
                avgDaysTicketsBoughtInAdvance: Number(values.avgDaysTicketsBoughtInAdvance),
            },
        })
            .unwrap()
            .then((response) => {
                if (response) {
                    dispatch(
                        replaceMessage(
                            'success_gps_update',
                            'success',
                            'Global price settings successfully updated',
                            5000,
                        ),
                    )
                } else {
                    dispatch(replaceMessage('error_gps_update', 'error', 'Something went wrong please try again', 5000))
                }
            })
    }

    const handleRewardEarlyBookingsToggle = (value: boolean) => {
        formikRef.current?.setFieldValue('earlyBookingsImpact', initialValues?.earlyBookingsImpact)
        formikRef.current?.setFieldValue('rewardEarlyBookings', value)
    }

    const handleChargeLateBookingsToggle = (value: boolean) => {
        formikRef.current?.setFieldValue('lateBookingsImpact', initialValues?.lateBookingsImpact)
        formikRef.current?.setFieldValue('chargeLateBookings', value)
    }

    return (
        <LoaderWrapper loading={isFetching}>
            <FormWrapper
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => submitValues(values)}
                validationSchema={pricerFormValidationSchema}
                style={{ width: '100%' }}
                innerRef={formikRef}
            >
                <StudioProvider accountSlug={accountSlug}>
                    <GPSStudioLayout>
                        <UpdateIndustryDefaults setIndustryDefaults={setIndustryDefaults} />
                        <InputCard style={{ marginBottom: '1.5rem' }}>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="Your venue's goal"
                                helperText="This optimizes pricing towards your goal."
                            >
                                <SingleSelectFieldFormik
                                    id="pricingGoal"
                                    name="pricingGoal"
                                    SingleSelectOption={PricingGoalSelectOption}
                                    options={pricingGoalOptions}
                                />
                            </FieldRow>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="Venue type"
                                helperText="This defines the impact certain weather has on your venue."
                            >
                                <SingleSelectFieldFormik
                                    id="venueTypeOutdoor"
                                    name="venueTypeOutdoor"
                                    options={venueTypeOptions}
                                />
                            </FieldRow>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="Preferred weather type"
                                helperText="This defines which weather is most favorable for your venue."
                            >
                                <SliderFieldFormik
                                    name="rainImpact"
                                    id="rainImpact"
                                    min={1}
                                    max={5}
                                    step={1}
                                    minLabel="Rain"
                                    maxLabel="Dry"
                                    recommendedValue={industryDefaults?.rainImpact}
                                    recommendedValueDescription="Default setting, based on best practice experience"
                                />
                            </FieldRow>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="Preferred temperature"
                                helperText="This defines which temperatures are most favorable for your venue."
                            >
                                <SliderFieldFormik
                                    name="temperatureImpact"
                                    id="temperatureImpact"
                                    min={1}
                                    max={5}
                                    step={1}
                                    minLabel="Low"
                                    maxLabel="High"
                                    recommendedValue={industryDefaults?.temperatureImpact}
                                    recommendedValueDescription="Default setting, based on best practice experience"
                                />
                            </FieldRow>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="National holidays"
                                helperText="This defines the adjustment of national and regional school holidays."
                            >
                                <SliderFieldFormik
                                    name="holidayImportance"
                                    id="holidayImportance"
                                    min={1}
                                    max={5}
                                    step={1}
                                    minLabel="Lower"
                                    maxLabel="Higher"
                                    recommendedValue={industryDefaults?.holidayImportance}
                                    recommendedValueDescription="Default setting, based on best practice experience"
                                />
                            </FieldRow>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="International holidays"
                                helperText="This defines the adjustment of international/neighbouring holidays in a radius of 250km."
                            >
                                <SliderFieldFormik
                                    name="audienceLocation"
                                    id="audienceLocation"
                                    min={1}
                                    max={5}
                                    step={1}
                                    minLabel="Lower"
                                    maxLabel="Higher"
                                    recommendedValue={industryDefaults?.audienceLocation}
                                    recommendedValueDescription="Default setting, based on best practice experience"
                                />
                            </FieldRow>
                        </InputCard>

                        <InputCard style={{ marginBottom: '1.5rem' }}>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="Gate price on visit day"
                                helperText="This will match the online price with the box office price (gate price) on the day itself."
                            >
                                <ToggleFieldFormik
                                    id="boxOfficePriceOnVisitDate"
                                    style={{ fontSize: '9px' }}
                                    name="boxOfficePriceOnVisitDate"
                                />
                            </FieldRow>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="Reward early bookings"
                                helperText="Give discounts for early bookings to encourage visitors book in advance."
                            >
                                <ToggleFieldFormik
                                    id="rewardEarlyBookings"
                                    style={{ fontSize: '9px', marginBottom: '0.65rem' }}
                                    name="rewardEarlyBookings"
                                    onClick={handleRewardEarlyBookingsToggle}
                                />
                                {formikRef.current?.values.rewardEarlyBookings && (
                                    <SliderFieldFormik
                                        name="earlyBookingsImpact"
                                        min={1}
                                        max={5}
                                        step={1}
                                        minLabel="Low discount"
                                        maxLabel="High discount"
                                        recommendedValue={industryDefaults?.earlyBookingsImpact}
                                        recommendedValueDescription="Default setting, based on best practice experience"
                                    />
                                )}
                            </FieldRow>
                            <FieldRow bottomSpacing="2rem" title="Early means a minimum of:">
                                <Row vAlign="center" gutter={8}>
                                    <Col span={3}>
                                        <NumberInputFieldFormik
                                            style={{ width: '100%' }}
                                            name="avgDaysTicketsBoughtInAdvance"
                                            min={1}
                                            maxLength={3}
                                            integerOnly
                                        />
                                    </Col>
                                    <Col span="auto">
                                        <Caption>Days in advance</Caption>
                                    </Col>
                                </Row>
                            </FieldRow>
                            <FieldRow
                                bottomSpacing="2rem"
                                title="Charge late bookings"
                                helperText="Charge higher prices for late bookings to get more revenue from last minute purchases."
                            >
                                <ToggleFieldFormik
                                    id="chargeLateBookings"
                                    style={{ fontSize: '9px', marginBottom: '0.65rem' }}
                                    name="chargeLateBookings"
                                    onClick={handleChargeLateBookingsToggle}
                                />
                                {formikRef.current?.values.chargeLateBookings && (
                                    <SliderFieldFormik
                                        name="lateBookingsImpact"
                                        min={1}
                                        max={5}
                                        step={1}
                                        minLabel="Low charge"
                                        maxLabel="High charge"
                                        recommendedValue={industryDefaults?.lateBookingsImpact}
                                        recommendedValueDescription="Default setting, based on best practice experience"
                                    />
                                )}
                            </FieldRow>
                        </InputCard>

                        <InputCard style={{ marginBottom: '1.5rem', width: 'fit-content' }}>
                            <Headline style={{ margin: 0 }} size={6}>
                                Define popular visiting hours
                            </Headline>
                            <Caption style={{ marginBottom: '1.5rem' }}>
                                This influences pricing based on popular times.
                            </Caption>
                            <Row style={{ marginBottom: '1.5rem' }}>
                                <Col
                                    span="auto"
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'flex-end',
                                        marginBottom: '1rem',
                                        paddingRight: '1.5rem',
                                        maxWidth: 120,
                                    }}
                                >
                                    <div style={{ width: '100%', height: 35 }} />
                                    <VisitHoursLabels
                                        quarterName="Morning"
                                        quarterTime={`(${convertTimeFormat('09:00')} - ${convertTimeFormat('12:00')})`}
                                    />
                                    <VisitHoursLabels
                                        quarterName="Afternoon"
                                        quarterTime={`(${convertTimeFormat('12:00')} - ${convertTimeFormat('16:00')})`}
                                    />
                                    <VisitHoursLabels
                                        quarterName="Evening"
                                        quarterTime={`(${convertTimeFormat('16:00')} - ${convertTimeFormat('20:00')})`}
                                    />
                                    <VisitHoursLabels
                                        quarterName="Night"
                                        quarterTime={`(${convertTimeFormat('20:00')} - ${convertTimeFormat('00:00')})`}
                                    />
                                </Col>
                                {(isUSPartner ? us_weekdays : weekdays).map(({ index, name }) => {
                                    return (
                                        <Col
                                            key={name}
                                            span="auto"
                                            style={{ maxWidth: '60px', marginRight: '1rem', padding: 0 }}
                                        >
                                            <InputCard style={{ padding: '1rem' }}>
                                                <Caption>{name}</Caption>
                                                <div style={{ marginBottom: '1rem' }} />
                                                <CheckboxFieldFormik
                                                    defaultValue={undefined}
                                                    value="morning"
                                                    name={`daytimeImportance[${index}]`}
                                                />
                                                <div style={{ marginBottom: '1rem' }} />
                                                <CheckboxFieldFormik
                                                    value="afternoon"
                                                    name={`daytimeImportance[${index}]`}
                                                />
                                                <div style={{ marginBottom: '1rem' }} />
                                                <CheckboxFieldFormik
                                                    value="evening"
                                                    name={`daytimeImportance[${index}]`}
                                                />
                                                <div style={{ marginBottom: '1rem' }} />
                                                <CheckboxFieldFormik
                                                    value="night"
                                                    name={`daytimeImportance[${index}]`}
                                                />
                                                <div style={{ marginBottom: '1rem' }} />
                                            </InputCard>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </InputCard>
                        <InputCard style={{ paddingBottom: '1.5rem', marginBottom: '1.5rem', width: 'fit-content' }}>
                            <Headline style={{ margin: 0 }} size={6}>
                                Define price adjustment per weekday
                            </Headline>
                            <Caption style={{ marginBottom: '1.5rem' }}>
                                This influences pricing based on popular weekdays.
                            </Caption>
                            <Row gutter={16}>
                                <Col
                                    span="auto"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        flexWrap: 'wrap',
                                        height: '136px',
                                        margin: '1rem 1.5rem 0 0',
                                    }}
                                >
                                    {Object.entries(priceChangeLabels).map(([key, val]) => (
                                        <Body size={3} color="light" key={key}>
                                            {val}
                                        </Body>
                                    ))}
                                </Col>
                                {(isUSPartner ? us_weekdays : weekdays).map(({ name, index }) => (
                                    <Col key={name} span="auto">
                                        <InputCard
                                            style={{
                                                padding: '1rem .45rem',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                                width: 52,
                                            }}
                                        >
                                            <SliderFieldFormik
                                                style={{ height: 136 }}
                                                min={1}
                                                max={5}
                                                step={1}
                                                recommendedValue={industryDefaults?.weekdayImportance?.[index]}
                                                name={`weekdayImportance.[${index}]`}
                                                orientation="vertical"
                                            />
                                            <Caption
                                                style={{
                                                    marginTop: '.5rem',
                                                    textAlign: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                {name}
                                            </Caption>
                                        </InputCard>
                                    </Col>
                                ))}
                            </Row>
                        </InputCard>
                        <InputCard
                            style={{
                                marginBottom: '5.75rem',
                                paddingBottom: '1.5rem',
                                maxWidth: 'max-content',
                            }}
                        >
                            <Headline style={{ margin: 0, width: 'fit-content' }} size={6}>
                                Define price adjustment per month
                            </Headline>
                            <Caption style={{ marginBottom: '1.5rem', width: 'fit-content' }}>
                                This influences pricing based on popular months.
                            </Caption>
                            <Row gutter={8} style={{ width: 'fit-content' }}>
                                <Col
                                    span="auto"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        flexWrap: 'wrap',
                                        height: '136px',
                                        margin: '1rem 1.5rem 0 0',
                                    }}
                                >
                                    {Object.entries(priceChangeLabels).map(([key, val]) => (
                                        <Body size={3} color="light" key={key}>
                                            {val}
                                        </Body>
                                    ))}
                                </Col>
                                {[
                                    'Jan',
                                    'Feb',
                                    'Mar',
                                    'Apr',
                                    'May',
                                    'Jun',
                                    'Jul',
                                    'Aug',
                                    'Sep',
                                    'Oct',
                                    'Nov',
                                    'Dec',
                                ].map((month, index) => {
                                    return (
                                        <Col key={month} span="auto" style={{ maxWidth: '52px', marginRight: '.5rem' }}>
                                            <InputCard
                                                style={{
                                                    padding: '1rem .45rem',
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'center',
                                                    width: 52,
                                                }}
                                            >
                                                <SliderFieldFormik
                                                    style={{ height: 136 }}
                                                    min={1}
                                                    max={5}
                                                    step={1}
                                                    recommendedValue={industryDefaults?.monthImportance?.[index]}
                                                    name={`monthImportance.[${index}]`}
                                                    orientation="vertical"
                                                />
                                                <Caption
                                                    style={{
                                                        marginTop: '.5rem',
                                                        textAlign: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {month}
                                                </Caption>
                                            </InputCard>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </InputCard>
                    </GPSStudioLayout>
                </StudioProvider>
            </FormWrapper>
        </LoaderWrapper>
    )
}

export default GPSStudio
