import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AppServices } from 'middleware'
import { getGroups, getItems } from '../utils'
import { TimelineGroup, TimelineItem } from '../types'
import { GetCapacityReportQueryParams } from '../capacityReportService'
import { OpeningTimesListItem } from 'venue/openingTimes/openingTimesService'

type GetCapacityReportData = {
    items: TimelineItem[]
    groups: TimelineGroup[]
    openingTime?: OpeningTimesListItem
    timezone?: string
}

export const getCapacityReportData = createAsyncThunk<
    GetCapacityReportData,
    { accountSlug: string } & GetCapacityReportQueryParams,
    { rejectValue: string }
>('capacityReport/get', async (params, { rejectWithValue }) => {
    try {
        const { availability, resources, timezone, ...rest } =
            await AppServices.capacityReportService.getCapacityReport(params)

        const groups = getGroups(resources)
        const items = getItems(availability, timezone)

        return {
            groups,
            items,
            timezone,
            ...rest,
        }
    } catch (error) {
        return rejectWithValue(error instanceof Error ? error.message : 'An unknown error occurred')
    }
})

type CapacityReportState = GetCapacityReportData & {
    isLoading: boolean
    isReady: boolean
    error: string | null
}

const initialState: CapacityReportState = {
    isLoading: false,
    isReady: false,
    items: [],
    groups: [],
    error: null,
}

const capacityReportSlice = createSlice({
    name: 'capacityReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCapacityReportData.pending, (state) => {
                state.isLoading = true
                state.isReady = false
                state.items = []
                state.groups = []
                state.error = null
                state.timezone = undefined
            })
            .addCase(getCapacityReportData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isReady = true
                state.openingTime = action.payload.openingTime
                state.groups = action.payload.groups
                state.items = action.payload.items
                state.timezone = action.payload.timezone
            })
            .addCase(getCapacityReportData.rejected, (state, action) => {
                state.isLoading = false
                state.isReady = false
                state.error = action.payload ?? 'An unknown error occurred'
            })
    },
})

export default capacityReportSlice.reducer
