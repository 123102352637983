import * as React from 'react'
import { FormItem } from 'uiComponents/form/formElements'
import { TextArea } from 'uiComponents/input'
import { CancelRefundNotesProps } from '../types'
import './styles.scss'

const CancelRefundNotes = (props: CancelRefundNotesProps) => {
    const { cancelRefundReason, handleTextAreaChange, isCancellation } = props

    return (
        <FormItem className="cancel-refund-notes" htmlFor="request">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <p className='body1 noMargin'>Notes (not visible to the customer)</p>
                <p className='body1 noMargin secondary'>Optional</p>
            </div>
            <TextArea
                className="text-area"
                maxLength={4000}
                placeholder={`Why are you issuing this ${isCancellation ? 'cancellation?' : 'refund?'}`}
                value={cancelRefundReason ? cancelRefundReason : ''}
                onChange={handleTextAreaChange}
            />
        </FormItem>
    )
}

export default CancelRefundNotes
