import * as React from 'react'
import SearchBar, { getSearchByOptionByValue, SearchBarProps } from './searchBar'
import { withNavigation } from 'hocs'
import './searchBarWithSubtitle.scss'

interface SearchBarWithSubtitleProps extends SearchBarProps {
    loading?: boolean
}

const SearchBarWithSubtitle = (props: SearchBarWithSubtitleProps) => {
    const { loading, searchByOptions, navigation } = props
    const query = navigation.query()
    const searchByOption = getSearchByOptionByValue(searchByOptions, query.searchBy)
    const subtitle = `Showing results for ${searchByOption?.name} "${query.search}"`

    return (
        <div className="search-bar-with-subtitle">
            <SearchBar {...props} />
            <div className="search-bar-subtitle">{!loading && query.search ? subtitle : ''}</div>
        </div>
    )
}

export default withNavigation(SearchBarWithSubtitle)
