import { MetaData, FilterCategory } from 'uiComponents/filter/schema'
import { formatFlatOptions } from 'uiComponents/filter/filterHelpers'

interface ApplicableFiltersConfig {
    orders: FilterCategory[]
    tickets: FilterCategory[]
}

export const applicableFilters: ApplicableFiltersConfig = {
    orders: ['products', 'payment_method', 'channels', 'language', 'location_id', 'email_status', 'status'],
    tickets: ['products', 'payment_method', 'channels', 'language'],
}

const categoryOptions = {
    payment_method: [
        { name: 'Amex', slug: 'amex' },
        { name: 'Bancontact', slug: 'bancontact' },
        { name: 'Banktransfer', slug: 'banktransfer' },
        { name: 'BCMC', slug: 'bcmc' },
        { name: 'Belfius', slug: 'belfius' },
        { name: 'Convious', slug: 'convious' },
        { name: 'Credit Card', slug: 'creditcard' },
        { name: 'Direct E-Banking', slug: 'directEbanking' },
        { name: 'EBanking FI', slug: 'ebanking_FI' },
        { name: 'Entercash', slug: 'entercash' },
        { name: 'EPS', slug: 'eps' },
        { name: 'Giftcard', slug: 'giftcard' },
        { name: 'Giropay', slug: 'giropay' },
        { name: 'Ideal', slug: 'ideal' },
        { name: 'Inghomepay', slug: 'inghomepay' },
        { name: 'KBC', slug: 'kbc' },
        { name: 'MC', slug: 'mc' },
        { name: 'Paypal', slug: 'paypal' },
        { name: 'Przelewy24', slug: 'przelewy24' },
        { name: 'Sofort', slug: 'sofort' },
        { name: 'Visa', slug: 'visa' },
    ],
    email_status: [
        { name: 'Sending', slug: 'sending' },
        { name: 'Processed', slug: 'processed' },
        { name: 'Delivered', slug: 'delivered' },
        { name: 'Opened', slug: 'opened' },
        { name: 'Clicked', slug: 'clicked' },
        { name: 'Deferred', slug: 'deferred' },
        { name: 'Bounced', slug: 'bounced' },
        { name: 'Dropped', slug: 'dropped' },
    ],
    ticket_status: [
        { name: 'Paid', slug: 'paid' },
        { name: 'Refunded', slug: 'refunded' },
        { name: 'Charged back', slug: 'charged_back' },
        { name: 'Redeemed', slug: 'redeemed' },
    ],
    apf_status: [
        { name: 'Order APF completed', slug: 'apf_completed' },
        { name: 'Order APF data missing', slug: 'apf_missing' },
    ],
    redeem_status: [
        { name: 'Redeemed', slug: 'redeemed' },
        { name: 'Not redeemed', slug: 'not_redeemed' },
    ],
    print_status: [
        { name: 'Printed', slug: 'printed' },
        { name: 'Not printed', slug: 'not_printed' },
    ],
    status: [
        { name: 'Confirmed', slug: 'CONFIRMED' },
        { name: 'Cancelled', slug: 'CANCELED' },
        { name: 'Open', slug: 'OPEN' },
    ],
}

export const staticMetadaCategories: MetaData[] = [
    {
        category: 'payment_method' as FilterCategory,
        options: formatFlatOptions('payment_method', categoryOptions.payment_method),
    },
    {
        category: 'email_status' as FilterCategory,
        options: formatFlatOptions('email_status', categoryOptions.email_status),
    },
    {
        category: 'ticket_status' as FilterCategory,
        options: formatFlatOptions('ticket_status', categoryOptions.ticket_status),
    },
    {
        category: 'apf_status' as FilterCategory,
        options: formatFlatOptions('apf_status', categoryOptions.apf_status),
    },
    {
        category: 'redeem_status' as FilterCategory,
        options: formatFlatOptions('redeem_status', categoryOptions.redeem_status),
    },
    {
        category: 'print_status' as FilterCategory,
        options: formatFlatOptions('print_status', categoryOptions.print_status),
    },
    {
        category: 'status' as FilterCategory,
        options: formatFlatOptions('status', categoryOptions.status),
    },
]
