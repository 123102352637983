/* eslint-disable id-denylist */
import { defaultPageSize } from 'uiComponents/table/pagination'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'
import { OptionItem } from 'products/options/optionsService'
import { StatusType } from 'uiComponents/typography'

interface StatusDescriptor {
    text: string
    style: StatusType
}

export type PaymentStatus =
    | 'open'
    | 'pending'
    | 'cancelled'
    | 'expired'
    | 'paid'
    | 'refunded'
    | 'failed'
    | 'charged_back'
    | 'unpaid'

export type OrderStatus =
    | 'paid'
    | 'confirmed'
    | 'booked'
    | 'canceled'
    | 'cancelled'
    | 'refunded'
    | 'refunding'
    | 'charged_back'
    | 'charged back'
    | 'redeemed'
    | 'not_redeemed'
    | 'open'
    | 'failed'
    | 'pending'
    | 'partial'
    | 'partially_canceled'
    | 'partially_cancelled'
    | 'partially_refunded'
    | 'partially_redeemed'
    | 'open_reservation_failed'
    | 'confirmed_reservation_failed'
    | 'open_barcodes_failed'
    | 'confirmed_barcodes_failed'
    | 'unknown'

export type CartStatus = 'open' | 'pending' | 'initiated' | 'overdue' | 'expired' | 'cancelled' | 'completed'

export const statusDescriptors: { [key in OrderStatus | PaymentStatus | CartStatus]: StatusDescriptor } = {
    paid: { text: 'Paid', style: 'success' },
    confirmed: { text: 'Confirmed', style: 'success' },
    booked: { text: 'Booked', style: 'success' },
    cancelled: { text: 'Cancelled', style: 'info' },
    canceled: { text: 'Cancelled', style: 'info' },
    refunded: { text: 'Refunded', style: 'info' },
    refunding: { text: 'Refunding...', style: 'processing' },
    charged_back: { text: 'Charged back', style: 'info' },
    'charged back': { text: 'Charged back', style: 'info' }, // BO issues, need to check if this is valid
    redeemed: { text: 'Redeemed', style: 'success' },
    not_redeemed: { text: 'Not redeemed', style: 'processing' },
    open: { text: 'Open', style: 'processing' },
    failed: { text: 'Failed', style: 'error' },
    pending: { text: 'Pending', style: 'processing' },
    partial: { text: 'Partial', style: 'processing' },
    partially_canceled: { text: 'Partially cancelled', style: 'info' }, // temp until typo removed from BO
    partially_cancelled: { text: 'Partially cancelled', style: 'info' },
    partially_refunded: { text: 'Partially refunded', style: 'info' },
    partially_redeemed: { text: 'Partially redeemed', style: 'processing' },
    open_reservation_failed: { text: 'Open - reservation failed', style: 'error' },
    confirmed_reservation_failed: { text: 'Confirmed - reservation failed', style: 'error' },
    open_barcodes_failed: { text: 'Open - barcodes failed', style: 'error' },
    confirmed_barcodes_failed: { text: 'Confirmed - barcodes failed', style: 'error' },
    unknown: { text: 'Unknown', style: 'error' },
    unpaid: { text: 'Unpaid', style: 'info' },
    expired: { text: 'Expired', style: 'error' },
    initiated: { text: 'Initiated', style: 'processing' },
    overdue: { text: 'Overdue', style: 'error' },
    completed: { text: 'Completed', style: 'success' },
}

export const getStatusDescriptor = (status: string): StatusDescriptor => {
    const statusDescriptor = statusDescriptors[status as OrderStatus]

    if (!statusDescriptor) {
        return statusDescriptors['unknown']
    }

    return statusDescriptor
}

export const specifyOrderStatus = (order: IOrder): OrderStatus => {
    const reservationWithError = Object.values(order.confirmReservationsResult || {}).find(
        (reservation) => !!reservation.error,
    )

    if (order.status === 'open' || order.status === 'confirmed') {
        if (reservationWithError) {
            return `${order.status}_reservation_failed`
        } else if (order.issuedBarcodesResult?.error) {
            return `${order.status}_barcodes_failed`
        }
    }

    return (order.detailedStatus || order.status) as OrderStatus
}

export const ticketStatusesDescriptors: { [key: string]: StatusDescriptor } = {
    unpaid: { text: 'Unpaid', style: 'info' },
    cancelled: { text: 'Cancelled', style: 'info' },
    refund_in_progress: { text: 'Refund in progress', style: 'processing' },
    paid: { text: 'Paid', style: 'success' },
    refunded: { text: 'Refunded', style: 'info' },
    unknown: { text: 'Unknown', style: 'error' },
}

export type OrderTicketStatus = keyof typeof ticketStatusesDescriptors

export const getOrderTicketStatus = (status: OrderTicketStatus): StatusDescriptor => {
    const statusObj = ticketStatusesDescriptors[status]

    if (!statusObj) {
        return ticketStatusesDescriptors['unknown']
    }

    return statusObj
}

export const defaultPageData = {
    entries: [],
    totalCount: 0,
    currentPage: 1,
    nextPage: null,
    prevPage: null,
    pageSize: defaultPageSize,
    userFieldConfig: {
        enabled: false,
        fields: [],
    },
}

export interface Barcode {
    barcode: string
    redeemed: string | null
    refunded: boolean
    canRefund: boolean
    status: string
}

export interface TicketEntity {
    id: number
    uuid: string
    invalidatedAt: string | null
    status: string
    barcode: Barcode | null
    canRefund: boolean
    price: number
}

export interface Customer {
    email: string
    language: string
}

export interface Ticket {
    orderItemId: number
    orderItemUuid: string
    product: string
    quantity: number
    pricePerItem: number
    pricePerItemAfterDiscount: number
    freeItems: number
    isBundle: boolean
    groupedTicketEntities: TicketEntity[][]
    canRefund: boolean
    bundleItemPrices: number[] | null
}

export interface TicketItemForRefund {
    ticketEntities: TicketEntity[]
    ticketUuids: string[]
    barcodeList: string[]
    status: string
    itemRefundable: boolean
    price: number
}

export interface TicketForRefund extends Ticket {
    items: TicketItemForRefund[]
    status: string
}

export interface Order {
    id: string
    number: string
    uuid: string
    status: string
    orderStatus: string
    paymentStatus: string
    customer: Customer
    orderDate: string
    paymentDate: string
    visitDate: string | null
    visitTime: string | null
    tickets: Ticket[]
    ticketDownloadUrl: string
    total: number
    totalAfterDiscount: number
    discountAmount: number
    discountCode: string
    quantity: number
    canRefund: boolean
    canPartialRefund: boolean
    location: string
    partnerRefundFee: number | null
    partnerRefundFeeType: RefundFeeType | null
    emailStatus: string
    resellerName: string | null
    paymentProvider: string
    unfilledApf: boolean
    downloaded: string | null
    paymentMethod: string | null
    items?: OptionItem[]
}

export interface OrderDetailsRestSchema extends Omit<OrderDetails, 'barcode'> {
    barcode?: Barcode
}
export interface Page {
    totalCount: number
    prevPage: number | null
    nextPage: number | null
    currentPage: number
    pageSize: number
}

export interface OrderPage extends Page {
    entries: Order[]
}

export interface TransactionsQueryResponse {
    orders: OrderPage
    openDisputeCount: number
}

export interface UserField {
    name: string
    value: string
    type: string
    label: string
    placeholder: string
    isRequired: boolean
    editable: boolean
    repeatable: boolean
}

export interface ConfigUserField {
    name: string
    articles: string[]
    repeatable: boolean
    type: string
    label: string
    placeholder: string
    required: boolean
}

export interface OrderItemOptions {
    name: string
    numberOfItems: number
    price: number
}

export interface OrderDetails {
    id: string
    barcode: string
    orderId: string
    orderItemId: string
    orderUuid: string
    orderEmail: string
    product: string
    amountPaid: number
    discountCode: string
    bundle: boolean
    bundleOrderItemId: string
    paymentDate: string
    visitDate: string
    visitTime: string
    validFrom: string
    validTo: string
    redeemed: string | null
    status: string
    marketingOptIn: boolean
    userFields: UserField[]
    printed: string | null
    resellerName: string | null
    options: OrderItemOptions[]
}

export interface OrderDetailsPage extends Page {
    entries: OrderDetails[]
    userFieldConfig: UserFieldConfig
}

export interface UserFieldConfig {
    enabled: boolean
    fields: ConfigUserField[]
}

export interface OrderDetailsPageData {
    barcodes: OrderDetailsPage
}

export interface SelectedForRefund {
    uuid: string
    ticketUuids: string[]
}

export interface DateAndTime {
    date: string | null
    time: string | null
}

export interface ExportQuery {
    search: string | undefined
    widget_slug: string
    date_from: string | null
    date_to: string | null
    sort_by: string
    sort_direction: 'asc' | 'desc'
    date_range_type: string
}

export interface UpdateTicketValidToDetails {
    bundleOrderItemId: string
    orderId: string
    orderItemId: string
    date: Date
}

type OrderDetailsIncludeOptions =
    | 'payments'
    | 'items'
    | 'customer'
    | 'discounts'
    | 'location'
    | 'reseller'
    | 'items.barcode'
    | 'items.external_ids'
    | 'items.options'
    | 'items.refund_info'
    | 'date_changes'
    | 'comment'

export interface GetOrderDetailsRestParams {
    uuid: string
    include: OrderDetailsIncludeOptions[]
}

export interface ListOrdersParams {
    accountSlug: string
    dateRangeFrom: Date | string
    dateRangeTo: Date | string
    dateRangeType: 'visit' | 'sale'
    query: string
    sortProp: string
    sortDirection: string
    page: number
    pageSize: number
    searchType: 'extended' | 'simple'
    filter: string
}

export interface BulkRefundOrderProps {
    accountSlug: string
    orderUuids: string[]
    refundReason?: string
    forgoPartnerRefundFee?: boolean
    isCancellation?: boolean
}

export interface CartItem {
    productId: string
    productNumericId: string
    amount: number
    price: string | null
    gatePrice: string | null
    productName: Record<string, string>
}

type Comment = {
    content: string
    uuid?: string
    isActive: boolean
    createdBy: string
    createdAt: string
}

export interface Cart {
    id: string
    status: string
    categoryId: string
    eventDate: string | null
    startTime: string | null
    endTime: string | null
    externalId: string | null
    totalPrice: string
    totalPriceAfterDiscount: string
    priceIncludesTax: boolean
    items: CartItem[]
    extras: Record<string, any>
    expiresAt: string | null
    urls?: { rel: string; url: string }[]
    customer: {
        email: string
    }
    referenceId: string
    visitDate: string | null
    visitTime: string | null
    createdBy: string
    createdAt: string
    updatedAt: string
    timezone: string
    comments?: Comment[]
    comment?: Comment
}

// ----------------------------------------- Orders Beta schemas -------------------------------------------- //
export interface OrdersListRequestPayload {
    accountSlug: string
    include: string
    sortBy: string
    sortDirection: string
    search: string
    searchBy: string
    resellers: string
    directSales: boolean
    paymentMethods: string
    locations: string
    fromCreatedAt: string
    toCreatedAt: string
    fromEventDate: string
    toEventDate: string
    status: string
    paymentStatus: string
    emailStatus: string
    products: string
    pageSize: number
    offset: string
}

export interface OrdersListResult {
    next: string
    previous: string
    results: IOrder[]
}

export interface IOrder {
    id: string
    number: string
    account: string
    status: string
    detailedStatus: string
    productType: string
    categoryId: string
    orderDate: string
    updatedAt: string
    visitDate: string
    visitTime: string
    visitEndTime: string
    timezone: string
    ticketDownloadUrl: string
    quantity: number
    totalPriceInclTax: string
    totalPriceExclTax: string
    totalDiscountedPriceInclTax: string
    totalDiscountedPriceExclTax: string
    discountAmount: string
    currency: string
    locationId: string
    emailStatus: string
    unfilledApf: boolean
    unfilledApfUrl: string
    printed: boolean | null
    downloaded: string | null
    externalId: string | null
    orderSource: string
    customer: IOrderCustomer
    discounts: IOrderDiscount[]
    location: IOrderLocation
    reseller: IOrderReseller
    payments: IOrderPayment[]
    issuedBarcodesResult: {
        success: boolean
        error: string | null
    }
    confirmReservationsResult: Record<
        string,
        {
            success: boolean
            status: 'empty' | 'confirmed' | 'pending' | 'expired' | 'cancelled'
            error: string | null
        }
    >
    confirmPayments?: {
        success: boolean
        status: 'failed' | 'confirmed' | 'pending' | 'expired' | 'cancelled'
        error: string | null
    }
    items?: IOrderItems[]
    comments?: Comment[]
    comment?: Comment
}

export interface IOrderItems {
    id: string
    orderItemId: string
    productId: string
    productNumericId: number
    product: string
    gatePriceInclTax: string
    gatePriceExclTax: string
    priceInclTax: string
    priceExclTax: string
    discountedPriceInclTax: string
    discountedPriceExclTax: string
    discountedPriceWithoutOptionsInclTax: string
    discountedPriceWithoutOptionsExclTax: string
    invalidatedAt?: string
    validFrom: string
    validTo: string
    visitDate?: string
    visitTime?: string
    visitEndTime: string
    refunded: boolean
    canRefund: boolean
    isBundle: boolean
    afterPaymentForm: Record<string, string>
    barcode: IOrderItemBarcode
    options: IOrderItemOption[]
    refundInfo: IOrderItemRefundInfo | null
    status?: OrderStatus
}

interface IOrderCustomer {
    email: string
    name: string | null
    language: string
    marketingOptIn: boolean
}

interface IOrderDiscount {
    code: string
    externalId: string | null
}

interface IOrderLocation {
    id: string
    name: string
}

interface IOrderReseller {
    id: string | null
    name: string | null
}

export interface IOrderPayment {
    id: number
    paymentProvider: string
    paymentMethod: string
    paymentStatus: string
    paymentDate: string
}

interface IOrderItemBarcode {
    barcode: string
    redeemed: string | null
    status: string
}

export interface IOrderItemOption {
    id: string
    name: string
    priority: number | null
    pricingId: string
    priceInclTax: string
    priceExclTax: string
    numberOfItems?: number
}

interface IOrderItemRefundInfo {
    isCancellation: boolean
    reason: string
    requestedBy: string
}

export type SentEmailsResponse = {
    [datetime: string]: [
        {
            email_type: string
            url: string
        },
    ]
}
