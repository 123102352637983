import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { Messages, MessageKind } from 'uiComponents/messages'
import { withMessages, MessageProps, withCurrentUser, withNavigation } from 'hocs'
import { Row, Col } from 'uiComponents/flex'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import { StatsService } from 'http/statsService'
import { LoggingService } from 'http/loggingService'
import { ActionCard } from './actionCard'
import { User } from 'auth/state'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import StatsContainer from './statsCard'
import Orders from './icons/orders.svg'
import Sales from './icons/sales.svg'
import Products from './icons/products.svg'
import Prices from './icons/prices.svg'
import Engage from './icons/engage.svg'
import ReleaseNotes from './icons/release-notes.svg'
import RequiresInstalledScript from 'snippet/requiresInstalledScript'
import Permission from 'admin/permissionRequired'
import Disclaimer from 'reports/disclaimer'
import { useHasFeature } from 'features'

const CardRow = styled(Row)`
    margin-bottom: 1em;
    & > ${Col}:last-child {
        padding-left: 0;
    }
`

export interface CardData {
    title: string
    text: string
    icon: string
    destination: string
}

const cardData: CardData[] = [
    {
        title: 'Manage orders',
        text: 'Find, view, export and edit all your orders.',
        icon: Orders,
        destination: 'orders/transactions',
    },
    {
        title: 'Get sales insights',
        text: 'See numbers and reports around revenue and sales.',
        icon: Sales,
        destination: 'reports/sales',
    },
    {
        title: 'Edit products',
        text: 'Create and edit products, lists and exceptions.',
        icon: Products,
        destination: 'products/home/nested',
    },
    {
        title: 'Configure prices',
        text: 'Set, edit and add pricing rules and exceptions.',
        icon: Prices,
        destination: 'products/pricing/list',
    },
    {
        title: 'Style engage tools',
        text: 'Customize all your tools to meet goals.',
        icon: Engage,
        destination: 'engage/tools/home',
    },
]

interface DashboardHomePageProps {
    history: History
    statsService: StatsService
    accountSlug: string
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    loggingService: LoggingService
    user: User
    navigation: Navigation
    match: RouteMatch<any>
}

function DashboardHomePage(props: DashboardHomePageProps & MessageProps) {
    const { accountSlug, navigation } = props
    const releaseNotesPageEnabled = useHasFeature('release_notes_page')
    const cards = releaseNotesPageEnabled
        ? [
              {
                  title: "What's new",
                  text: 'See the latest product release notes or read the old news.',
                  icon: ReleaseNotes,
                  destination: 'release_notes/',
              },
              ...cardData,
          ]
        : cardData

    const eventData = {
        source: 'homepage',
    }

    function onCardClick(destination: string) {
        props.loggingService.logAction('navigation', Object.assign(eventData, { click_to: destination }))
        props.history.push(`/account/${props.accountSlug}/${destination}`)
    }

    return (
        <RequiresInstalledScript>
            <>
                <div id="dashboard-page" style={{ maxWidth: '1200px', minWidth: '1001px' }}>
                    <Messages messages={props.messages} hideMessage={props.hideMessage} />
                    <PageTitle>Dashboard</PageTitle>
                    <PageHeading>Manage and control your tools</PageHeading>
                    <Row>
                        <div style={{ paddingRight: '0', width: '49em' }}>
                            <CardRow>
                                <Col span={6}>
                                    <ActionCard data={cards[0]} onCardClick={onCardClick} isReleaseNotes={releaseNotesPageEnabled} />
                                </Col>
                                <Col span={6}>
                                    <ActionCard data={cards[1]} onCardClick={onCardClick} />
                                </Col>
                            </CardRow>
                            <CardRow>
                                <Col span={6}>
                                    <ActionCard data={cards[2]} onCardClick={onCardClick} />
                                </Col>
                                <Col span={6}>
                                    <ActionCard data={cards[3]} onCardClick={onCardClick} />
                                </Col>
                            </CardRow>
                            <CardRow>
                                <Col span={6}>
                                    <ActionCard data={cards[4]} onCardClick={onCardClick} />
                                </Col>
                                {releaseNotesPageEnabled && (
                                    <Col span={6}>
                                        <ActionCard data={cards[5]} onCardClick={onCardClick} />
                                    </Col>
                                )}
                            </CardRow>
                        </div>
                        <Permission name="access_analytics" accountSlug={accountSlug}>
                            <div style={{ paddingLeft: '1em', flex: '1', maxWidth: '48em' }}>
                                <StatsContainer
                                    statsService={props.statsService}
                                    accountSlug={accountSlug}
                                    navigation={navigation}
                                />
                            </div>
                        </Permission>
                    </Row>
                    <Disclaimer />
                </div>
            </>
        </RequiresInstalledScript>
    )
}

export default withNavigation(withMessages(withCurrentUser(DashboardHomePage)))
