import React from 'react'
import { createTheme, SimplePaletteColorOptions, ThemeOptions } from '@mui/material/styles'
import { getCssVariable, hexToRGBA } from 'utils/css'
import CircleInfo from '@convious/icons/src/icons/solid/circleInfoSolid.svg'
import CircleTimesSolid from '@convious/icons/src/icons/solid/circleTimesSolid.svg'
import ErrorSolid from '@convious/icons/src/icons/solid/errorSolid.svg'
import CircleCheckSolid from '@convious/icons/src/icons/solid/circleCheckSolid.svg'

const boyBlueColor = {
    5: getCssVariable('--boy-blue-5'),
    10: getCssVariable('--boy-blue-10'),
    20: getCssVariable('--boy-blue-20'),
    30: getCssVariable('--boy-blue-30'),
    40: getCssVariable('--boy-blue-40'),
    50: getCssVariable('--boy-blue-50'),
    60: getCssVariable('--boy-blue-60'),
    70: getCssVariable('--boy-blue-70'),
    80: getCssVariable('--boy-blue-80'),
    90: getCssVariable('--boy-blue-90'),
} as const

const aluminiumColor = {
    0: getCssVariable('--aluminium-0'),
    5: getCssVariable('--aluminium-5'),
    10: getCssVariable('--aluminium-10'),
    Border: getCssVariable('--aluminium-10'),
    20: getCssVariable('--aluminium-20'),
    30: getCssVariable('--aluminium-30'),
    TextLight: getCssVariable('--aluminium-30'),
    40: getCssVariable('--aluminium-40'),
    50: getCssVariable('--aluminium-50'),
    Aluminium: getCssVariable('--aluminium-50'),
    60: getCssVariable('--aluminium-60'),
    70: getCssVariable('--aluminium-70'),
    80: getCssVariable('--aluminium-80'),
    TextDark: getCssVariable('--aluminium-80'),
    90: getCssVariable('--aluminium-90'),
} as const

const tableRowColor = {
    50: getCssVariable('--table-row'),
} as const

const whiteColor = getCssVariable('--white')

const sunColor = {
    50: getCssVariable('--sun-50'),
} as const

const bodyFontSize = 16

const borderSize = 1 / bodyFontSize // rem

const theme: ThemeOptions = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1560,
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: boyBlueColor[50],
            light: boyBlueColor[5],
            dark: boyBlueColor[60],
            contrastText: aluminiumColor[0],
        },
        secondary: {
            main: aluminiumColor.TextDark,
        },
        warning: {
            main: sunColor[50],
            dark: aluminiumColor.TextDark,
            contrastText: aluminiumColor.TextDark,
        },
        text: {
            primary: aluminiumColor.TextDark,
            secondary: aluminiumColor.TextLight,
        },
        error: {
            main: getCssVariable('--sunrise'),
        },
    },
})

export const themeOptions: ThemeOptions = createTheme(theme, {
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        htmlFontSize: bodyFontSize,
        body1: {
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
        },
        body2: {
            fontSize: '0.75rem',
            lineHeight: '1.25rem',
        },
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    padding: '0 1.5rem',
                    width: 'auto',
                    borderBottom: `${borderSize}rem solid ${aluminiumColor[5]}`,
                    minHeight: 26,
                },
                scroller: {
                    paddingBottom: '0.25rem',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 26,
                    padding: 0,
                    whiteSpace: 'unset',
                    margin: '0 0.5rem',
                    color: getCssVariable('--text-dark'),
                    fontSize: '0.875rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '1.5rem',
                    '&.Mui-selected': {
                        color: getCssVariable('--text-dark'),
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: '0.5rem',
                    marginBottom: 0,

                    '&.MuiSlider-vertical': {
                        width: '0.5rem',
                        height: '100%',
                    },
                },
                rail: {
                    opacity: 1,
                },
                mark: {
                    height: '0.5rem',
                    width: '1px',
                    backgroundColor: whiteColor,
                },
                thumb: {
                    borderWidth: '0.15rem',
                    borderStyle: 'solid',
                    borderColor: whiteColor,

                    '&.Mui-focusVisible': {
                        boxShadow: 'none',
                    },
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                track: {
                    display: 'none',
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    info: <CircleInfo width="24px" height="24px" />,
                    error: <CircleTimesSolid width="24px" height="24px" />,
                    success: <CircleCheckSolid width="24px" height="24px" />,
                    warning: <ErrorSolid width="24px" height="24px" />,
                },
            },
            styleOverrides: {
                root: {
                    borderWidth: `${borderSize}rem`,
                    borderLeftWidth: '0.25rem',
                },
                standardWarning: {
                    borderColor: (theme?.palette?.warning as SimplePaletteColorOptions)?.main,
                },
                standardInfo: {
                    background: boyBlueColor[5],
                    borderColor: boyBlueColor[50],
                },
                message: {
                    color: theme?.palette?.text?.primary,
                },
                action: {
                    paddingTop: 0,
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&:not(&.list-item-depth-1)': {
                        backgroundColor: tableRowColor[50],
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                endAdornment: {
                    bottom: '0.5rem',
                    top: 'unset',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                hoverBorder: `${borderSize}rem solid ${(theme?.palette?.primary as SimplePaletteColorOptions)?.main}`,
                hoverColor: (theme?.palette?.primary as SimplePaletteColorOptions)?.light,
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&.MuiButton-sizeMedium': {
                        padding: '0.25rem 0.75rem',
                    },
                    [theme.breakpoints?.down?.('xl') ?? '']: {
                        '&.action-button': {
                            padding: '0.25rem 0.45rem !important',
                            marginLeft: '0.5rem',
                        },
                    },
                },
                startIcon: {
                    marginRight: '0.25rem',
                    svg: {
                        width: '1.25rem',
                        height: '1.25rem',
                    },
                },
                endIcon: {
                    marginLeft: '0.25rem',
                    svg: {
                        width: '1.25rem',
                        height: '1.52rem',
                    },
                },
                text: {
                    '&:hover': {
                        backgroundColor: (theme?.palette?.primary as SimplePaletteColorOptions)?.light,
                    },
                },
                contained: {
                    '&:hover': {
                        backgroundColor: (theme?.palette?.primary as SimplePaletteColorOptions)?.main,
                        boxShadow: `inset 0 -${2 * borderSize}rem #0D151E1A`,
                    },
                    '&:active': {
                        backgroundColor: boyBlueColor[60],
                        border: 'none',
                    },
                    '&:disabled': {
                        backgroundColor: aluminiumColor[20],
                        color: aluminiumColor.TextLight,
                    },
                },
                outlined: {
                    backgroundColor: whiteColor,
                },
                outlinedSecondary: {
                    borderColor: getCssVariable('--border'),

                    '&:hover': {
                        backgroundColor: getCssVariable('--background'),
                    },
                },
                outlinedError: {
                    '&:hover': {
                        backgroundColor: getCssVariable('--red-5'),
                    },
                },
                outlinedPrimary: {
                    '&:hover': {
                        backgroundColor: boyBlueColor[5],
                    },
                },
            },
        },
        MuiLoadingButton: {
            styleOverrides: {
                loadingIndicator: {
                    color: aluminiumColor[50],
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    '&.iconToggles': {
                        '&.MuiToggleButtonGroup-root': {
                            gap: '1rem',
                            width: '100%',
                        },
                        '&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped': {
                            flex: '1 1 0px',
                            flexDirection: 'column',
                            color: aluminiumColor.TextDark,
                            border: `1px solid ${aluminiumColor.Border}`,
                            padding: '0.5rem 0',
                            boxSizing: 'border-box',
                            borderRadius: '4px',

                            '&.Mui-selected': {
                                background: boyBlueColor[10],
                                borderColor: boyBlueColor[50],
                                color: boyBlueColor[50],

                                '&:hover': {
                                    background: boyBlueColor[10],
                                },
                            },

                            '&.Mui-disabled:not(.Mui-selected)': {
                                borderColor: aluminiumColor[20],
                                color: aluminiumColor[20],
                                cursor: 'not-allowed',
                                pointerEvents: 'all',

                                '&:hover': {
                                    boxShadow: 'unset',
                                },
                            },

                            '&:hover': {
                                background: 'unset',
                                boxShadow: `0px -2px 2px 0px ${hexToRGBA(getCssVariable('--richBlack'), 0.1)} inset`,
                            },
                        },
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                primary: {
                    '&:hover': {
                        backgroundColor: (theme?.palette?.primary as SimplePaletteColorOptions)?.main,
                        boxShadow: `inset 0 -${2 * borderSize}rem #0D151E1A`,
                    },
                    '&:active': {
                        backgroundColor: boyBlueColor[60],
                        border: 'none',
                    },
                    '&:disabled': {
                        backgroundColor: aluminiumColor[20],
                        color: aluminiumColor.TextLight,
                    },
                },
                secondary: {
                    backgroundColor: whiteColor,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '.MuiTypography-root': {
                        display: 'inline-flex',
                        fontSize: '0.875rem',
                        lineHeight: '1.5rem',
                        fontWeight: 500,

                        '&.Mui-disabled': {
                            color: 'unset',
                        },
                    },

                    '&.MuiFormControlLabel-labelPlacementStart': {
                        alignSelf: 'flex-start',
                        marginLeft: 0,
                    },

                    '& .MuiSwitch-switchBase': {
                        padding: 0,
                        margin: '0.813rem',
                        transitionDuration: '300ms',

                        '&.Mui-checked': {
                            transform: 'translateX(1.125rem)',

                            '& + .MuiSwitch-track': {
                                background: boyBlueColor[50],
                                opacity: 1,
                                border: 0,
                            },
                        },

                        '&.Mui-disabled + .MuiSwitch-track': {
                            cursor: 'not-allowed',
                            opacity: 0.7,
                        },
                    },
                    '.MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        background: whiteColor,
                        width: '0.75rem',
                        height: '0.75rem',
                    },
                    '.MuiSwitch-track': {
                        borderRadius: 26 / 2,
                        background: aluminiumColor[30],
                        opacity: 1,
                        width: '2rem',
                    },
                },
            },
        },
    },
})
