import * as React from 'react'
import styled, { css } from 'styled-typed'
import { isLastDayOfMonth, format, startOfMonth, addMonths, parse, isValid } from 'date-fns'
import { Picker, renderDay, DatePickerCaptionProps, CaptionContainer } from './oneMonthDateFilter'
import { TextInput } from 'uiComponents/input'
import 'react-day-picker/lib/style.css'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { ActionButton } from 'uiComponents/buttons'
import { Modifiers, Modifier, DayModifiers } from 'react-day-picker'
import { ModalDialog } from 'uiComponents/popups/modal'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { isAfter, isBefore } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { DateFormats, formatISOString } from 'utils/dates'
import { useAppSelector } from 'store/hooks'
import { getIsUSPartner } from 'auth/selectors'

function NoNavBar() {
    return null
}

const DateField = styled.div`
    display: flex;
    align-items: center;
    &.expanded {
        margin-left: -0.6em;
        padding: 0.5em;
        border: 1px solid ${(p) => p.theme.colors.boyBlue};
        border-radius: 5px;
    }
`
const InputField = styled(TextInput)`
    width: 100%;
    font-weight: lighter;
`
const ModalPickerContainerStyle = css`
    position: relative;
    top: 0;
    left: 0;
`
interface IInputPickerContainer {
    inlineEdit?: boolean
    modalDatePicker?: boolean
}
const InputPickerContainer = styled.div<IInputPickerContainer>`
    position: absolute;
    top: ${(p) => (p.inlineEdit ? '2.95em' : '3.4em')};
    left: ${(p) => (p.inlineEdit ? '-0.6em' : '0')};
    height: 0;
    &.visible {
        height: auto;
    }
    ${(p) => (p.modalDatePicker ? ModalPickerContainerStyle : '')};
    z-index: 2;
`
interface PickerProps {
    withFooter?: boolean
    modalDatePicker?: boolean
}
const InputPicker = styled(Picker)<PickerProps>`
    z-index: 1;
    top: ${(p) => (p.modalDatePicker ? '0' : '')};
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled),
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):hover {
        border-radius: 1em !important;
        background: none !important;
    }
    .DayPicker-Day--selected:focus,
    .DayPicker-Day--selected > :focus {
        outline: none !important;
    }
    .DayPicker-Month {
        padding-bottom: ${(p) => (p.withFooter ? '3.5em' : '')};
        padding: ${(p) => (p.modalDatePicker ? '1em .5em 3.5em .5em' : '')};
        width: ${(p) => (p.modalDatePicker ? '18em' : '')};
    }
    &:not(.visible) * {
        display: none;
    }
`
const PickerFooter = styled.div`
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0.9em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1.5em;
    opacity: 0;
    visibility: hidden;
    height: 0;
    &.visible {
        height: auto;
        visibility: visible;
        opacity: 1;
    }
`

const Icon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.textLight};
    cursor: pointer;
`

export interface DatePickerProps {
    date: Date | null
    onChange?: (d: Date | null) => void
    upperBoundary?: Date | null
    lowerBoundary?: Date | null
    status?: 'normal' | 'success' | 'error'
    id?: string
    className?: string
    disabled?: boolean
    allowNullDate?: boolean
    modalDatePicker?: boolean
    inlineEdit?: boolean
    onInlineEditAccept?: (date: Date) => void
    inlineEditDateFormat?: string
    positionAbove?: boolean
    timezone?: string
}

interface DatePickerState {
    month: Date
    enteredDate: Date | null
    expanded: boolean
    inputValue: string
}

export class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    popup: HTMLDivElement | null = null
    dateFormat = DateFormats.SHORT_DATE
    inlineEditDateFormat = DateFormats.EXTRA_SHORT_DATE
    isInputChange = React.createRef() as React.MutableRefObject<boolean>

    constructor(props: DatePickerProps) {
        super(props)
        this.state = {
            month: startOfMonth(this.props.date || new Date()),
            enteredDate: this.props.date,
            expanded: false,
            inputValue: this.props.date ? format(this.props.date, this.dateFormat) : '',
        }
    }

    resetInputDate = () => {
        if (this.state.enteredDate) {
            this.setState({
                inputValue: format(this.state.enteredDate, this.dateFormat),
            })
        } else {
            this.setState({ inputValue: '' })
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
        document.addEventListener('keyup', this.keyUp, false)
    }

    componentDidUpdate(prevProps: DatePickerProps) {
        if (prevProps.date !== this.props.date) {
            this.setState(
                {
                    enteredDate: this.props.date,
                },
                () => {
                    this.resetInputDate()
                },
            )
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
        document.removeEventListener('keyup', this.keyUp, false)
    }

    outsideClick = (ev: MouseEvent) => {
        if (
            !this.props.modalDatePicker &&
            this.popup &&
            this.popup.contains &&
            !this.popup.contains(ev.target as Node) &&
            this.state.expanded
        ) {
            this.setState({ expanded: false })
        }
    }

    keyUp = (e: KeyboardEvent) => {
        var key = e.which || e.keyCode
        if (key === 9) {
            const el = e.target as Element
            if (el === window.document.body || this.checkFocusOnCalendar(el)) {
                return
            }
            this.setState({ expanded: false })
        }
    }

    checkFocusOnCalendar = (el: Element | null) => {
        while (el) {
            if (el === this.popup) {
                return true
            }
            el = el.parentElement
        }
        return false
    }

    setPopupRef = (node: HTMLDivElement) => {
        this.popup = node
    }

    setInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        this.setState({ inputValue: value })
    }

    validateInputDate = (v: string) => {
        const isNotFinishedTyping = v?.includes('_') && v !== '__/__/____'

        if (!this.isInputChange.current || isNotFinishedTyping) {
            return
        }

        if (this.props.allowNullDate && (v === '' || v === '__/__/____')) {
            this.setNewDate(null)
            return
        }
        const parsedDate = parse(v, this.dateFormat, new Date())

        if (!isValid(parsedDate)) {
            this.resetInputDate()
        } else {
            if (this.props.lowerBoundary && isBefore(parsedDate, this.props.lowerBoundary)) {
                return this.setNewDate(this.props.lowerBoundary)
            }

            if (this.props.upperBoundary && isAfter(parsedDate, this.props.upperBoundary)) {
                return this.setNewDate(this.props.upperBoundary)
            }

            this.setNewDate(parsedDate)
        }
    }

    handleDayChange = (date: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled) {
            return
        }
        this.setNewDate(date)
    }

    setNewDate = (date: Date | null) => {
        this.setState(
            {
                enteredDate: date,
                expanded: false,
            },
            () => {
                this.resetInputDate()
            },
        )

        if (this.props.onChange) {
            this.props.onChange(date)
        }
    }

    handleInlineEditDayChange = (date: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled) {
            return
        }
        this.setState({ enteredDate: date })
    }

    onInlineEditAccept = () => {
        if (this.state.enteredDate && this.props.onInlineEditAccept) {
            this.props.onInlineEditAccept(this.state.enteredDate)
        }
        this.setState({ expanded: false })
    }

    onInlineEditCancel = () => {
        this.setState({ enteredDate: this.props.date, expanded: false })
    }

    onFocus = () => {
        if (this.props.disabled) {
            return
        }

        this.setState({ expanded: true })
    }

    renderCaption = ({ date, localeUtils }: DatePickerCaptionProps) => (
        <CaptionContainer
            onClickPrev={() => this.setState({ month: addMonths(date, -1) })}
            onClickNext={() => this.setState({ month: addMonths(date, 1) })}
            localeUtils={localeUtils}
            date={date}
        />
    )

    getDisplayDate = () => {
        if (!this.state.enteredDate) return 'Undated'

        return formatISOString(this.state.enteredDate, this.props.inlineEditDateFormat || this.inlineEditDateFormat, {
            timeZone: this.props.timezone,
        })
    }

    render() {
        const { enteredDate, expanded } = this.state
        const { inlineEdit, modalDatePicker } = this.props

        let disabledDays
        if (this.props.upperBoundary) {
            disabledDays = [{ after: this.props.upperBoundary }]
        } else if (this.props.lowerBoundary) {
            disabledDays = [{ before: this.props.lowerBoundary }]
        }

        const modifiers = {
            selectedOnly: enteredDate ? enteredDate : undefined,
            monthStart: (d: Date) => d.getDate() === 1,
            monthEnd: (d: Date) => isLastDayOfMonth(d),
        }

        return (
            <div className="date-picker" style={{ position: 'relative' }} ref={this.setPopupRef}>
                {!inlineEdit && (
                    <InputField
                        id={this.props.id}
                        className={this.props.className}
                        onFocus={this.onFocus}
                        value={this.state.inputValue}
                        status={this.props.status}
                        onKeyDown={(e) => {
                            this.isInputChange.current = true
                        }}
                        onChange={(e) => {
                            this.setInputValue(e)
                            this.validateInputDate(e.target.value)
                            this.isInputChange.current = false
                        }}
                        onBlur={(e) => {
                            if (e.target.value.includes('_') || !this.props.allowNullDate) {
                                this.resetInputDate()
                            }
                        }}
                        postfix={
                            <Icon onClick={() => this.setState({ expanded: true })} icon={faCalendarAlt as IconProp} />
                        }
                        disabled={this.props.disabled}
                        placeholder={this.dateFormat}
                        mask="99/99/9999"
                        autoComplete="off"
                    />
                )}
                {inlineEdit && (
                    <DateField className={expanded ? 'expanded' : ''}>
                        {this.getDisplayDate()}
                        {!this.props.disabled && (
                            <ActionIcon
                                onClick={() => this.setState({ expanded: true })}
                                icon={faPencilAlt}
                                title="Edit"
                                style={{ marginLeft: '0.5em' }}
                            />
                        )}
                    </DateField>
                )}
                {!modalDatePicker && (
                    <div style={this.props.positionAbove ? { top: '-21.5em', position: 'relative' } : {}}>
                        <DatePickerContainer
                            inlineEdit={inlineEdit}
                            expanded={expanded}
                            enteredDate={enteredDate}
                            month={this.state.month}
                            modifiers={modifiers}
                            disabledDays={disabledDays}
                            renderCaption={this.renderCaption}
                            handleDayChange={this.handleDayChange}
                            handleInlineEditDayChange={this.handleInlineEditDayChange}
                            onInlineEditCancel={this.onInlineEditCancel}
                            onInlineEditAccept={this.onInlineEditAccept}
                        />
                    </div>
                )}
                {modalDatePicker && expanded && (
                    <ModalDialog
                        onDismiss={this.onInlineEditCancel}
                        interactive
                        fromTop="15%"
                        style={{ padding: '1em' }}
                    >
                        <DatePickerContainer
                            modalDatePicker={modalDatePicker}
                            inlineEdit={inlineEdit}
                            expanded={expanded}
                            enteredDate={enteredDate}
                            month={this.state.month}
                            modifiers={modifiers}
                            disabledDays={disabledDays}
                            renderCaption={this.renderCaption}
                            handleDayChange={this.handleDayChange}
                            handleInlineEditDayChange={this.handleInlineEditDayChange}
                            onInlineEditCancel={this.onInlineEditCancel}
                            onInlineEditAccept={this.onInlineEditAccept}
                        />
                    </ModalDialog>
                )}
            </div>
        )
    }
}

interface DatePickerContainerProps {
    modalDatePicker?: boolean
    inlineEdit?: boolean
    expanded: boolean
    enteredDate: Date | null
    month: Date
    modifiers: Partial<Modifiers>
    disabledDays?: Modifier | Modifier[]
    renderCaption: ({ date, localeUtils }: DatePickerCaptionProps) => React.ReactElement
    handleDayChange: (date: Date, modifiers: DayModifiers) => void
    handleInlineEditDayChange: (date: Date, modifiers: DayModifiers) => void
    onInlineEditCancel: () => void
    onInlineEditAccept: () => void
}

function DatePickerContainer(props: DatePickerContainerProps) {
    const {
        inlineEdit,
        enteredDate,
        expanded,
        month,
        modifiers,
        disabledDays,
        renderCaption,
        modalDatePicker,
        handleDayChange,
        handleInlineEditDayChange,
        onInlineEditCancel,
        onInlineEditAccept,
    } = props
    const isUSPartner = useAppSelector(getIsUSPartner)

    return (
        <InputPickerContainer
            inlineEdit={inlineEdit}
            modalDatePicker={modalDatePicker}
            className={expanded ? 'visible' : ''}
        >
            <InputPicker
                className={expanded ? 'visible' : ''}
                month={month}
                numberOfMonths={1}
                firstDayOfWeek={isUSPartner ? 0 : 1}
                selectedDays={enteredDate}
                onDayClick={inlineEdit ? handleInlineEditDayChange : handleDayChange}
                modifiers={modifiers}
                disabledDays={disabledDays}
                renderDay={renderDay}
                navbarElement={NoNavBar}
                captionElement={renderCaption}
                weekdaysShort={['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']}
                withFooter={inlineEdit}
                modalDatePicker={modalDatePicker}
            />
            <PickerFooter className={!inlineEdit ? '' : expanded ? 'visible' : ''}>
                <ActionButton secondary onClick={onInlineEditCancel}>
                    Cancel
                </ActionButton>
                <ActionButton onClick={onInlineEditAccept} style={{ marginLeft: '1em' }}>
                    Save
                </ActionButton>
            </PickerFooter>
        </InputPickerContainer>
    )
}
