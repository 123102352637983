import { MetaData, FilterCategory } from 'uiComponents/filter/schema'
import { formatFlatOptions } from 'uiComponents/filter/filterHelpers'

export const applicableFilters: FilterCategory[] = ['reservation_status']

const categoryOptions = {
    reservation_status: [
        { name: 'Open', slug: 'open' },
        { name: 'Pending', slug: 'pending' },
        { name: 'Initiated', slug: 'initiated' },
        { name: 'Overdue', slug: 'overdue' },
        { name: 'Expired', slug: 'expired' },
        { name: 'Cancelled', slug: 'cancelled' },
        { name: 'Completed', slug: 'completed' },
    ],
}

export const staticMetadaCategories: MetaData[] = [
    {
        category: 'reservation_status' as FilterCategory,
        options: formatFlatOptions('reservation_status', categoryOptions.reservation_status),
    },
]
