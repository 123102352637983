import React from 'react'
import { MetaData } from 'uiComponents/filter/schema'
import Filter from 'uiComponents/filter'
import { useAppSelector } from 'store/hooks'
import { getActiveAccount } from 'preferences/selectors'
import { applicableFilters, staticMetadaCategories } from './config'

const CartTableFilters: React.FC = () => {
    const accountSlug = useAppSelector(getActiveAccount)

    if (!accountSlug) throw new Error('No account selected')

    const data = React.useMemo((): MetaData[] => {
        return [...staticMetadaCategories]
    }, [])

    return (
        <Filter
            accountSlug={accountSlug}
            applicableFilters={applicableFilters}
            metaData={data}
            standaloneFilters={[]}
            singleSelectCategories={[]}
            compact
        />
    )
}

export default CartTableFilters
