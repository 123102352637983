import React, { useCallback, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styledComponent, { useTheme } from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleUp, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BaseRouteParams, useNavigation } from 'hocs'
import { LoadDataParams, ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import { DiscountPool } from 'venue/bookingCodes/bookingCodesService'
import { useLazyListDiscountPoolsQuery } from 'venue/reduxQuery'
import PoolUploads from './poolUploads'
import { formatTableState } from 'uiComponents/table/reactTable/tableState'
import { Text } from 'uiComponents/typography'
import ActionButton from 'uiComponents/buttons'
import { history } from 'middleware'
import { pathUtils } from 'utils/pathnameFormatter'
import InlineName from './inlineNameEdit'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { addSeparators } from 'utils'

const ClosedIcon = styledComponent(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.aluminiumShades[10]};
  font-size: 1.2rem;
  width: unset !important;
`
const OpenedIcon = styledComponent(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.aluminiumShades[30]};
  font-size: 1.2rem;
  width: unset !important;
`
const emptyArray = [] as any[]

const Pools: React.FC = ({ children }) => {
    const [fetchDiscountPools, { data, isFetching }] = useLazyListDiscountPoolsQuery()
    const { accountSlug } = useParams<BaseRouteParams>()
    const location = useLocation()
    const navigation = useNavigation()
    const { search, searchBy } = navigation.query()
    const theme = useTheme()

    const columns = useMemo(() => {
        return [
            {
                Header: () => null,
                id: 'expander',
                accessor: 'expander',
                width: '2.5rem',
                disableSortBy: true,
                disableHideColumn: true,
                Cell: ({ row }) => {
                    if (!row.original.codes?.length && !row.original.uploads?.length) {
                        return null
                    }

                    if (search === row.original.name) {
                        row.isExpanded = true
                    }

                    return (
                        <div {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? (
                                <OpenedIcon icon={faAngleUp as IconProp} />
                            ) : (
                                <ClosedIcon icon={faAngleDown as IconProp} />
                            )}
                        </div>
                    )
                },
            },
            {
                accessor: 'name',
                Header: 'Pool name',
                Cell: ({ row: { original } }) => {
                    return <InlineName discountPool={original} />
                },
            },
            {
                accessor: 'usage',
                Header: 'Count',
                width: '7rem',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    const result = `${addSeparators(original.usage.used)}/${addSeparators(original.usage.total)}`
                    const isAllResourcesUsed = original.usage.used === original.usage.total
                    const isAllResourcesGettingLow =
                        original.usage.used &&
                        original.usage.total &&
                        (original.usage.used * 100) / original.usage.total >= 90

                    if (isAllResourcesUsed) {
                        return <Text status="error">{result}</Text>
                    }

                    if (isAllResourcesGettingLow) {
                        return <Text status="processing">{result}</Text>
                    }

                    return <Text status="success">{result}</Text>
                },
            },
            {
                accessor: 'action',
                disableHideColumn: true,
                disableSortBy: true,
                align: 'left',
                width: '15rem',
                Cell: ({
                    row: {
                        original: { uuid, usage },
                    },
                }) => {
                    return (
                        <>
                            <ActionButton
                                kind="action"
                                secondary
                                style={{ marginRight: '0.5rem' }}
                                onClick={() => {
                                    history.push(
                                        pathUtils.addPath(location.pathname, `/add/form/${uuid}`, location.search),
                                    )
                                }}
                            >
                                + Add Codes
                            </ActionButton>
                            {usage.total === 0 && (
                                <ActionIcon
                                    title="Delete"
                                    icon={faTrash as IconProp}
                                    onClick={() => {
                                        history.push(
                                            pathUtils.addPath(
                                                location.pathname,
                                                `/delete/pool/${uuid}`,
                                                location.search,
                                            ),
                                        )
                                    }}
                                />
                            )}
                        </>
                    )
                },
            },
        ] as TableColumn<DiscountPool>[]
    }, [location])

    const loadData = useCallback(
        (params: LoadDataParams) => {
            fetchDiscountPools({
                accountSlug,
                query: formatTableState.toQueryFromLoadData(params, {
                    include: 'uploads,codes',
                    search,
                    search_by: searchBy,
                }),
            })
        },
        [accountSlug, search, searchBy],
    )

    return (
        <>
            <ReactTable
                noResultsRow
                pagination
                sort={{ prop: 'name', direction: 'asc' }}
                expanded={PoolUploads}
                loading={isFetching}
                elevation={false}
                rowProps={() => ({
                    style: {
                        borderBottom: `1px solid ${theme.colors.aluminiumShades[5]}`,
                    },
                })}
                data={data?.entries || emptyArray}
                totalCount={data?.totalCount}
                columns={columns}
                loadData={loadData}
            />
            {children}
        </>
    )
}

export default Pools
