import * as React from 'react'
import { CircleInfoSolid } from '@convious/icons'
import { IconButton, Tooltip } from '@mui/material'
import { Cell, DataRow } from 'uiComponents/table'
import { Checkbox } from 'uiComponents/input'
import Money from 'uiComponents/money'
import { ICancelRefundTableRow } from './types'
import './styles.scss'

interface CancelRefundRowProps {
    row: ICancelRefundTableRow
    isSelected: boolean
    onSelectItem: (parentId: string | null, id: string) => void
    onRefundFeeSelect: () => void
    accountSlug: string
    confirmMode: boolean
}

const CancelRefundTableRow = ({
    row,
    isSelected,
    onSelectItem,
    accountSlug,
    onRefundFeeSelect,
    confirmMode,
}: CancelRefundRowProps) => {
    const onCheckboxChange = React.useCallback(() => {
        onSelectItem(row.orderItemId ?? null, row.id)
    }, [onSelectItem, row])

    if (row.id === 'total') {
        return (
            <DataRow className="cancel-refund-data-row total-row">
                <Cell noPadding>
                    <p className="body1 bold">{row.name}</p>
                </Cell>
                <Cell className="middle-cell" noPadding>
                    <p className="body1" title={row.descriptionInfo ? row.descriptionInfo : row.description}>
                        {row.description}
                    </p>
                </Cell>
                <Cell align="right" noPadding>
                    <p className="body1 bold">
                        <Money accountSlug={accountSlug} amount={row.amount.toFixed(2)} />
                    </p>
                </Cell>
            </DataRow>
        )
    }

    if (row.id === 'refundFee') {
        return (
            <DataRow className="cancel-refund-data-row" onClick={row.disabled ? undefined : onRefundFeeSelect} >
                <Cell noPadding>
                    {!confirmMode && (
                        <Checkbox
                            className="row-checkbox"
                            id="partner-refund-fee-checkbox"
                            checked={isSelected}
                            disabled={row.disabled}
                        />
                    )}
                    <p className="body1" title={row.name}>
                        {row.name}
                    </p>
                </Cell>
                <Cell className="middle-cell" noPadding>
                    <p className="body1" title={row.descriptionInfo ? row.descriptionInfo : row.description}>
                        {row.description}
                    </p>
                    {row.infoTip && (
                        <Tooltip title={row.infoTip} placement="top-end">
                            <IconButton>
                                <CircleInfoSolid width={24} height={24} className="info-tip" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Cell>
                <Cell align="right" className="overflow-allowed" noPadding>
                    <p className="body1 error">
                        - <Money accountSlug={accountSlug} amount={row.amount.toFixed(2)} />
                    </p>
                </Cell>
            </DataRow>
        )
    }
 
    return (
        <DataRow className="cancel-refund-data-row" onClick={onCheckboxChange}>
            <Cell noPadding>
                {!confirmMode && (
                    <Checkbox
                        data-testid="order-checkbox"
                        className="row-checkbox"
                        id={row.id}
                        checked={isSelected}
                    />
                )}
                <p className="body1" title={row.name}>
                    {row.name}
                </p>
            </Cell>
            <Cell className="middle-cell" noPadding>
                <p className="body1" title={row.descriptionInfo ? row.descriptionInfo : row.description}>
                    {row.description}
                </p>
            </Cell>
            <Cell align="right" className="overflow-allowed" noPadding>
                <p className="body1">
                    <Money accountSlug={accountSlug} amount={row.amount.toFixed(2)} />
                </p>
            </Cell>
        </DataRow>
    )
}

export default CancelRefundTableRow
